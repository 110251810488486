
@mixin hook-button {
	font-weight: $font-weight-medium;
	text-transform: lowercase;
	white-space: nowrap;
	transition: box-shadow 0.2s ease;
	position: relative;
	padding: $button-padding-vertical $button-padding-horizontal;
	padding-right: ((($global-font-size / 1rem) * ($global-small-font-size / 1rem)) * 16px) + $button-padding-horizontal;

	&:after {
		@include make-fa-icon;
		@include fa-light;

		content: fa-content($fa-var-angle-right);
		line-height: inherit;
		position: absolute;
		right: $button-padding-horizontal;
		width: $global-small-font-size;
	}
}

@mixin hook-button-hover {
	box-shadow: $global-large-box-shadow;
}

@mixin hook-button-focus {
	@include hook-button-hover;
}

@mixin hook-button-default {
	border: $button-border-width solid $button-default-border-color;
	padding: ($button-padding-vertical - $button-border-width) ($button-padding-horizontal - $button-border-width);
	padding-right: ((($global-font-size / 1rem) * ($global-small-font-size / 1rem)) * 16px) + ($button-padding-horizontal - $button-border-width);

	&:after {
		right: ($button-padding-horizontal - $button-border-width);
	}
}

@mixin hook-button-text {
	&:after {
		content: "";
	}
}

@mixin hook-button-text-hover {
	box-shadow: none;
}

@mixin hook-button-link {
	font-weight: normal;

	&:after {
		position: static;
	}
}
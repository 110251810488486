.dogtopia-dash-page-container {
	padding: 0;

	.dash-hero-container {
		margin: 0 0 25px;
		@media all and ( min-width: $breakpoint-medium ) {
			margin: 0 0 68px;
		}

		img {
			width: 100%;
		}
	}

	.dash-landing-container {
		text-align: center;
		margin: 0 auto 100px;
		max-width: 970px;
		@media all and ( min-width: $breakpoint-medium ) {
			margin: 0 auto 200px;
		}

		.large-landing-container {			
			h2 {
				font-family: $global-font-family;
				font-size: 29px;
				font-weight: bold;
				line-height: 1.03;
				color: #4d545a;
				text-transform: none;
				margin: 0 0 15px;
				@media all and ( min-width: $breakpoint-medium ) {
					font-size: 45px;
					line-height: 1.22;
					margin: 0 0 10px;
				}
			}

			p {
				font-family: $global-font-family;
				font-size: 16px;
				line-height: 1.25;
				color: #4d545a;
				margin: 0 0 5px;
				@media all and ( min-width: $breakpoint-medium ) {
					font-size: 23px;
					line-height: 1.26;
					margin: 0 0 40px;
				}
			}
		}

		.short-landing-container {			
			h3 {
				font-family: $global-font-family;
				font-size: 16px;
				font-weight: 900;
				line-height: 1.31;
				letter-spacing: 0.48px;
				color: $brand-orange;
				margin: 0 0 5px;
				@media all and ( min-width: $breakpoint-medium ) {
					font-size: 20px;
					line-height: 1.35;
					letter-spacing: 0.6px;
				}
			}

			p {
				font-size: 14px;
  			color: #4d545a;
				margin: 0;
				@media all and ( min-width: $breakpoint-medium ) {
					font-size: 16px;
				}
			}
		}
	}

	.dash-orange-process-container {
		background: $brand-orange;
		padding-bottom: 40px;
		@media all and ( min-width: $breakpoint-medium ) {
			padding-bottom: 60px;
		}

		.process-slider-container {
			margin-top: -60px;
			@media all and ( min-width: $breakpoint-medium ) {
				margin-top: -100px;
			}

			.img-container {
				padding: 8px 8px 0;
				max-width: 354px;
				margin: 0 auto;
				@media all and ( min-width: $breakpoint-medium ) {
					max-width: none;
				}

				img {
					border-radius: 10px;
    			box-shadow: 0 0 8px 0 rgba(0, 0, 0, .25);
				}
			}

			.content-container {
				padding: 15px 8px;
				text-align: center;
				max-width: 354px;
				margin: 0 auto;
				@media all and ( min-width: $breakpoint-medium ) {
					max-width: none;
					padding: 20px 8px;
				}

				h4 {
					font-size: 20px;
					font-weight: 900;
					line-height: normal;
					letter-spacing: 0.6px;
					color: $global-background;
					margin: 0 0 15px;
				}

				p {
					font-family: $global-font-family;
					font-size: 23px;
					line-height: 1.26;
					color: $global-background;
					margin: 0;
				}
			}

			.slider-nav-container {
				text-align: center;

				a {
					&:active,
					&:focus,
					&:hover {
						opacity: .7;
					}
				}
			}
		}
	}

	.dash-blue-features-container {
		background: $brand-blue;
		padding: 22px 0 23px;
		@media all and ( min-width: $breakpoint-medium ) {
			padding: 100px 0 125px;
		}

		.blue-content-container {
			text-align: center;
			max-width: 970px;
			margin: 0 auto 54px;

			h2 {
				font-family: $global-font-family;
				font-size: 20px;
				font-weight: 900;
				line-height: normal;
				letter-spacing: 0.6px;
				color: $global-background;
			}

			p {
				font-family: $global-font-family;
				font-size: 45px;
				font-weight: bold;
				line-height: 1.22;
				color: $global-background;
			}
		}

		.blue-list-img-container {
			flex-direction: column-reverse;
			@media all and ( min-width: $breakpoint-medium ) {
				flex-direction: unset;
			}

			.list-container {
				padding: 37px 25px 0;
				border-radius: 10px;
				box-shadow: 0 0 14px 0 rgba( 0, 0, 0, 0.25 );
				background: $global-background;
				text-align: center;
				max-width: 354px;
				margin: 0 auto;
				@media all and ( min-width: $breakpoint-large ) {
					margin: 0;
				}

				ul {
					padding: 0;

					li {
						display: block;
						margin: 0 0 20px;
						font-family: $global-font-family;
						font-size: 23px;
						line-height: 1.26;
						color: #4d545a;

						&::before {
							content: "";
							background-image: url( '../images/dash-checkmark.png' );
							width: 44px;
							height: 44px;
							display: block;
							margin: 0 auto 12px;
							background-size: contain;
							background-repeat: no-repeat;
						}
					}
				}

				p {
					font-family: $global-font-family;
					font-size: 23px;
					line-height: 1.26;				
					color: #4d545a;
				}
			}

			.img-container {
				margin: 0 -60px;
				@media all and ( min-width: $breakpoint-medium ) {
					margin: 0;
				}
			}
		}
	}

	.dash-testimonial-container {
		padding: 50px 0 70px;
		text-align: center;
		margin: 0 auto;
		max-width: 970px;

		.img-container {
			margin: 0 0 7px;
		}

		.testimonial-content-container {
			h2 {
				font-family: $global-font-family;
				font-weight: 900;
				line-height: normal;
				color: $brand-orange;
				margin: 0 0 7px;
				font-size: 16px;
				letter-spacing: 0.48px;
				@media all and ( min-width: $breakpoint-medium ) {
					font-size: 20px;
					letter-spacing: 0.6px;
				}			
			}

			h3 {
				font-family: $global-font-family;
				font-weight: bold;				
				color: #4d545a;
				text-transform: none;
				margin: 0 0 7px;
				font-size: 29px;
				line-height: 1.03;
				@media all and ( min-width: $breakpoint-medium ) {
					font-size: 45px;
					line-height: 1.22;
				}
			}

			p {
				font-family: $global-font-family;
				font-size: 16px;
				line-height: 1.69;
				color: #4d545a;
				margin: 0 0 7px;
			}
		}
	}

	.dash-image-divider-container {
		min-width: 1440px;
		max-height: 263px;
		margin: 0 0 58px;
		overflow: hidden;

		.img-container {
			width: 105vw;
			@media all and ( min-width: $breakpoint-small ) {
				width: 25%;
			}
		}
	}

	.dash-faq-container {
		max-width: 970px;
		margin: 0 auto;
		padding: 0 0 31px;
		@media all and ( min-width: $breakpoint-medium ) {
			padding: 0 0 100px;
		}

		h2.faq-title {
			font-family: $global-font-family;
			font-size: 16px;
			font-weight: 900;
			line-height: normal;
			letter-spacing: 0.48px;
			text-align: center;
			color: $brand-orange;
			margin: 0 0 20px;
			@media all and ( min-width: $breakpoint-medium ) {
				font-size: 20px;
				letter-spacing: 0.6px;
				margin: 0 0 35px;
			}
		}

		ul {
			li { 
				box-shadow: 0 0 7px 0 rgba( 0, 0, 0, 0.25 );
				margin: 0 0 15px;
				position: relative;
				@media all and ( min-width: $breakpoint-medium ) {
					margin: 0 0 30px;
				}

				&.uk-open {
					.faq-question {
						&::after {
							transform: rotate( 180deg );
						}
					}
				}
		
				.faq-question {
					font-size: 24px;
					line-height: 1.33;
					font-family: $global-font-family;
					font-weight: bold;			
					color: $gray-400;
					padding: 25px 70px 25px 12px;
					@media all and ( min-width: $breakpoint-medium ) {
						line-height: 1.23;
						padding: 30px 90px 26px 30px;
						font-size: 26px;
					}

					&:active,
					&:focus,
					&:hover {
						color: $gray-300;
					}

					&::after {
						content: "\f078";
						font-family: $font-awesome-font-family;
						position: absolute;
						right: 12px;
						top: 25px;
						font-size: 24px;
						line-height: 1.33;
						color: $gray-400;
						transition: all .3s;
						@media all and ( min-width: $breakpoint-medium ) {
							right: 30px;
							top: 30px;
							font-size: 26px;
  						line-height: 1.23;
						}
					}
				}

				.faq-answer {
					font-family: $global-font-family;
					font-size: 23px;
					line-height: 1.26;
					color: $gray-400;
					padding: 0 12px 25px;
					margin-top: 0;
					@media all and ( min-width: $breakpoint-medium ) {
						padding: 0 30px 26px;
					}
				}
			}
		}

		.faq-info-container {
			margin: 10px 0 0;

			p {
				font-family: $global-font-family;
				font-size: 23px;
				line-height: 1.26;
				text-align: center;
				color: #4d545a;
			}			
		}
	}

	hr.divider-line {
		border-color: #9db1c3;
	}

	.dash-shop-container {
		padding: 32px 0 33px;
		text-align: center;
		margin: 0 auto;
		max-width: 970px;
		@media all and ( min-width: $breakpoint-medium ) {
			padding: 78px 0;
		}

		.shop-info-container {
			h2 {
				font-family: $global-font-family;
				font-size: 16px;
				font-weight: 900;
				line-height: normal;
				letter-spacing: 0.48px;
				color: $brand-orange;
				margin: 13px 0;
				@media all and ( min-width: $breakpoint-medium ) {
					margin: 22px 0 13px;
					font-size: 20px;
					letter-spacing: 0.6px;
				}
			}

			p {
				font-family: $global-font-family;
				font-size: 29px;
				font-weight: bold;
				line-height: 1.14;
				color: #4d545a;
				@media all and ( min-width: $breakpoint-medium ) {
					font-size: 45px;
					line-height: 1.22;
				}

				a {
					text-decoration: underline;

					&:active,
					&:focus,
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}

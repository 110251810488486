
// Adding .content-page class to increase specificity to help override default plugin styles
.content-page .wpdt-c {
	table,
	.wpDataTablesWrapper table.wpDataTable {
		& > thead,
		& > tbody,
		& > tfoot {
			& > tr {
				& > th,
				& > td {
					color: $base-body-color;
				}

				& > th {
					font-weight: $font-weight-bold;
				}
			}
		}
	}

	table {
		thead,
		tbody,
		tfoot {
			th,
			td {
				font-family: $global-font-family !important;
				font-size: 15px;
				font-weight: $font-weight-medium;
			}
		}
	}
}
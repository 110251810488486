
.uk-navbar-nav {
	& > li {
		& > a.uk-open,
		&.uk-active > a.uk-open {
			border-bottom-color: $gray-200;
			color: $gray-200;
		}
	}
}

.header-navbar {
	& > ul > li {
		margin: 0 $navbar-nav-item-margin-horizontal;

		@include media-breakpoint-up(xl) {
			margin: 0 $navbar-nav-item-margin-horizontal-xl;
		}

		&.menu-item-has-children > a {
			&:after {
				@include make-fa-icon;
				@include fa-solid;

				content: fa-content($fa-var-caret-down);
				font-size: 0.7rem;
				line-height: 1;
				margin-top: 5px;
				margin-right: -10px;
				padding: 0 10px;
			}
		}
	}

	.uk-navbar-nav {
		position: relative;
	}

	.uk-navbar-dropdown {
		&.navbar-dropdown-2-column-width {
			.uk-navbar-dropdown-nav > li {
				@extend .uk-width-1-2;
			}
		}

		&.navbar-dropdown-3-column-width {
			.uk-navbar-dropdown-nav > li {
				width: percentage(1 / 3);
			}
		}

		&.uk-open {
			z-index: -1;

			// 15px is average width of drop shadow so it gets covered
			&:before {
				content: "";
				background-color: $brand-orange;
				z-index: 1;
				position: absolute;
				top: -15px;
				right: -15px;
				left: -15px;
				height: 15px;
			}

			&.navbar-dropdown-has-featured-item {
				.navbar-dropdown-inner-wrap {
					@media all and ( min-width: $breakpoint-large ) {
						height: 385px;
					}
				}
			}


			.navbar-dropdown-inner-wrap {
				position: relative;
				overflow: hidden;

				&:before {
					content: "";
					box-shadow: $global-medium-box-shadow;
					z-index: 1;
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
				}
			}
		}
	}

	.uk-navbar-dropdown-nav {
		padding-left: 60px - $global-gutter;

		& > li {
			padding-left: $global-gutter;

			&.menu-item-featured {
				position: relative;
				margin: ((28px - ($navbar-dropdown-nav-item-gutter-vertical / 2)) * -1) 0;

				& > a {
					@extend .uk-button;
					@extend .uk-button-primary;

					position: absolute;
					bottom: 30px;
					left: -60px;
				}

				& > img {
					height: 385px;
					object-fit: cover;
				}
			}
		}
	}
}

.header-navbar-banner-wrap {
	$sticky-navbar-animation-length: 0.3s;
	$header-navbar-icon-width: 31px;

	&:not(.uk-sticky) {
		.header-navbar {
			.header-logo-icon,
			.navbar-button-wrap {
				display: none;
			}
		}
	}

	&.uk-sticky {
		.header-navbar {
			.header-logo-icon,
			.navbar-button-wrap {
				position: absolute;
			}

			.header-logo-icon {
				left: 0;
				margin-right: (40px / 2) - $navbar-nav-item-padding-horizontal;

				a:hover {
					position: relative;

					.hover-state {
						opacity: 1;
					}

					img + img {
						opacity: 0;
					}
				}

				img {
					transition: opacity 0.2s ease;
				}

				.hover-state {
					opacity: 0;
					position: absolute;
				}
			}

			.navbar-button-wrap {
				top: 0;
				right: 0;
			}
		}
	}

	&:not(.uk-active) {
		.header-navbar {
			.header-logo-icon,
			.navbar-button-wrap {
				opacity: 0;
				transition: opacity $sticky-navbar-animation-length ease;

				& > a {
					visibility: hidden;
					transition: visibility 0s ease $sticky-navbar-animation-length;
				}
			}

			.navbar-button-wrap {
				top: -100px;
				transition-property: opacity, top;
				transition-timing-function: ease-out;
			}

			.uk-navbar-nav {
				transition: margin $sticky-navbar-animation-length ease-out;
			}

			& > ul > li {
				transition: margin $sticky-navbar-animation-length ease-out;
			}
		}
	}

	&.uk-sticky.uk-active {
		@include media-breakpoint-down(lg) {
			position: relative !important;
			width: auto !important;
		}

		@include media-breakpoint-up(xl) {
			background-color: $brand-orange;

			.header-navbar {
				.uk-navbar-nav {
					margin: 0 0 0 ($header-navbar-icon-width + ($navbar-nav-item-padding-horizontal * 2) + ((40px / 2) - $navbar-nav-item-padding-horizontal));
				}

				.navbar-button-wrap {
					margin-left: auto;
				}

				& > ul > li {
					margin: 0 (40px / 2) - $navbar-nav-item-padding-horizontal;
				}
			}
		}
	}

	.header-navbar {
		@extend .uk-visible\@l;
	}
}

.uk-sticky-placeholder {
	@extend .uk-visible\@xl;
}
.dogtopia-header-v2-container {
	z-index: 5;

  div.top-banner-video-holder {
    height: 500px;
    min-height: 0;
  }

  .header-navbar-banner-wrap {
    .uk-navbar-nav > li > a,
    .uk-navbar-item {
      min-height: 60px;
			@media all and ( min-width: $breakpoint-large ) {
				font-size: 18px;
			}

			@media all and ( min-width: $breakpoint-xlarge ) {
				font-size: inherit;
			}
    }
  }

	.uk-sticky-fixed {
		.uk-navbar-nav > li > a {
			font-size: 20px;
		}
		
		.menu-item-has-children > a:after {
			margin-top: 2px;
		}
	}

  .header-logo {
    width: 100%;
    @include media-breakpoint-down(md) {
      width: auto;
    }
  }

  .mobile-collapsible-nav-wrap {
    .social-account-icons {
      a {
        &:active,
        &:focus,
        &:hover {
          opacity: .7;
        }

        img {
          filter: brightness(200%);
        }
      }
    }
  }
}




.dogtopia-mobile-home-header-container {
  width: 100%;
  height: 400px;
  background: $brand-orange;
  position: relative;
  background: linear-gradient( 360deg, rgba( 0, 0, 0, 0.4 ) 21%, rgba( 0, 0, 0, 0 ) 56.5%), url( 
    "../images/home/mobile-hero-banner.jpeg"
  );
  background-size: cover;
  background-position: center center;
  @include media-breakpoint-down(md) {
    height: 323px;
  }
  
  @include media-breakpoint-down(sm) {
    height: 223px;
  }

  .page-header-banner-slogan {
    position: absolute;
    bottom: 53px;
    left: 16px;

    img {
      filter: drop-shadow( 0px 4px 4px rgba( 0, 0, 0, 0.34 ) );
    }
  }
}

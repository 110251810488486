
.content-store-locator-atwork {


	@media only screen and (min-width: 992px) {
		.coming-soon-locations-grid > *, .uk-child-width-1-4\@lg > * {
			width: 255px !important;
		}
	}
	
	@media (min-width: 660px) {
		.coming-soon-locations-grid>*, .team-members-grid>*, .uk-child-width-1-3\@ms>* {
			  width: 250px !important;
		}
		
	}
	
	ol, p, ul {
		margin-bottom: 0.0rem;
		margin-top: 0.0rem !important;

	}	

	.content-footer-promo-block{
		padding-bottom: 6vw !important;
	}

	@media (min-width: 1500px) {
		.content-footer-promo-block {
			padding-bottom: 70px !important;
			padding-right: 215px !important;
		}
	}


	
}

	




@mixin make-uk-position-top(){
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
}

@mixin make-uk-child-width-ready(){
	@extend #{"[class*='uk-child-width']"};
}

@mixin make-uk-width-ready(){
	flex-grow: 0;
	flex-basis: auto;
	min-width: unset;
	max-width: 100%;
}

@mixin make-x-cols-wide($num-cols, $container-width: $container-max-width){
	@if($num-cols % 1 == 0.5){
		width: ((($container-width + $global-gutter) / $grid-columns) * $num-cols) - ($global-gutter / 2);
	}@else{
		width: ((($container-width + $global-gutter) / $grid-columns) * $num-cols) - $global-gutter;
	}
}

@mixin make-uk-width-offset($size, $side: "left", $columns: $grid-columns){
	margin-#{$side}: percentage($size / $columns);
}

@mixin h1-heading-highlight {
	color: $brand-orange;
	font-size: 1.75rem;
	line-height: normal;
	display: block;
	margin-bottom: -8px;
}

@mixin h2-heading-highlight {
	color: $brand-yellow;
	font-size: $base-h3-font-size;
	font-weight: $font-weight-black;
	line-height: normal;
	display: block;
	margin-bottom: 2px;
}

@mixin colored-bullet-list($li-font-size: $global-font-size, $li-line-height: $global-line-height) {
	list-style: none;
	padding-left: 0;

	& > li {
		position: relative;
		padding-left: $base-list-padding-left;

		&:before {
			content: "";
			background-color: $brand-yellow;
			position: absolute;
			top: (((($li-font-size / 1rem) * 16px) * $li-line-height) / 2) - ($colored-bullet-width-height / 2);
			left: 0;
			width: $colored-bullet-width-height;
			height: $colored-bullet-width-height;
		}
	}
}


// Font Awesome helper mixins

@mixin make-fa-icon {
	@include fa-icon;

	font-family: $font-awesome-font-family;
}

@mixin fa-brands {
	font-family: $font-awesome-brands-font-family;
	font-weight: 400;
}

@mixin fa-solid {
	font-weight: $font-awesome-solid-weight;
}

@mixin fa-regular {
	font-weight: $font-awesome-regular-weight;
}

@mixin fa-light {
	font-weight: $font-awesome-light-weight;
}
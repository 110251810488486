
.content-experts {
	.uk-grid {
		margin-top: 0;
	}

	.main-content-column-wrap {
		margin-top: 20px;
	}

	.main-content-column {
		@extend .uk-width-4-5\@sm;
		@extend .uk-width-3-5\@md;
		@extend .uk-width-1-1\@lg;
		@extend .uk-width-4-5\@xl;
	}

	.main-content-elements-wrap {
		.main-content-element-block {
			@extend .uk-clearfix;
			@extend .uk-width-1-2\@lg;

			margin-top: 70px;

			&.dog-expert-card-element-block {
				margin-top: 140px;
			}
		}
	}

	.blog-promo-wrap {
		margin-top: 70px;

		.section-title {
			h3 {
				color: $brand-orange;
			}
		}
	}

	@include media-breakpoint-down(xs) {
		.blog-posts-grid-row {
			padding-bottom: 0 !important;
		}
	}

	@include media-breakpoint-between(sm, md) {
		.main-content-column {
			margin-right: auto;
			margin-left: auto;
		}
	}

	@include media-breakpoint-up(lg) {
		.main-content-elements-wrap {
			margin-left: (($global-large-gutter + 10) - $global-gutter) * -1;

			.main-content-element-block {
				float: left;
				padding-left: ($global-large-gutter + 10) - $global-gutter;

				&:nth-child(even) {
					float: right;
				}

				&:last-child {
					margin-bottom: -40px;
				}

				&.dog-expert-card-element-block:first-child {
					margin-top: -90px;
				}
			}
		}
	}

	@include media-breakpoint-only(lg) {
		.main-content-elements-wrap {
			.main-content-element-block {
				padding-right: $global-gutter;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.main-content-column {
			@include make-uk-width-offset(1);
		}

		.main-content-elements-wrap {
			margin-left: (($global-large-gutter + 10) * -1);

			.main-content-element-block {
				padding-left: ($global-large-gutter + 10);
			}
		}
	}
}

.experts-ask-question-modal {
	.uk-modal-dialog {
		width: 540px;
	}

	button[class*=uk-modal-close-] {
		&:hover {
			margin-top: $icon-hover-offset;
			padding-bottom: (($modal-close-padding + $icon-hover-offset) * -1);
			box-shadow: none;

			&:after {
				text-shadow: 0 7px 4px rgba(0, 0, 0, 0.5);
			}
		}
	}

	.gform_wrapper {
		.gfield_checkbox {
			.gfield_label .gfield_required {
				display: none;
			}
		}
	}
}
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/light.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands.scss";

@import "common/variables";

@import "../../node_modules/uikit/src/scss/variables.scss";
@import "../../node_modules/uikit/src/scss/mixins.scss";

@import "common/mixins";

@import "components/mixins/breakpoints";

@import "components/mixins/base";
@import "components/mixins/buttons";
@import "components/mixins/label";
@import "components/mixins/alert";
@import "components/mixins/forms";
@import "components/mixins/nav";
@import "components/mixins/pagination";
@import "components/mixins/container";
@import "components/mixins/grid";
@import "components/mixins/card";
@import "components/mixins/navbar";
@import "components/mixins/modal";
@import "components/mixins/width";
@import "components/mixins/flex";
@import "components/mixins/visibility";

@import "../../node_modules/uikit/src/scss/uikit.scss";

// @import url("https://use.typekit.net/lwa0jfb.css");
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * brandon-grotesque:
 *   - http://typekit.com/eulas/0000000000000000000132df
 *   - http://typekit.com/eulas/0000000000000000000132e0
 *   - http://typekit.com/eulas/0000000000000000000132e3
 *   - http://typekit.com/eulas/0000000000000000000132e4
 *   - http://typekit.com/eulas/0000000000000000000132e1
 *   - http://typekit.com/eulas/0000000000000000000132e2
 *   - http://typekit.com/eulas/0000000000000000000132e5
 *
 * © 2009-2019 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2018-07-19 18:05:15 UTC"}*/
@import url("https://p.typekit.net/p.css?s=1&k=lwa0jfb&ht=tk&f=10294.10295.10296.10297.10302.10303.10304&a=1153055&app=typekit&e=css");
@font-face {
  font-family:"brandon-grotesque";
  src:url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:400;
}
@font-face {
  font-family:"brandon-grotesque";
  src:url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display:swap;font-style:italic;font-weight:400;
}
@font-face {
  font-family:"brandon-grotesque";
  src:url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:700;
}
@font-face {
  font-family:"brandon-grotesque";
  src:url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:swap;font-style:italic;font-weight:700;
}
@font-face {
  font-family:"brandon-grotesque";
  src:url("https://use.typekit.net/af/d8f71f/0000000000000000000132e1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/d8f71f/0000000000000000000132e1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/d8f71f/0000000000000000000132e1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:500;
}
@font-face {
  font-family:"brandon-grotesque";
  src:url("https://use.typekit.net/af/ba2c35/0000000000000000000132e2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/ba2c35/0000000000000000000132e2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/ba2c35/0000000000000000000132e2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
  font-display:swap;font-style:italic;font-weight:500;
}
@font-face {
  font-family:"brandon-grotesque";
  src:url("https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:900;
}



@font-face {
  font-family: "brandon-text";
  src: url( '../fonts/brandontext-bold.eot' ) format( 'embedded-opentype' ),
  url( '../fonts/brandontext-bold.otf' ) format( 'opentype' ),
  url( '../fonts/brandontext-bold.woff' ) format( 'woff' ),
  url( '../fonts/brandontext-bold.ttf' ) format( 'truetype' );
  font-display: swap; font-style: normal; font-weight: 700;
}

@font-face {
  font-family: "brandon-text";
  src: url( '../fonts/brandontext-bolditalic.eot' ) format( 'embedded-opentype' ),
  url( '../fonts/brandontext-bolditalic.otf' ) format( 'opentype' ),
  url( '../fonts/brandontext-bolditalic.woff' ) format( 'woff' ),
  url( '../fonts/brandontext-bolditalic.ttf' ) format( 'truetype' );
  font-display: swap; font-style: italic; font-weight: 700;
}

@font-face {
  font-family: "brandon-text-light";
  src: url( '../fonts/brandontext-light.eot' ) format( 'embedded-opentype' ),
  url( '../fonts/brandontext-light.otf' ) format( 'opentype' ),
  url( '../fonts/brandontext-light.woff' ) format( 'woff' ),
  url( '../fonts/brandontext-light.ttf' ) format( 'truetype' );
  font-display: swap; font-style: normal; font-weight: 300;
}

@font-face {
  font-family: "brandon-text";
  src: url( '../fonts/brandontext-lightitalic.eot' ) format( 'embedded-opentype' ),
  url( '../fonts/brandontext-lightitalic.otf' ) format( 'opentype' ),
  url( '../fonts/brandontext-lightitalic.woff' ) format( 'woff' ),
  url( '../fonts/brandontext-lightitalic.ttf' ) format( 'truetype' );
  font-display: swap; font-style: italic; font-weight: 300;
}

@font-face {
  font-family: "brandon-text";
  src: url( '../fonts/brandontext-medium.eot' ) format( 'embedded-opentype' ),
  url( '../fonts/brandontext-medium.otf' ) format( 'opentype' ),
  url( '../fonts/brandontext-medium.woff' ) format( 'woff' ),
  url( '../fonts/brandontext-medium.ttf' ) format( 'truetype' );
  font-display: swap; font-style: normal; font-weight: 500;
}

@font-face {
  font-family: "brandon-text";
  src: url( '../fonts/brandontext-mediumitalic.eot' ) format( 'embedded-opentype' ),
  url( '../fonts/brandontext-mediumitalic.otf' ) format( 'opentype' ),
  url( '../fonts/brandontext-mediumitalic.woff' ) format( 'woff' ),
  url( '../fonts/brandontext-mediumitalic.ttf' ) format( 'truetype' );
  font-display: swap; font-style: italic; font-weight: 500;
}

@font-face {
  font-family: "brandon-text";
  src: url( '../fonts/brandontext-regular.eot' ) format( 'embedded-opentype' ),
  url( '../fonts/brandontext-regular.otf' ) format( 'opentype' ),
  url( '../fonts/brandontext-regular.woff' ) format( 'woff' ),
  url( '../fonts/brandontext-regular.ttf' ) format( 'truetype' );
  font-display: swap; font-style: normal; font-weight: 400;
}

@font-face {
  font-family: "brandon-text";
  src: url( '../fonts/brandontext-regularitalic.eot' ) format( 'embedded-opentype' ),
  url( '../fonts/brandontext-regularitalic.otf' ) format( 'opentype' ),
  url( '../fonts/brandontext-regularitalic.woff' ) format( 'woff' ),
  url( '../fonts/brandontext-regularitalic.ttf' ) format( 'truetype' );
  font-display: swap; font-style: italic; font-weight: 400;
}


@font-face {
  font-family: 'signpainter';
  src: url('../fonts/signpainter_housescript_regular-webfont.eot');
  src: url('../fonts/signpainter_housescript_regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/signpainter_housescript_regular-webfont.woff2') format('woff2'),
       url('../fonts/signpainter_housescript_regular-webfont.woff') format('woff'),
       url('../fonts/signpainter_housescript_regular-webfont.ttf') format('truetype'),
       url('../fonts/signpainter_housescript_regular-webfont.svg#signpainter_housescriptRg') format('svg');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

.tk-brandon-grotesque { font-family: "brandon-grotesque",sans-serif; }

@import "common/global";

@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/media-blocks";
@import "components/cards";
@import "components/navbar";
@import "components/slideshows";
@import "components/pagination";
@import "components/modals";
//@import "components/grid";
@import "components/wp-classes";

@import "vendor_overrides/wp-datatables";
@import "vendor_overrides/highcharts";
@import "vendor_overrides/ui-datepicker";

@import "layouts/general";
@import "layouts/core/header";
@import "layouts/core/footer";
@import "layouts/core/pages";
@import "layouts/core/posts";
@import "layouts/core/promo";
@import "layouts/core/blog";
@import "layouts/core/extra-nav";
@import "layouts/core/faq";
@import "layouts/core/404";

.team-page-v2-container {
  h4 {
    span.surname {
      font-family: $sign-painter;
      color: $brand-orange;
      display: inline;
      text-transform: lowercase;
      font-weight: 500;
      font-size: 36px;
      line-height: 29px;
    }
  }

	.bg-white-menu {
		padding: 20px;
		width: 100%;
		left: 0;
		top: 0;
		background: $global-background;
		box-shadow: 0px -2px 2px 2px rgba(0, 0, 0, .04),
							  -1px 0px 2px 2px rgba(0, 0, 0, .02),
								0px 4px 4px rgba(0, 0, 0, .1);
		@media all and ( min-width: $breakpoint-medium-small ) {
			padding: 20px 50px 20px 20px;
			width: 421px;
		}
	
		h3 {
			color: $gray-300;
			font-family: $global-font-family;
			font-weight: 800;
			font-size: 26px;
			line-height: 29px;
		}

		a {
			font-family: $global-font-family;
			font-weight: 450;
			font-size: 20px;
			line-height: 29px;
			align-items: center;
			color: $gray-300;
			padding: 0;
			
			&:hover {
				text-decoration: none;
			}

			&:after {
				font-family: $font-awesome-font-family;
				content: "\f105";
				display: inline-block;
				padding: 0 0 0 5px;
				color: $gray-300;
				font-size: 17px;
				font-weight: 400;
			}
		}

		.borderp {
			border-bottom: 3px solid $brand-orange;
		}

		p {
			padding: 10px 0;
			margin: 0 auto;
		}
	}
}

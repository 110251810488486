
.post-pagination-wrap {
	.pagination-item {
		@extend .uk-width-1-2;
		@extend .uk-width-1-3\@xl;

		& > a {
			transition: padding 0.2s ease, margin 0.2s ease;
		}

		@include media-breakpoint-down(xs) {
			.pagination-card {
				box-shadow: none;

				&.blog-post-card {
					.uk-card-body {
						padding: 5px 10px;
						width: 100%;

						.uk-card-title {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		@media (max-width: 399px) {
			width: 100%;
		}

		@media (min-width: 400px) and (max-width: $breakpoint-xsmall-max) {
			&.pagination-item-previous {
				.pagination-card.blog-post-card {
					.uk-card-body {
						padding-right: 0;
					}
				}
			}

			&.pagination-item-next {
				.pagination-card.blog-post-card {
					.uk-card-body {
						padding-left: 0;
					}
				}
			}
		}

		@include media-breakpoint-between(sm, lg) {
			width: 46%;
		}
	}

	.pagination-item-next {
		text-align: right;
		margin-left: auto;

		.media-block-img {
			order: 2;
		}
	}
}

.footer-anchored-pagination {
	padding-top: 25px;

	.pagination-item {
		display: flex;
		padding-left: 0;

		& > * {
			width: 100%;
		}

		& > a:hover {
			margin-top: -15px;

			.uk-card-body {
				padding-bottom: 45px;
			}
		}

		.pagination-card {
			height: 100%;
		}

		.uk-card-body {
			transition: padding 0.2s ease;
		}
	}

	@include media-breakpoint-down(sm) {
		margin-right: ($global-small-gutter * -1);
		margin-left: ($global-small-gutter * -1);
	}

	@include media-breakpoint-up(md) {
		margin-right: ($footer-anchored-pagination-card-horizontal-breakout * -1);
		margin-left: ($footer-anchored-pagination-card-horizontal-breakout * -1);

		.pagination-item-previous {
			.uk-card-body {
				padding-left: $footer-anchored-pagination-card-horizontal-breakout + 20px;
			}
		}

		.pagination-item-next {
			.uk-card-body {
				padding-right: $footer-anchored-pagination-card-horizontal-breakout + 20px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		background-image: url("../images/scruffy-content-footer-pagination.png");
		background-repeat: no-repeat;
		background-position: top 17px center;
	}
}
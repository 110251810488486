
.header-wrap {
	background-color: $brand-orange;
	color: $global-inverse-color;
	box-shadow: $global-medium-box-shadow;
	position: relative;

	.uk-grid {
		@extend .uk-flex-middle;
		@include media-breakpoint-down(sm) {
			flex-wrap: nowrap;
		}
	}

	.nav-toggler-column {
		@extend .uk-hidden\@l;

		margin-right: 30px - $global-small-gutter;

		@include media-breakpoint-down(sm) {
			margin-left: ($global-small-gutter * -1);
		}

		@media (max-width: 339px) {
			margin-right: 0;
		}
	}

	.social-account-icons {
		font-size: 1.35rem;
	}
}

.has-top-banner-media {
	.top-banner-media-holder > img,
	.top-banner-video-holder {
		@extend .uk-visible\@xl;
	}

	.header-top-banner-elements {
		@include media-breakpoint-up(lg) {
			@include make-uk-position-top;

			width: 100%;
		}
	}
}

.top-banner-media-wrap {
	overflow: hidden;

	.top-banner-media {
		margin-left: -200%;
		width: 500%;

		img {
			@extend .uk-preserve-width;

			display: block;
			margin: 0 auto;
		}
	}
}

.header-top-banner-elements {
	@include media-breakpoint-up(lg) {
		padding: 30px 0 20px 0;
	}

	.uk-grid {
		@extend .uk-flex-middle;
	}

	.social-account-icons {
		@extend .uk-visible\@l;
	}

	.secondary-header-nav {
		@extend .uk-visible\@s;
	}

	.nav-item.sign-in-nav-item {
		@extend .uk-visible\@m;
	}
}

.header-logo {
	& > a {
		display: inline-block;
	}

	img {
		height: $header-logo-height;

		@include media-breakpoint-up(lg) {
			height: $header-logo-height-lg;
		}

		@media (max-width: 339px) {
			height: $header-logo-height - 1;
		}
	}
}

.secondary-header-nav {
	font-size: 0;
	text-align: right;

	.nav-item {
		font-size: 1.25rem;
		display: block;
		@include media-breakpoint-up(md) {
			vertical-align: middle;
			display: inline-block;
			& + .nav-item {
				margin-left: $header-top-banner-element-gutter;
			}
		}
		@include media-breakpoint-down(sm) {
			padding-top: 9px;
			padding-bottom: 9px;
		}

		a {
			color: inherit;
			font-weight: $font-weight-medium;
		}
	}
}

.header-navbar-banner-wrap {
	z-index: $global-z-index;
	position: relative;

	.uk-navbar-container {
		@extend .uk-navbar-transparent;
	}
}

.collapsible-nav-wrap {
	$collapsible-nav-item-gutter-vertical: 17px;
	$collapsible-nav-item-padding-vertical: 8px;

	background-color: $brand-orange;
	/*overflow: scroll;*/
	overflow: hidden;
	z-index: 5;
	position: fixed;
	top: $navbar-toggle-height - 1px;
	right: 0;
	bottom: 100%;
	left: 0;
	transition: bottom 0.5s ease-out;

	.collapsible-nav-content-wrap {
		padding-bottom: 100px;
	}

	.header-navbar {
		margin: 0;
		width: 100%;

		ul {
			display: block;
			width: 100%;

			& > li {
				text-align: center;
				display: block;
				margin: 0;

				&:hover > a {
					color: inherit;
				}

				&.menu-item-has-children > a {
					&:after {
						transition: transform 0.2s ease-out;
					}

					&.uk-open:after {
						transform: rotate(180deg);
					}
				}

				& > a {
					border-bottom: $navbar-nav-item-active-border-width solid transparent;
					margin: 0 auto;
					padding-right: 10px;
					padding-left: 10px;
					display: inline-block;
				}

				& > a:hover,
				& > a:focus,
				&.uk-active > a,
				& > a.uk-open {
					border-bottom-color: $global-inverse-color;
				}
			}
		}

		.uk-navbar-nav {
			& > li {
				position: relative;
				padding-top: ($collapsible-nav-item-gutter-vertical - $collapsible-nav-item-padding-vertical);
				padding-bottom: ($collapsible-nav-item-gutter-vertical - $collapsible-nav-item-padding-vertical);

				& > a {
					line-height: 1.46;
					padding-top: $collapsible-nav-item-padding-vertical;
					padding-bottom: ($collapsible-nav-item-padding-vertical - $navbar-nav-item-active-border-width);
					min-height: unset;

					&:before {
						content: "";
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
					}

					&:after {
						vertical-align: middle;
						margin-top: 0;
					}

					&:focus,
					&.uk-open {
						color: inherit;
					}
				}
			}
		}

		.uk-navbar-dropdown {
			color: $global-inverse-color;
			background-color: rgba(white, 0.15);
			display: block;
			overflow: hidden;
			position: static;
			padding: 0;
			width: 100%;
			height: 0;
			transition: height 0.5s ease-out;

			&:before {
				display: none;
			}

			.navbar-dropdown-inner-wrap {
				background: transparent;
				box-shadow: none;
				height: auto;

				&:before {
					display: none;
				}
			}

			.uk-navbar-dropdown-nav {
				margin: 10px 0 15px 0;
				padding: 0;

				& > li {
					padding: 2px 0;
					width: auto;

					&.menu-item-featured {
						display: none;
					}

					& > a {
						color: inherit;
						font-size: $navbar-nav-item-font-size;
						text-align: center;
						padding-bottom: 6px 0 (6px - $navbar-nav-item-active-border-width) 0;
					}
				}
			}
		}
	}

	.social-account-icons {
		margin: (50px - $collapsible-nav-item-gutter-vertical) 0 50px 0;

		.uk-iconnav {
			@extend .uk-flex-center;
		}
	}

	.collapsible-nav-slogan-wrap {
		text-align: center;
		padding: 0 20px;

		img {
			max-height: 105px;
		}
	}

	&.mobile-collapsible-nav-wrap {
		@extend .uk-hidden\@l;
		background-color: $gray-300;

		.collapsible-nav-content-wrap {
			background-color: $brand-orange;
			padding-top: 20px;
			padding-bottom: 50px;
		}

		.secondary-header-nav {
			text-align: center;
			margin-bottom: 10px;

			.store-locator-nav-item {
				@extend .uk-hidden\@s;
			}
		}

		.collapsible-nav-footer-wrap {
			padding: 40px 30px;

			@include media-breakpoint-up(xs) {
				padding: 60px;
			}

			.uk-grid {
				@include make-uk-child-width-ready();

				@extend .uk-flex-middle;
			}

			.social-account-icons,
			.national-site-link {
				@extend .uk-width-1-2\@sm;
				@extend .uk-width-2-5\@ms;
			}

			.social-account-icons {
				margin-top: 0;
				margin-bottom: 0;

				.uk-iconnav {
					@extend .uk-flex-left\@s;
				}

				@include media-breakpoint-up(ms) {
					@include make-uk-width-offset(1);
				}
			}

			.national-site-link {
				text-align: center;

				&.uk-grid-margin {
					margin-top: 40px;
				}

				@include media-breakpoint-up(sm) {
					text-align: right;
				}

				a {
					color: inherit;
					font-size: 1.1rem;
					font-weight: $font-weight-medium;
				}
			}

			.collapsible-nav-slogan-wrap {
				margin-top: 50px;

				img {
					max-height: 120px;
				}

				@include media-breakpoint-down(xs) {
					padding: 0;
				}
			}
		}
	}
}

body {
	&.admin-bar {
		.collapsible-nav-wrap {
			top: $navbar-toggle-height + 45px;

			@include media-breakpoint-up(md) {
				top: $navbar-toggle-height + 31px;
			}
		}
	}

	&.mobile-nav-open {
		position: fixed;
		width: 100%;

		&.admin-bar {
			position: fixed !important;
		}

		.collapsible-nav-wrap {
			bottom: 0;
			overflow-y: scroll;
		}
	}
}
.content-store-locator {
    background-image: url("../images/triangle-bg-left-blue.png");
    background-repeat: no-repeat;
    background-position: top 75px left;
    & > .uk-container > .uk-grid:not(:first-child) {
        margin-top: 50px;
    }
    .page-title {
        @extend .uk-width-4-5\@sm;
    }
    .coming-soon-locations-column {
        @extend .uk-width-4-5\@xl;
        position: relative;
        z-index: 2;
    }
    .franchise-promo-block-column {
        @extend .uk-width-4-5\@md;
        @extend .uk-width-3-5\@xl;
    }
    .scruffy-slogan-image-column {
        @extend .uk-visible\@xl;
        width: 30%;
        img {
            position: absolute;
            bottom: -30px;
            left: $global-large-gutter;
        }
    }
    @include media-breakpoint-down(xs) {
        background-position: top 75px left -80px;
    }
    @media (max-width: 390px) {
        background-position: top 140px left -80px;
    }
    @include media-breakpoint-only(sm) {
        .franchise-promo-block-column {
            @include make-uk-width-offset(1);
            width: percentage(8 / $grid-columns);
        }
    }
    @include media-breakpoint-up(sm) {
        .page-title {
            @include make-uk-width-offset(1);
        }
    }
    @include media-breakpoint-up(md) {
        .franchise-promo-block-column {
            @include make-uk-width-offset(1);
        }
    }
    @include media-breakpoint-up(lg) {
        & > .uk-container > .uk-grid:not(:first-child) {
            margin-top: 70px;
        }
    }
    @include media-breakpoint-up(xl) {
        .coming-soon-locations-column{
            @include make-uk-width-offset(1);
        }
	} 

	#locator-page-title {
		float: left; 
		width: 40%;
	}
	 
	.at-work-store-locator-call-to-action {
		margin-left: 620px;
		position: relative;
		left: -125px;
		top: -65px;
	}

    .promo-heading-atwork {
		font-size: 26px;
		font-weight: revert;
		width: 585px;
		position: absolute;
		top: 26px;
		box-shadow: -5px 4px 15px -2px #d3d3d3;
		padding: 20px;
		float: right;
		top: -1vh;
		right: -490px;
		background: #fff;
	}

	@media (min-width: 1280px) {

		.image-button-cta-atwork {
			top: -40px !important;
		}
	}
	
	@media (min-width: 550px) and (max-width: 660px) {

		.promo-heading-atwork {
			top: 10px!important;
			right: -440px;
			font-size: 26px !important;
			width: 450px;
		}
	}
	@media (min-width: 1480px) {
		#calloutWorkAt {
			position: relative;
			top: 30px;
			margin-left: -20px;
		}
	}

	@media (min-width: 990px) and (max-width: 1240px) {
		#calloutWorkAt {
			left: -158px !important;
		}
	}

	@media (min-width: 990px) and (max-width: 1280px) {
		.image-button-cta-atwork {
			top: -43px !important;

		}
	}
	@media (max-width: 1480px) {
		#calloutWorkAt {
			position: relative;
			float: right;
			top: 30px;
			left: -20px;
		}
	}
	
	@media (max-width: 990px) {
		#at-work-locator-image {
			display: none !important;

		}
		.at-work-store-locator-call-to-action {
			margin-left: 345px;
			margin-top: 120px;
			top: -70px;
		}
		#calloutWorkAt {
			position: relative;
			left: -123px;
		}
		.promo-heading-atwork {
			font-size: 1em;
			font-weight: revert;
			width: 350px;
			position: absolute;
			top: 26px;
			box-shadow: -5px 4px 15px -2px #d3d3d3;
			padding: 25px;
			float: right;
			top: -45px;
			right: -347px;
			background: #fff;
			height: 175px;
		}
		
	}

	@media (max-width: 830px) {
		.promo-heading-atwork {
			right: -290px;
		}
		.at-work-store-locator-call-to-action {
			margin-left: 290px;

		}
	}

	@media (max-width: 700px) {
		& {
			background-position: top 225px left -80px !important;

		}
		.image-button-cta-atwork.promo-cta {
			top: -42px;
			transform: none;
			margin-left: -201px;
		}
		.page-title {
			width: 400px;
			top: 185px;
			position: relative;
		}
		#calloutWorkAt {
			position: relative;
			left: -136px;
			top: -120px;
		}
		.promo-heading-atwork {
			top: 0px !important;
			right: -340px;
		}
		.at-work-store-locator-call-to-action {
			left: -170px;
			z-index: 2;
			position: relative;
			top: 5px;
		}
	}


	@media (min-width: 660px) and (max-width: 700px) {
		.promo-heading-atwork {
			right: -145px;
		}
		.at-work-store-locator-call-to-action {
			left: -318px !important;
			top: 145px !important;
		}
	}

	@media (max-width: 700px) {
		.promo-heading-atwork {
			font-size: 26px;
			width: 400px;				
			top: 26px;
			box-shadow: -5px 4px 15px -2px #d3d3d3;
			background: #fff;
			height: 190px;
		}
		.at-work-store-locator-call-to-action {
			left: -255px;
			margin-top: 10px!important;
			z-index: 2;
			position: relative;
			top: 85px;
		}
		.page-title {
			width: 400px;
			top: 30px;
			position: relative;
			margin-top: 150px;
		}
		#calloutWorkAt {
			position: relative;
			top: -25px;
			left: -29vw;
		}
		.at-work-store-locator-call-to-action {
			left: -125px;
		}
	}	

	@media (width: 360px) {
		#calloutWorkAt {
			position: absolute;
			left: 136px;
			top: 109px;
		}
		.at-work-store-locator-call-to-action {
			left: -230px;
			top: 110px;
		}
		.promo-heading-atwork {
			width: 325px;
			padding: 15px;
			float: right;
			top: 5px !important;
			right: -173px;
			height: 200px;
		}
		.page-title {
			margin-top: 160px;
		}
		.page-title h1 small {
			font-size: 30px;
		}

	}

	@media (width: 411px) {
		.promo-heading-atwork {
			width: 375PX;
			right: -78vw;
			padding: 15px;
			height: 185px;
		}
		.at-work-store-locator-call-to-action {
				left: -125px;
		}
		.page-title {
			margin-top: 150px;
		}
	}

	@media (min-width: 320px) and (max-width: 359px) {
		#calloutWorkAt {
			position: absolute;
			left: 30px;
			top: 109px;
		}
		.promo-heading-atwork {
			font-size: 22px;
			width: 290px;
			right: -75vw;
			top: 10px !important;
			padding: 15px;
			height: 185px;

		}
		.at-work-store-locator-call-to-action {
			left: -125px;
			top: 95px;
		}
		.page-title {
			margin-top: 155px;
		}
		.page-title h1 small {
			font-size: 30px;

		}
	}

	@media (min-width: 361px) and (max-width: 374px) {
		#calloutWorkAt {
			position: absolute;
			left: 30px;
			top: 109px;
		}
		.promo-heading-atwork {
			font-size: 22px;
			width: 290px;
			right: -75vw;
			top: 10px !important;
			padding: 15px;
			height: 185px;

		}
		.at-work-store-locator-call-to-action {
			left: -125px;
			top: 95px;
		}
		.page-title {
			margin-top: 155px;
		}
		.page-title h1 small {
			font-size: 30px;
		}
	}
	@media (width: 375px) {
		#calloutWorkAt {
			position: relative;
			left: -112px;
			top: -25px;
		}
		.promo-heading-atwork {
			font-size: 26px;
			right: -77vw;
			width: 335px;
			padding: 15px;
			top: 3px !important;
			height: 200px;
			
		}
		.at-work-store-locator-call-to-action {
			left: -120px;
			top: 110px;
		}
		.page-title {
			margin-top: 165px;
		}
	}

	@media (width: 414px) {
		.promo-heading-atwork {
			width: 380px;
			right: -78.1vw;
			font-size: 26px;
			padding: 15px;
			top: 5px!important;
			height: 185px;
		}
		.at-work-store-locator-call-to-action {
			left: -135px;
			top: 100px;
		}
		.page-title {
			margin-top: 155px;
		}
	}

	@media (width: 667px) {
		.promo-heading-atwork {
			top: 0!important;
			right: -140px;
		}
		.at-work-store-locator-call-to-action {
			left: -325px;
			top: 145px;
		}
	}

	@media (width: 640px) {
		.promo-heading-atwork {
			top: 0!important;
			right: -320px;
		}
		.at-work-store-locator-call-to-action {
			left: -143px;
		}
	}

	@media (max-width: 319px) {
		h2, .at-work-callout {
			display: none !important;
		}
	}

.store-locator-app-wrap {
    box-shadow: $global-small-box-shadow;
    margin-top: 40px;
}
.coming-soon-locations-header {
    h2 {
        margin-bottom: 1px;
    }
    & > h3:first-child {
        @include h2-heading-highlight;
    }
}
.coming-soon-locations-grid {
    @extend .uk-child-width-1-2\@sm;
    @extend .uk-child-width-1-3\@ms;
    @extend .uk-child-width-1-4\@lg;
    margin-top: 30px;
    .coming-soon-location {
        h4 {
            line-height: $base-h3-line-height;
            margin-bottom: 10px;
        }
        p {
            line-height: 1.15;
            margin-bottom: 4px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        a {
            color: inherit;
        }
    }
}
.scruffy-slogan-image-column {
    position: relative;
    img {
        @extend .uk-preserve-width;
    }
}


}

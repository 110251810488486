.dogtopia-foundation-bar-wrap {
	box-shadow: 0 -2px 3px 5px rgba(0, 0, 0, 0.1);
	position: relative;
	padding: 40px 0;

	@include media-breakpoint-up(sm) {
		padding: 30px 0;
	}

	.dogtopia-foundation-block {
		@extend .uk-flex-middle;
		@extend .uk-flex-between\@xl;
		@extend .uk-child-width-auto;

		@include media-breakpoint-down(xs) {
			& > .foundation-cta-column {
				margin-top: 20px;
			}
		}

		.foundation-logo-column,
		.foundation-description-column {
			@include media-breakpoint-between(md, lg) {
				@include make-uk-width-offset(1);
			}

			@include media-breakpoint-down(ms) {
				padding-left: 20px + $global-small-gutter;
			}
		}

		.foundation-description-column,
		.foundation-cta-column {
			@include media-breakpoint-down(ms) {
				padding-right: 20px;
			}

			@include media-breakpoint-down(xs) {
				padding-right: 20px - $global-small-gutter;
				padding-left: 20px;
			}
		}

		.foundation-logo-column {
			@extend .uk-width-1-2\@sm;
			@extend .uk-width-2-5\@md;

			order: 3;

			@include media-breakpoint-up(sm) {
				order: 2;
			}

			@include media-breakpoint-between(sm, ms) {
				padding-right: 25px;
			}

			@include media-breakpoint-up(xl) {
				order: 1;
				width: auto;
			}

			@include media-breakpoint-down(xs) {
				margin-right: auto;
				margin-left: auto;
				padding-left: 0;
			}
		}

		.foundation-description-column {
			@include make-uk-width-ready;

			@extend .uk-width-4-5\@md;
			@extend .uk-width-1-2\@xl;

			order: 1;

			@include media-breakpoint-up(xl) {
				order: 2;
			}
		}

		.foundation-cta-column {
			@extend .uk-width-1-2\@sm;
			@extend .uk-width-2-5\@md;

			text-align: left;
			order: 2;

			@include media-breakpoint-up(sm) {
				text-align: right;
				order: 3;
			}

			@include media-breakpoint-up(xl) {
				width: auto;
			}
		}
	}
}

.footer-main-wrap {
	@include media-breakpoint-down(md) {
		.app-promo-column {
			order: 2;
		}

		.footer-full-site-nav-column {
			order: 1;
		}
	}

	&.uk-container {
		@extend .uk-container-expand;
	}

	& > .uk-grid {
		@extend .uk-child-width-expand\@l;

		@include media-breakpoint-down(md) {
			margin-right: ($global-gutter * -1);
		}

		@include media-breakpoint-down(ms) {
			margin-right: ($global-small-gutter * -1);
		}

		.footer-full-site-nav-column {
			@extend .uk-width-1-2\@lg;

			@include media-breakpoint-down(md) {
				margin: 0 auto;
				padding-left: 0;
				width: ((($container-medium-max-width + $global-gutter) / $grid-columns) * 8) - $global-gutter;
			}

			@include media-breakpoint-down(ms) {
				width: ((($container-small-max-width + $global-small-gutter) / $grid-columns) * 8) - $global-small-gutter;
			}

			@include media-breakpoint-up(lg) {
				@include make-uk-width-ready();

				margin-left: (((($container-large-max-width + $global-gutter) / $grid-columns) * 2) - $global-gutter) - $app-promo-column-right-margin-md;
			}

			@include media-breakpoint-up(xl) {
				margin-left: ((($container-xlarge-max-width + $global-gutter) / $grid-columns) - $global-gutter) / 2; // Half column width
			}
		}
	}

	.footer-full-site-nav-column {
		@extend .uk-visible\@s;
	}
}

.app-promo-column {
	@include media-breakpoint-up(lg) {
		.uk-container {
			max-width: none;

			.uk-grid {
				margin: 0;

				& > * {
					padding-left: 0;
				}
			}
		}
	}

	.app-promo-copy {
		position: relative;
		z-index: 2;

		@include media-breakpoint-only(lg) {
			@include make-x-cols-wide(3, map_get($container-max-widths, lg));

			margin-right: $app-promo-column-right-margin-md;

			.app-download-btn {
				&:first-child {
					margin-left: -27px;
				}

				&:last-child {
					margin-right: -27px;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			margin-left: auto;

			.app-download-btn {
				margin-top: 15px;
			}
		}

		@include media-breakpoint-up(xl) {
			@include make-x-cols-wide(3, map_get($container-max-widths, xl));

			margin-right: ((($container-xlarge-max-width + $global-gutter) / $grid-columns) * 1.5) + ($global-gutter / 2); // 1.5 column width right offset

			.app-download-btn {
				margin-top: 0;
			}
		}

		@include media-breakpoint-down(xs) {
			padding-bottom: 90px;
		}
	}

	.app-promo-image {
		z-index: 1;

		@include media-breakpoint-up(lg) {
			margin-bottom: 0;

			& > img {
				margin-left: 0;
				width: auto;
			}
		}

		@include media-breakpoint-only(lg) {
			@include make-x-cols-wide(3, map_get($container-max-widths, lg));

			text-align: center;
			margin-top: -90px;
			margin-right: $app-promo-column-right-margin-md;
			margin-left: auto;
			height: 0;

			& > img {
				width: 170px;
			}
		}

		@include media-breakpoint-up(xl) {
			position: absolute;
			right: -32px;
			bottom: -38px;
			margin-top: 0;

			& > img {
				width: auto;
			}
		}
	}
}

.app-promo-copy {
	@include media-breakpoint-up(lg) {
		padding-top: 40px;
		padding-bottom: 0;
	}

	@include media-breakpoint-up(xl) {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	h2 + p {
		@extend .uk-visible\@l;
	}

	p {
		font-weight: $font-weight-bold;
		line-height: 1.5;
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.footer-full-site-nav {
	$footer-top-level-nav-item-bottom-margin: 25px;

	@extend .uk-child-width-1-3\@m;
	@extend .uk-child-width-auto\@xl;
	@extend .uk-flex-between;

	padding: 60px 0;

	@include media-breakpoint-up(md) {
		padding-bottom: 50px;
	}

	@include media-breakpoint-down(md) {
		& > * {
			width: percentage(3 / 8);

			&:last-child {
				width: percentage(2 / 8);
			}
		}
	}

	@include media-breakpoint-up(lg) {
		margin-left: ($global-gutter + (($container-large-max-width + $global-gutter) / $grid-columns)) * -1;
		// 6 column fixed width plus the global gutter
		width: (((($container-large-max-width + $global-gutter) / $grid-columns) * 6));
	}

	@include media-breakpoint-up(xl) {
		margin-left: ($global-large-gutter * -1);
		// 5 column fixed width plus the large gutter width from .uk-grid-large
		width: (((($container-xlarge-max-width + $global-gutter) / $grid-columns) * 5) - $global-gutter) + $global-large-gutter;

		& > * {
			padding-left: $global-large-gutter;
		}

		.nav-column:last-child {
			width: 30%;
		}
	}

	.uk-nav > li > a,
	.uk-nav-header {
		color: $gray-300;
		font-weight: $font-weight-bold;
		line-height: $base-h5-line-height;
		padding: 0;
	}

	.uk-nav {
		margin-bottom: ($footer-top-level-nav-item-bottom-margin * -1);

		& > li {
			margin-bottom: $footer-top-level-nav-item-bottom-margin;

			& > a {
				text-transform: uppercase;

				&:hover {
					color: $brand-blue;
				}
			}
		}
	}

	.uk-nav-header {
		white-space: nowrap;
		display: block;
	}

	.uk-nav-sub {
		line-height: $base-h5-line-height;
		margin-top: 8px;
		padding: 0;

		& > li {

			& + li {
				margin-top: 8px;
			}

			& > a {
				color: $gray-500;
				text-transform: lowercase;
				line-height: 1.0625; // ~17px
				padding: 5px 0 4px 0;

				&:hover {
					color: $gray-400;
				}
			}
		}
	}
}

.footer-minor-wrap {
	background-color: $gray-300;
	color: $global-inverse-color;
	padding: 15px 0;

	.social-account-icons {
		// font-size: 1rem;
		// line-height: 1.15;

		.uk-iconnav {
			@extend .uk-flex-center;
			@extend .uk-flex-right\@l;

			margin-right: ($iconnav-padding-horizontal * -1);
		}


		img {
			filter: brightness(200%);
			height: 20px;
		}
	}

	@include media-breakpoint-only(lg) {
		& > .uk-container > .uk-grid {
			justify-content: center;
		}

		.uk-grid-margin {
			margin-top: 20px;
		}

		// .footer-copyright-secondary-nav {
		// 	justify-content: center;
		// 	width: 100%;
		// }
	}

	@include media-breakpoint-up(lg) {

	}

	@include media-breakpoint-up(lg) {
		.site-by-reshift,
		.social-account-icons {
			width: auto;
		}

		.footer-copyright-secondary-nav {
			justify-content: center;
			width: percentage(8 / $grid-columns);
			display: flex;
			flex-direction: column-reverse;
			align-items: flex-start;
		
			.uk-nav {
				> li {
					margin-right: 35px;
					margin-left: 0;
					margin-bottom: 15px;
				}
			}
		}

		.site-by-social-container {
			display: flex;
			flex-direction: column-reverse;
			width: percentage(2 / $grid-columns);
			margin: 5px 0 0;
			height: 100%;
			align-items: flex-end;

			.social-account-icons {
				margin-bottom: 15px;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		// .footer-copyright-secondary-nav {
		// 	width: percentage(8 / $grid-columns);
		// }

		.site-by-reshift {
			margin-left: auto;
		}
	}
}

.content-wrap.content-404 {
	padding-top: 60px;
	padding-bottom: 0;

	.image-copy-content-section {
		.image-column,
		.copy-column {
			@extend .uk-width-1-2;
		}

		.image-column {
			margin-top: 0;
			padding-right: 0;

			& > img {
				float: none;
			}
		}

		.copy-column {
			margin-top: 80px;

			& > h3:first-child {
				color: $brand-orange;
			}
		}
	}
}
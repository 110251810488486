
@mixin hook-pagination-misc {
	.numbered-pagination {
		$numbered-pagination-font-size: 1rem;
		$numbered-pagination-font-size-sm: 1.25rem;

		margin-right: -5px;
		margin-left: -20px;

		& > * {
			font-size: $numbered-pagination-font-size;
			font-weight: $font-weight-medium;
			line-height: 1;
			padding-left: 15px;

			&.uk-pagination-previous,
			&.uk-pagination-next {
				font-size: 1.25rem;

				span {
					display: block;
					line-height: ((($global-font-size / 1rem) * ($numbered-pagination-font-size / 1rem)) * 16px);
				}
			}

			& > * {
				padding: 3px 5px 4px 5px;
			}

			& > span {
				margin-right: 5px;
				margin-left: 5px;
				padding-right: 0;
				padding-left: 0;
			}
		}

		& > .uk-active {
			& > * {
				border-bottom: 4px solid $brand-orange;
				padding-bottom: 6px;
			}
		}

		@include media-breakpoint-up(sm) {
			& > * {
				font-size: $numbered-pagination-font-size-sm;

				&.uk-pagination-previous,
				&.uk-pagination-next {
					font-size: 1.75rem;

					span {
						line-height: ((($global-font-size / 1rem) * ($numbered-pagination-font-size-sm / 1rem)) * 16px);
					}
				}

			}

			& > .uk-active > * {
				padding-bottom: 9px;
			}
		}
	}
}
.content-dogtopia-at-work {

    #dogtopiaCampus li:before {
        content: "";
        background-color: #ffcc42;
        position: absolute;
        top: 11.5px;
        left: 0;
        width: 9px;
        height: 9px;
    }

    #dogtopiaCampus li {
        position: relative;
        padding-left: 24px;
    }


    #dogtopiaCampus {
        list-style: none;
    }
    
    .convContent {
        float: left;
        width: 50%;

    }

    .convGallery {
        margin: 0 auto;

    }

    .convGallery .caption-column {
        display: none !important;
    }

    #field_7_8 .gfield_required {
       display: none !important;

    }

    #field_10_8 .gfield_required {
        display: none !important;
    }

    .convContentImg { 
        padding: 10px;
        position: relative;
        left: 85px;
    }

    #contactLeft {
        float: left; 
        width: 50%;
        padding-right: 20px;
    }

    #contactRight {
        float: right;
        width: 50%;
        margin-top: 100px;
    }


    .uk-button-primary {
        z-index: 1;

    }

    #atWork_expectmore_left {
        float: left; 
        width: 60%; 
        padding-right: 10px;

    }

    #atWork_expectmore_right {
        float: right; 
        width: 40%;
        text-align: center;

    }

    .convContentImg img {
        position: absolute; 
        width: 472px !important;

    }

    .stat-description {
        font-size: 26px;
        line-height: 32px;
       
    }

    .stat-card .stat-icon {
        width: 90px;
        height: 78px;
    }

    .uk-container-at-work {
        max-width: 955px;
    }

    #team_trust_copy {
        margin-top: 70px;
    }

    .content-footer-promo-block  {
        max-width: 915px;

    }

    #campus-get-in-touch-button {
        margin-top: 30px; 
        margin-left: 100px;
    }

    @media only screen and (max-width: 992px) { 

        .convContentImg img {
            position: relative;
            width: 472px!important;
        }

        .convContentImg {
            width: 100%;
        }

        .uk-container-at-work {
            max-width: 625px !important;

        }

        #contactLeft {
            width: 50%;
            margin: 0 auto;

        }
        
        #contactRight {
            float: unset; 
            width: 50%;
            margin-top: 100px;
            clear: both;
        }

    }

    @media only screen and (max-width: 669px) {

        .convContentImg {
            margin: 0 auto;
            left: unset;
            text-align: center;

        }

        .uk-button-primary {
            display: block !important;            
            margin: 0px;
            display: relative;
            padding-top: 13px; 
            margin-top: 30px;
            margin-left: 0px !important;
            width: 165px;
            top: 18px;
        }

        .image-button-cta {
            margin-top: -30px !important;

        }

        .topLogo {
            width: 90px !important;
            display: inline-table;

        }

        .topLogo img {
            width: 100% !important;

        }

        .at-work-store-locator-call-to-action {
            margin-left: 552px;
            margin-top: 5px;
        }

        #at-work-convienience, #healthy-dogs, #largest-provider, #campus-label {
            font-size: 30px !important;             
        }

        .stat-info {
            font-size: 50px !important;       
        }

        .stat-cards-wrap>:not([class*=uk-width]), .uk-child-width-expand>:not([class*=uk-width]) {
            -ms-flex: 1;
            flex: 1;
            min-width: 230px;
            margin: 25px;
        }

    }

    @media only screen and (width: 1366px) {
        .convContentImg img {
            right: 0px;

        }

    }

    @media only screen and (width: 1024px) {
        .header-container {
            width: 750px !important;
            backface-visibility: hidden;
        }
    }

    @media only screen and (max-width: 992px) {

        .promo-cta img {
            display: none;

        }

        .convContent {
            float: unset !important; 
            width: unset !important; 
            
        }
        
        .convContentImg {
            float: unset !important; 
            width: unset !important; 
            
        }

        .convContentImg img {
            position: unset; 
            width: unset;
        
        }

        .convGallery {
            margin-top: 40px; 
            margin-bottom: 50px;

        }

        #contactLeft {
            float: unset !important; 
            width: unset !important;
        }

        #contactRight {
            float: unset !important; 
            width: unset !important;
            margin-top: 0px !important;
            
        }

        #atWork_expectmore_left {
            float: unset; 
            width: 100%; 
            padding-right: unset;
        
        }
        
        #atWork_expectmore_right {
            float: unset; 
            width: 100%;
            margin-top: 50px;
        
        }


        .topLogo {
            padding: 55px;
            margin: 0 auto;
        }

        
        /*.franchisee-testimonials-column {
            display: none !important;

        }*/

        .stat-icon, .stat-container {
            display: none !important;

        }

        #gform_confirmation_message_10 {
            margin-bottom: 50px !important;

        }

        .content-footer-promo-block.franchise-promo-block {
            margin-right: 50px;
            padding: 0 0px 45px 0!important;
            margin-bottom: 0;
        }

        /*#at-work-convienience {
            line-height: 0px;
        }*/

        .topLogo {
            margin: 5px !important;
            padding: 5px !important;
        }

        .image-button-cta {
           display: block !important;

        }

        #team_trust_copy {
            margin-top: 40px;
        }

    }

    .page-dogtopia-at-work {
        max-width: 100vw;
        margin-right: -10px;

    }
    
    #at-work-convienience {
        font-size: 40px; 
        color: #ff8200; 
        font-weight: 900; 
    }

    #healthy-dogs, #largest-provider, #campus-label {
        font-size: 40px;
    }

    #largest-provider {
        line-height: 30px;
        text-transform: uppercase;
        font-weight: 900;
    }

    #campus-label {
        color: #5b6770;
        line-height: 1.25;
    }

    #franchise_expectmore {
        float: left; 
        width: 60%; 
        padding-right: 10px;

    }
    #franchise_engaged {
        float: left;
        width: 60%; 
        padding-right: 10px;
    }

    .stat-info {
        font-size: 50px;       
    }

    .stat-container {
        min-height: 110px;
    }
    

    .image-slideshow-column {
        max-width: 690px!important;
        margin: 0 auto;

    }

    .image-slideshow-column {
        max-width: 690px !important;

    }

    .testimonialSlides {
        min-height: 130px!important;
        margin-bottom: -40px;
    }


    @media (min-width: 1230px) {
        .stat-cards-wrap>* {
            padding-left: 70px;
        }
    }

    .content-footer-promo-block.franchise-promo-block {
        margin-right: 50px;
        padding: 0 221px 20px 0;
    }

    .gform_confirmation_wrapper {
        background-image: url(../images/scruffy-illustrated.svg);
        background-repeat: no-repeat;
        background-position: bottom left 40px;
        margin: 40px 0 50px;
        padding-bottom: 200px;
        text-align: center;
        margin: 0 auto;
        background-position: bottom;
    }


    
}


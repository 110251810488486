
.content-careers {
	.job-post-list-block,
	.resume-upload-form-block {
		margin-top: 50px;
	}

	.gform_wrapper {
		.gfield_fileupload {
			background-color: $gray-800;
			margin-right: -20px;
			margin-left: -20px;
			padding: 20px;

			& > label {
				line-height: normal;
				display: inline-block;
				position: static;
				margin-right: 10px;
				margin-bottom: 10px;
			}

			.ginput_container_fileupload {
				display: inline-block;
				width: 80%;
			}

			.gfield_description {
				font-size: 1rem;
				font-style: italic;
			}
		}

		.ginput_container_fileupload {
			input {
				font-family: inherit;
				font-size: 1rem;
				line-height: 1;
				width: 100%;
			}
		}

		.gfield_checkbox {
			.gfield_label {
				display: none;
			}
		}
	}

	.gform_confirmation_wrapper {
		background-position: bottom center;
		padding-bottom: 210px;

		h3 {
			margin-bottom: 7px;
		}
	}
}

.content-job-post-list {
	.image-row-column {
		@extend .uk-width-4-5\@sm;

		& > .uk-grid {
			@extend .uk-child-width-1-2\@sm;
			@extend .uk-child-width-1-4\@md;
		}
	}

	.body-copy-content-wrap .body-copy-content-column {
		@extend .uk-width-4-5\@l;
	}

	.body-copy-content-column {
		h2 {
			margin-bottom: 10px;
		}
	}

	.job-post-list-block {
		.job-post-list-item-card + .job-post-list-item-card {
			margin-top: 20px;
		}
	}

	.resume-upload-form-block {
		& > .uk-grid {
			@extend .uk-flex-center;
		}

		.form-column {
			@extend .uk-width-4-5\@ms;
			@extend .uk-width-1-2\@xl;

			margin-top: -10px;
		}
	}

	@include media-breakpoint-down(ms) {
		.image-row-column {
			& > .uk-grid {
				justify-content: space-around;

				& > * {
					text-align: center;
				}
			}
		}

		.body-copy-content-wrap .body-copy-content-column {
			margin-left: 0;
			width: 100%;
		}
	}

	@media (min-width: 350px) {
		.body-copy-content-wrap {
			background-position: top 160px right -100px;
		}
	}

	@media (max-width: 551px) {
		
		.row-images-image {
			display: none;
		}

		.job-post-list-block {
			margin-top: 20px;
		}

	}

	@include media-breakpoint-up(sm) {
		.body-copy-content-wrap {
			background-position: top 60px right -100px;
		}

		.image-row-column {
			@include make-uk-width-offset(1);
		}
	}

	@include media-breakpoint-up(md) {
		.body-copy-content-wrap {
			background-position: top 60px right;
		}
	}

	@include media-breakpoint-between(md, lg) {
		.image-row-column {
			margin-left: 0;
			width: 100%;
		}
	}

	@include media-breakpoint-only(lg) {
		.resume-upload-form-block {
			.form-column {
				width: percentage(7 / $grid-columns);
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.body-copy-content-wrap .body-copy-content-column {
			@include make-uk-width-offset(1);
		}
	}

	@include media-breakpoint-up(xl) {
		.body-copy-content-wrap {
			background-image: url("../images/triangle-bg-right-blue.png");
			background-repeat: no-repeat;
			background-position: top 160px right -160px;
		}
	}
}

.content-single-job-posting {
	padding-top: 40px + $box-shadow-spacing;

	.single-post-content-column {
		.back-link {
			margin-left: 0;
		}
	}

	.post {
		box-shadow: none;
		padding: 0;
	}

	.gform_wrapper,
	.gform_confirmation_wrapper,
	.shortcode-form-legal-text-column {
		margin: 0 auto;
		max-width: ((($container-max-width + $global-gutter) / $grid-columns) * 4) - $global-gutter;
	}

	.shortcode-form-legal-text-column {
		margin-top: 20px;
	}

	@include media-breakpoint-up(sm) {
		.single-post-content-column {
			@include make-uk-width-offset(1);

			width: percentage(8 / $grid-columns);
		}
	}
}
.post {
  .post-tags {
    font-size: $global-small-font-size;
    line-height: 2;
    margin-top: 40px;

    a {
      font-weight: $font-weight-bold;
    }
  }
}

.content-single {
  .single-post-content-column {
    box-shadow: none;
  }

  .post-pagination-wrap {
    position: relative;
    z-index: 1;
  }

  @include media-breakpoint-up(sm) {
    .post-pagination-wrap {
      margin-top: -15px;

      .pagination-item {
        & > a:hover {
          padding-top: 15px;
          margin-bottom: -15px;
        }
      }
    }
  }
}

.content-single.content-blog {
  .single-post-content-column {
    .back-link {
      margin-left: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    .post {
      padding-top: $article-card-padding-lg;
    }
  }

  // Blog Widget
  .blog-widget {
    width: 100%;
    margin: 0 auto 40px;
    padding: 90px 0 0;
    @media all and (min-width: $breakpoint-xsmall) {
      width: 325px;
    }
    @media all and (min-width: $breakpoint-medium) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 355px !important;
      margin: 40px 0 40px 40px;
      float: right;
      width: 50%;
    }

    .blog-widget-wrapper {
      border: 4px solid $brand-yellow;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 10px 16px;
      @media all and (min-width: $breakpoint-xsmall) {
        padding: 0 35px 16px;
      }

      p,
      span,
      a {
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
      }

      br {
        display: none;
      }

      img {
        margin-top: -85px;
        margin-bottom: 41px;
        @media all and (min-width: $breakpoint-medium) {
          width: 275px;
          height: 180px;
          margin-top: -86px;
        }
      }

      .industry-title {
        color: $gray-400;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.39;
        margin-bottom: 20px;
        @media all and (min-width: $breakpoint-medium) {
          font-size: 36px;
        }

        em {
          color: $brand-yellow;
          font-family: $sign-painter;
          font-size: 45px;
          font-weight: normal;
					font-style: normal;
          line-height: normal;
          @media all and (min-width: $breakpoint-medium) {
            font-size: 55px;
          }
        }
      }

      .industry-desc {
        color: $gray-400;
        font-size: 20px;
        font-weight: normal;
        line-height: normal;
        margin: 0 0 35px;
      }
    }

    .btn-container {
      background: $global-inverse-color;
      margin: 0 auto;
      width: fit-content;
      display: flex;
      justify-content: center;
      margin-top: -20px;

      a {
        color: $global-inverse-color;
        background: #00acc6;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.6;
        padding: 7px 22px;
        width: fit-content;
        display: block;
        margin: 0 38px;
        display: flex;
        align-items: center;
        &:active,
        &:focus,
        &:hover {
          text-decoration: none;
          @if (mixin-exists(hook-button-hover)) {
            @include hook-button-hover();
          }
        }
      }

      i {
        margin-left: 5px;
      }
    }
  }
  // Blog Widget end
}

.content-single-faq {
  .post {
    box-shadow: $global-small-box-shadow;

    &.has-post-thumbnail {
      .entry-content {
        min-height: 330px;

        .post-featured-image {
          margin-bottom: 60px;
        }
      }
    }

    header .post-sharing-icons {
      margin-bottom: 25px;
    }

    .entry-content {
      min-height: 0;
    }
  }

  .post-pagination-wrap {
    //z-index: -1;
  }

  @include media-breakpoint-up(lg) {
    .post {
      padding-top: 40px;
    }
  }
}

.best-life-banner {
  background-image: url("../images/join-industry-pattern.png");
  padding: 51px 20px 66px;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  @media all and (min-width: $breakpoint-large) {
    flex-direction: row;
    justify-content: center;
    gap: 0;
  }

  h3,
  p,
  a {
    color: $global-inverse-color;
    font-family: $font-family-brandon-grotesque;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    @media all and (min-width: $breakpoint-medium) {
      text-align: unset;
    }
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media all and (min-width: $breakpoint-large) {
      align-items: unset;
    }
  }

  h3 {
    font-size: 50px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: normal;
    text-transform: unset;

    i, em {
      color: $brand-yellow;
      font-family: $sign-painter;
      font-size: 65px;
			font-style: normal;
      font-weight: normal;
      line-height: normal;
    }
  }

  p {
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  a {
    background: $button-default-color;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: normal;
    padding: 10px 22px;
    width: fit-content;
    &:active,
    &:focus,
    &:hover {
      text-decoration: none;

      @if (mixin-exists(hook-button-hover)) {
        @include hook-button-hover();
      }
    }

    i {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      margin-left: 5px;
    }
  }

  .info-container {
    @media all and (min-width: $breakpoint-large) {
      min-width: 640px;
      padding-left: 50px;
    }
    @media all and (min-width: $breakpoint-xlarge) {
      min-width: 600px;
      padding-left: 100px;
    }

    .info-wrapper {
      @media all and (min-width: $breakpoint-large) {
        width: 396px;
      }

      .description {
        margin-bottom: 45px;
      }
    }
  }
}

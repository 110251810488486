
@mixin hook-visibility-misc {
	@include media-breakpoint-up(xs) {
		.uk-hidden\@xs {
			display: none;
		}
	}

	@include media-breakpoint-up(ms) {
		.uk-hidden\@ms {
			display: none;
		}
	}

	@include media-breakpoint-down(ms) {
		.uk-visible\@m {
			display: none
		}
	}

	@include media-breakpoint-down(sm) {
		.uk-visible\@ms {
			display: none;
		}
	}

	@include media-breakpoint-down(xxs) {
		.uk-visible\@xs {
			display: none;
		}
	}
}
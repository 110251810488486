
@mixin hook-modal-header {
	color: $global-inverse-color;

	p {
		font-weight: $font-weight-medium;
	}

	.uk-modal-title {
		color: inherit;
	}
}
@mixin hook-modal-body {
	padding-top: $modal-body-padding-vertical - 10;
}

@mixin hook-modal-close {
	font-size: 30px;
	line-height: 1;

	@at-root button#{&} {
		border: none;
		background-color: transparent;
		color: $global-inverse-color;
		transition: all 0.2s ease;

		& > * {
			display: none;
		}

		&:after {
			content: fa-content($fa-var-times);
			font-weight: $font-awesome-regular-weight;
			vertical-align: baseline;
			display: block;
			transition: text-shadow 0.2s ease;
			position: static;
			width: 1em;
		}
	}
}

@mixin hook-grid-misc {
	@include media-breakpoint-down(ms) {
		.uk-grid {
			margin-left: ($global-small-gutter * -1);

			& > * {
				padding-left: $global-small-gutter;
			}
		}
	}
}

@function breakpoint_next($name, $breakpointsList: $breakpoints, $breakpointNames: map_keys($breakpointsList)){

	$i: index($breakpointNames, $name);

	@return if(($i < length($breakpointNames)), nth($breakpointNames, ($i + 1)), null);

}

@function breakpoint_min($name, $breakpointsList: $breakpoints){

	$minBreakpoint: map_get($breakpointsList, $name);

	@return if(($minBreakpoint != 0), $minBreakpoint, null);

}

// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
@function breakpoint_max($name, $breakpointsList: $breakpoints){

	$nextBreakpoint: breakpoint_next($name, $breakpointsList);

	@return if(($nextBreakpoint and (breakpoint_min($nextBreakpoint, $breakpoints) != null)), (breakpoint_min($nextBreakpoint, $breakpoints) - 0.02px), null);

}

@mixin media-breakpoint-up($name, $breakpointsList: $breakpoints) {

	$minBreakpoint: breakpoint_min($name, $breakpointsList);

	@if($minBreakpoint){

		@media (min-width: $minBreakpoint) {
			@content;
		}

	}@else{

		@content;

	}

}

@mixin media-breakpoint-down($name, $breakpointsList: $breakpoints) {

	$maxBreakpoint: breakpoint_max($name, $breakpointsList);

	@if($maxBreakpoint){

		@media (max-width: $maxBreakpoint) {
			@content;
		}

	}@else{

		@content;

	}

}

@mixin media-breakpoint-between($lowerName, $upperName, $breakpointsList: $breakpoints) {

	$minBreakpoint: breakpoint_min($lowerName, $breakpointsList);
	$maxBreakpoint: breakpoint_max($upperName, $breakpointsList);

	@if(($minBreakpoint != null) and ($maxBreakpoint != null)){

		@media (min-width: $minBreakpoint) and (max-width: $maxBreakpoint) {
			@content;
		}

	}@else if($maxBreakpoint == null){

		@include media-breakpoint-up($lowerName, $breakpointsList) {
			@content;
		}

	}@else if($minBreakpoint == null){

		@include media-breakpoint-down($upperName, $breakpointsList) {
			@content;
		}

	}

}

@mixin media-breakpoint-only($name, $breakpointsList: $breakpoints) {

	$minBreakpoint: breakpoint_min($name, $breakpointsList);
	$maxBreakpoint: breakpoint_max($name, $breakpointsList);

	@if(($minBreakpoint != null) and ($maxBreakpoint != null)){

		@media (min-width: $minBreakpoint) and (max-width: $maxBreakpoint) {
			@content;
		}

	}@else if($maxBreakpoint == null){

		@include media-breakpoint-up($name, $breakpointsList) {
			@content;
		}

	}@else if($minBreakpoint == null){

		@include media-breakpoint-down($name, $breakpointsList) {
			@content;
		}

	}

}

body:before {
	display: none;

	@each $breakpointName, $breakpointMin in $breakpoints {

		@if($breakpointMin == 0){

			content: "#{$breakpointName}";

		}@else{

			@include media-breakpoint-up($breakpointName) {
				content: "#{$breakpointName}";
			}

		}

	}
}

$home-page-promo-slideshow-border-width: 4px;
$home-page-promo-slideshow-border-v-offset: 25px;
$home-page-promo-slideshow-border-h-offset: 25px;
$home-page-promo-slideshow-border-v-offset-sm: 35px;
$home-page-promo-slideshow-border-h-offset-sm: 35px;
$home-page-promo-slideshow-border-v-offset-md: 40px;
$home-page-promo-slideshow-border-h-offset-md: 40px;

.slide-controls-wrap {
	@extend .uk-flex;

	a {
		background-color: $gray-800;
		color: inherit;
		font-size: 1.75rem;
		line-height: 1;
		padding: 7px 20px;

		&:hover {
			color: $gray-300;
			background-color: $gray-700;
		}
	}
}

.image-slideshow {
	&:not(.home-page-promo-slideshow){
		.uk-slideshow-items > li > img {
			width: auto !important;
			height: 100% !important;
		}
	}

	.uk-slideshow-items {
		& > li {
			.slide-copy-holder {
				display: none;
			}
		}
	}

	.slideshow-status-control-bar {
		@extend .uk-flex;
		@extend .uk-flex-right;

		position: relative;
		z-index: 1;

		& > * {
			background-color: #FFFFFF;
		}
	}

	.slideshow-current-slide-status {
		font-weight: $font-weight-medium;
		text-align: center;
		padding: 9px 20px;
		min-width: 3.5em;

		& > * {
			vertical-align: text-top;
		}
	}
}

.home-page-promo-slideshow {
	.uk-slideshow-items {
		overflow: visible;
		box-shadow: 0 1px 6px 2px rgba(0,0,0,0.3);

		&:before {
			content: "";
			border: $home-page-promo-slideshow-border-width solid $brand-yellow;
			position: absolute;
			top: $home-page-promo-slideshow-border-v-offset;
			right: $home-page-promo-slideshow-border-h-offset;
			bottom: ($home-page-promo-slideshow-border-v-offset * -1);
			left: ($home-page-promo-slideshow-border-h-offset * -1);
			z-index: -1;

			@include media-breakpoint-up(sm) {
				top: $home-page-promo-slideshow-border-v-offset-sm;
				right: $home-page-promo-slideshow-border-h-offset-sm;
				bottom: ($home-page-promo-slideshow-border-v-offset-sm * -1);
				left: ($home-page-promo-slideshow-border-h-offset-sm * -1);
			}

			@include media-breakpoint-up(md) {
				top: $home-page-promo-slideshow-border-v-offset-md;
				right: $home-page-promo-slideshow-border-h-offset-md;
				bottom: ($home-page-promo-slideshow-border-v-offset-md * -1);
				left: ($home-page-promo-slideshow-border-h-offset-md * -1);
			}
		}
	}

	.slideshow-current-slide-status {
		margin-top: 9px;
		padding-top: 0;
	}
}

.services-promo-slideshow {
	.uk-slideshow-items {
		border: 4px solid $brand-yellow;
	}

	.service-promo-slide {
		@extend .uk-flex;
		@extend .uk-flex-middle;

		background-color: $global-background;

		.service-promo-image {
			@extend .uk-visible\@xs;

			img {
				@extend .uk-preserve-width;
			}
		}

		.service-promo-copy {
			position: relative;
			padding: 30px 33px;

			h4 {
				color: $brand-blue;
				line-height: $global-line-height;
				margin-bottom: -7px;
			}

			h5 {
				font-size: $base-h4-font-size;
				font-weight: normal;
				font-style: italic;
				text-transform: none;
				line-height: 1;
				margin-top: 5px;
				margin-bottom: $base-margin-vertical + 4px;
			}

			p {
				font-size: $global-small-font-size;
				line-height: normal;
			}

			.service-icon {
				position: absolute;
				top: 30px;
				left: -26px;
				width: 52px;
			}
		}

		@media (max-width: 489px) {
			.service-promo-image {
				img {
					width: 170px;
				}
			}

			.service-promo-copy {
				padding-top: 20px;

				.service-icon {
					top: 20px;
				}
			}
		}

		@media (max-width: 469px) {
			.service-promo-image {
				img {
					width: 160px;
				}
			}

			.service-promo-copy {
				padding-top: 15px;

				.service-icon {
					top: 15px;
				}
			}
		}

		@include media-breakpoint-down(xxs){
			.service-promo-copy {
				margin-left: 59px;
			}
		}

		@media (min-width: $breakpoint-xsmall) and (max-width: 499px) {
			align-items: flex-start;
		}

		@include media-breakpoint-only(lg) {
			.service-promo-image {
				img {
					max-width: 100%;
				}
			}

			.service-promo-copy {
				width: percentage(2 / 3);
			}
		}
	}
}

.page-home {
	.top-banner-video-holder {
		min-height: 595px;
	}
}

.content-home {
	padding: 35px 0 60px 0;

	@include media-breakpoint-up(sm) {
		padding-top: 60px;
	}

	@include media-breakpoint-between(sm, ms) {
		padding-bottom: 0;
	}

	@include media-breakpoint-up(md) {
		padding-bottom: 60px;
	}

	h1 {
		@extend .uk-h2;
	}

	h1,
	h2 {
		margin-bottom: 12px;
	}

	.image-copy-content-section .image-column {
		$slogan-overlay-offset: 165px;

		padding-left: 5px;

		@include media-breakpoint-up(sm) {
			padding-left: $global-small-gutter;
		}

		@include media-breakpoint-up(md) {
			padding-left: $global-gutter;
		}

		@include media-breakpoint-down(md) {
			width: auto;
		}

		@include media-breakpoint-down(sm) {
			overflow: hidden;
			margin-right: ($global-small-gutter * -1);
		}

		@include media-breakpoint-down(xs){
			padding-right: 0;
		}

		& > img {
			margin-left: 10px;

			@media (min-width: 400px) {
				margin-left: 30px;
			}

			@include media-breakpoint-up(xs) {
				margin-left: 60px;
			}

			@include media-breakpoint-up(sm) {
				margin-left: 110px;
			}

			@include media-breakpoint-up(ms) {
				margin-left: $slogan-overlay-offset;
			}

			@include media-breakpoint-up(lg) {
				margin-left: 0;
			}

			@include media-breakpoint-down(xs){
				max-width: 450px;
			}
		}

		.endless-love-slogan-overlay {
			margin-top: -25px;
			padding-right: 5px;

			@include media-breakpoint-up(xs) {
				margin-top: -35px;
			}

			@include media-breakpoint-up(sm) {
				margin-top: -45px;
				padding-right: 0;
				width: 80%;
			}

			@include media-breakpoint-up(ms) {
				width: 600px;
			}

			@include media-breakpoint-up(lg) {
				margin-top: -30px;
				margin-left: (($slogan-overlay-offset - 110) * -1);
				width: 500px;
			}

			@include media-breakpoint-up(xl) {
				margin-left: ($slogan-overlay-offset * -1);
				width: 640px;
			}
		}
	}

	.uk-grid + .uk-grid,
	.service-cards-wrap,
	.stats-testimonials-section-wrap {
		margin-top: 70px;

		@include media-breakpoint-up(xl) {
			margin-top: 80px;
		}
	}

	.uk-grid.home-page-promo-slideshow-content-wrap {
		@include media-breakpoint-down(lg) {
			margin-top: 40px;
		}

		@include media-breakpoint-down(md) {
			margin-top: 50px;
		}
	}

	.service-cards-wrap {
		@media (max-width: ($service-card-breakpoint-small - 0.02px)){
			margin-top: 105px;
		}

		.service-card {
			.uk-card-body {
				transition: box-shadow 0.2s ease;

				&:hover {
					box-shadow: $global-large-box-shadow;
				}
			}
		}

		.boarding-service-card {
			@include media-breakpoint-up(lg) {
				margin-top: -130px;
			}

			@include media-breakpoint-up(xl) {
				margin-top: -100px;
			}
		}

		.spa-service-card {
			@include media-breakpoint-up(lg) {
				margin-top: 50px;
			}

			@include media-breakpoint-up(xl) {
				margin-top: 80px;
			}
		}
	}

	.uk-grid.blog-promo-wrap {
		margin-top: 60px;

		@include media-breakpoint-up(md) {
			margin-top: 50px;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 65px;
		}
	}

	.uk-grid.blog-posts-grid-row {
		@include media-breakpoint-down(ms) {
			margin-top: 50px;
		}

		@include media-breakpoint-down(xs) {
			margin-top: 30px;
		}
	}
}

.stats-testimonials-section-wrap {
	background-color: #4D565D;
	background-image: url("../images/stats-testimonials-bg.jpg");
	background-position: center top;
	background-repeat: no-repeat;
	color: $global-inverse-color;
	padding: 20px 0 70px 0;

	@include media-breakpoint-up(xs) {
		padding-top: 30px;
	}

	@include media-breakpoint-up(md) {
		padding-top: 45px
	}

	@include media-breakpoint-down(xxs) {
		.uk-container {
			padding: 0 ($global-small-gutter - 5px);
		}

		.uk-grid {
			margin-left: ($global-small-gutter - 5px) * -1;

			& > * {
				padding-left: ($global-small-gutter - 5px);
			}
		}
	}

	h2 {
		@extend .uk-h1;

		color: inherit;
		text-align: center;
		line-height: 1.35;
		margin-bottom: 30px;

		@include media-breakpoint-up(md) {
			margin: 0 auto 40px auto;
			width: 80%;
		}
	}
}

.stat-cards-wrap {
	@extend .uk-child-width-expand;

	@include media-breakpoint-up(xl) {
		margin-left: ($grid-large-gutter-horizontal * -1);

		& > * {
			padding-left: $grid-large-gutter-horizontal;
		}
	}

	@include media-breakpoint-down(xxs) {
		& > :last-child {
			flex-grow: 1.2;
		}
	}

	@media (max-width: 399px) {
		& > .stat-card {
			flex-basis: auto;
			width: 100%;

			&.uk-grid-margin {
				margin-top: 40px;
			}
		}
	}
}

.testimonial-slides-wrap {
	@extend .uk-flex;
	@extend .uk-flex-middle;

	margin-left: ($global-small-gutter * -1);
	height: 440px;

	@include media-breakpoint-up(sm) {
		height: 400px;
	}

	@include media-breakpoint-up(ms) {
		height: 370px;
	}

	@include media-breakpoint-up(md) {
		margin-left: ($global-gutter * -1);
	}

	@include media-breakpoint-up(xl) {
		height: 390px;
	}

	@media (max-width: 369px) {
		height: 460px;
	}

	@media (max-width: 339px) {
		height: 480px;
	}

	@media (max-width: 329px) {
		height: 500px;
	}
}

.testimonial-slides {
	line-height: normal;
	list-style: none;
	margin: 0;
	padding: 0;

	@include media-breakpoint-up(xl) {
		font-size: 1.25rem;
		line-height: 1.3;
	}

	& > li {
		@extend .uk-width-4-5\@xs;
		@extend .uk-width-3-5\@xl;

		text-align: center;
		position: relative;
		margin: 0 auto;
		padding-left: $global-small-gutter;

		@include media-breakpoint-up(md) {
			padding-left: $global-gutter;
		}

		&:before {
			@include media-breakpoint-up(xs) {
				content: "";
				background-image: url("../images/testimonial-quote-mark-icon.svg");
				background-repeat: no-repeat;
				position: absolute;
				top: 0;
				left: -41px;
				width: 34px;
				height: 31px;
			}

			@include media-breakpoint-between(xs, ms) {
				background-size: 34px 31px;
			}

			@include media-breakpoint-up(md) {
				left: -34px;
				width: 53px;
				height: 48px;
			}
		}
	}

	p {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			font-weight: $font-weight-bold;
		}
	}

	.quote {
		font-style: italic;
		font-weight: $font-weight-medium;

		.quote-author {
			@include media-breakpoint-down(ms) {
				display: block;
			}
		}
	}
}

.testimonial-slides-switcher {
	display: none;
}

.instagram-photos-row {
	@extend .uk-child-width-1-3;
	@extend .uk-child-width-1-4\@m;
	@extend .uk-child-width-1-5\@xl;

	margin-top: -70px;

	@include media-breakpoint-down(xs) {
		margin-right: -10px;
		margin-left: -20px;
	}

	.instagram-photo-column {
		@include media-breakpoint-down(lg) {
			&:last-child {
				display: none;
			}
		}

		@include media-breakpoint-down(ms) {
			&:nth-last-child(2) {
				display: none;
			}
		}

		@include media-breakpoint-down(xs) {
			padding-left: 10px;
		}
	}
}

.blog-promo-wrap {
	@extend .uk-flex-middle;

	.section-title,
	.blog-promo-description-column {
		@include media-breakpoint-only(lg) {
			@include make-uk-width-offset(1);
		}
	}

	.blog-promo-description-column {
		@include media-breakpoint-down(md) {
			font-size: 0.9rem;
			line-height: normal;
		}

		.h2-heading-highlight {
			@include h2-heading-highlight;
		}
	}

	.blog-promo-image-column {
		@extend .uk-visible\@l;

		margin-top: 0;
		width: 30%;
	}
}

.dogtopia-home-page-promo-container {
	@include media-breakpoint-down(md) {
		margin-top: -60px;
	}
}


.dogtopia-home-page-promo-container {
  padding: 30px 15px;
  max-width: 1330px;
  margin: 0 auto;
  position: relative;

  .slider-box-shadow > div {
    box-shadow: 0px 1px 11px 5px rgba( 0, 0, 0, 0.12 );
  }

  .national-promo-slider-container {
    background: #FFFFFF; 
    height: 100%;
    display: flex;

    .image-column {
      width: 55%;
      position: relative;
      @include media-breakpoint-down(lg) {
        width: 50%;
      }

      @include media-breakpoint-down(md) {
        width: 55%;
      }

      .image-container {
        background: $brand-orange;
        width: 100%;
        height: 100%; 
        clip-path: polygon( 0 0, 100% 0, 80% 100%, 0% 100% );

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .content-column {
      width: 45%;
      @include media-breakpoint-down(lg) {
        width: 50%;
      }

      @include media-breakpoint-down(md) {
        width: 45%;
      }

      .content-container {
        display: flex;
        flex-direction: column;
        padding: 10px 25px 40px 15px;
        height: 100%;
        @include media-breakpoint-down(sm) {
          padding: 10px 15px 30px 5px;
        }

        .copy-container {
          flex-grow: 4;
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;

          .cursive-text {
            font-family: "signpainter", sans-serif;
            font-weight: 400;
            font-size: 42px;
            text-transform: lowercase;
            line-height: 39px;
            color: #FFCC44;
            margin: 0 0 3px;
            @include media-breakpoint-down(sm) {
              font-size: 30px;
              line-height: 29px;
              margin: 0;
            }
          }

          .line-text {
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            text-transform: uppercase;
            color: #00ACC6;
            margin: 0 0 20px;
            max-width: 240px;
            @include media-breakpoint-down(lg) {
              display: none;
            }
            
            @include media-breakpoint-down(md) {
              display: block;
            }

            @include media-breakpoint-down(sm) {
              font-size: 14px;
              line-height: 20px;
              color: #5B6770;
              text-transform: initial;
              margin: 0;
            }
          }

          .desc-text {
            font-size: 18px;
            line-height: 22px;
            margin: 0;
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }

        .cta-container {
          flex-grow: 1;

          a {
            @include media-breakpoint-down(sm) {
              font-size: 16px;
              line-height: 23px;
              padding: 8px 0;
            }

            &::after {
              content: "";
            }

            i {
              display: inline-block;
              font-size: 14px;
              line-height: 14px;
              font-weight: 400;
              @media all and ( max-width: 374px ) {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .slider-nav-container {
    position: absolute;
    bottom: 20px;
    left: 20px;

    a {
      background: white;
      border-radius: 50%;
      padding: 13px;
      display: inline-flex;
      margin-right: 20px;

      &:active,
      &:focus,
      &:hover {
        text-decoration: none;
        opacity: .7;
      }

      i {
        font-weight: 400;
        font-size: 17px;
        line-height: 17px;
        color: #424A51;
      }
    }
  }

  .uk-dotnav {
    margin-left: -13px;

    li {
      padding-left: 13px;

      &.uk-active {
        a {
          background: #424A51;
        }
      }

      a {
        background: #C3C3C3;
      }
    }
  }

  h3.quick-links-header {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    color: #424A51;
  }

  a.card-container {
    width: 100%;
    height: 100%;
    min-height: 193px;
    background: transparent;
    position: relative;
    display: block;

    &:active,
    &:focus,
    &:hover {
      opacity: .7;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .title-container {
      background: linear-gradient(
        0deg, #000000 0.11%, rgba(0, 0, 0, 0) 92.17%, rgba(0, 172, 198, 0) 92.17%
      );
      position: absolute;
      bottom: 0;
      text-align: right;
      width: 100%;
      padding: 46px 25px 16px 15px;
      z-index: 3;
      @include media-breakpoint-down(sm) {
        padding: 40px 12px 18px;
      }

      h4.card-title {
        font-weight: 400;
        margin: 0;
        font-size: 26px;
        line-height: 1;
        text-transform: uppercase;
        color: #FFFFFF;
        @include media-breakpoint-down(sm) {
          font-size: 20px;
        }

        @media all and ( max-width: 374px ) {
          font-size: 17px;
        }

        span {
          font-family: "signpainter", sans-serif;
          font-size: 46px;
          line-height: 0.8;
          text-transform: lowercase;
          display: inline-block;
          @include media-breakpoint-down(sm) {
            font-weight: 400;
            font-size: 38px;
            letter-spacing: -1px;
            display: block;
            margin-top: 15px;
            margin-right: 15px;
            margin-bottom: -10px;
          }

          @media all and ( max-width: 374px ) {
            font-size: 30px;
          }
        }

        i {
          display: none;
					position: relative;
          @include media-breakpoint-down(sm) {
            display: inline-block;
						bottom: 16px;
          }

					@media all and ( max-width: 374px ) {
						bottom: 10px;
					}
        }
      }

      .cta-text {
        font-size: 20px;
        line-height: 29px;
        color: #FFFFFF;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      i {
        font-size: 12px;
        line-height: 12px;
        color: #FFFFFF;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
}


.content-blog-index-list {
	.image-copy-content-section {
		.image-column.hero-blog-post-card-column {
			@include media-breakpoint-up(lg) {
				margin-top: -60px;
			}
		}
	}
}

.content-blog-post {
	$post-sharing-icons-top-offset: 45px;
	$back-link-bottom-margin:		15px;

	padding-top: 15px + $box-shadow-spacing;

	.post {
		box-shadow: $global-small-box-shadow;

		& + .post-sharing-icons {
			top: ((($global-font-size / 1rem) * $global-line-height) * 16px) + $back-link-bottom-margin + $post-sharing-icons-top-offset;
			margin-bottom: 45px;
		}
	}

	.post-pagination-wrap {
		.blog-post-card .post-media-block .media-block-img {
			@extend .uk-visible\@l;
		}

		& + .content-footer-services-promo {
			margin-top: 70px;
		}
	}

	.blog-promo-wrap {
		margin-top: 70px;

		.section-title:last-child {
			margin-bottom: 0;
		}
	}
}

.blog-list-wrap {
	background-image: url("../images/triangle-bg-left-gray.png");
	background-repeat: no-repeat;
	background-position: top -55px left -160px;

	@include media-breakpoint-up(sm) {
		background-position: top ($grid-list-vertical-gutter - 5) left;
	}
}

.uk-modal-body {
	.form-column {
		p:first-child {
			margin-bottom: 0;
		}
	}

	.gform_confirmation_wrapper {
		background-position: top right -35px;
		margin: -15px ($modal-body-padding-horizontal * -1) 0 ($modal-body-padding-horizontal * -1);
		padding: 15px $modal-body-padding-horizontal 30px $modal-body-padding-horizontal;

		.gform_confirmation_message {
			h3 {
				margin-bottom: 7px;
			}
		}
	}
}

.uk-modal-header,
.uk-modal-body {
	@media (max-width: 499px) {
		padding-right: 25px;
		padding-left: 25px;
	}
}
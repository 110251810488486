
.ui-datepicker-header {
	background-color: $brand-primary;
}

.ui-datepicker-title {
	select {
		border: 1px solid grey;
		border-radius: 5px;
		line-height: normal;
		margin-bottom: 5px;
		padding: 3px 20px 3px 10px;
		width: auto;

		&:not([multiple]) {
			border-bottom-width: 1px;
			height: auto;
		}
	}
}
body.mobile-nav-open {
  .dogtopia-extra-nav-container {
    @include media-breakpoint-down(md) {
      display: block;
    }
  }  
}

.dogtopia-extra-nav-container {
	background: $global-background;
	position: relative;
	z-index: 1001;
  height: 60px;
	@include media-breakpoint-down(md) {
    padding: 15px 0;
		margin-top: -20px;
    height: auto;
    display: none;
	}

	nav {
		padding: 0 15px;
		max-width: 1330px;
		margin: 0 auto;
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
 
    li {
      &.active-page {
        border-bottom: 5px solid $gray-300;
        margin: 0 25px;
      
        a {
          padding: 0;
          // margin-bottom: -3px;
          @include media-breakpoint-down(md) {
            margin-bottom: 0;
          }
        }
      }

      &.social-container {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      a {
        &:active,
        &:focus,
        &:hover {
          text-decoration: none;
          opacity: .6;
        }
      }
    }

		.uk-navbar-right {
			@include media-breakpoint-down(md) {
				margin: 0;
			}

      a {
        color: #9BB1C2;
      
        &.uk-button {
          margin: 3px 25px;
          color: #fff;
          padding: 13px 38px 13px 26px;
          border: none;
          height: 54px;
          bottom: 0;

          &:active,
          &:focus,
          &:hover {
            opacity: 1;
            text-decoration: none;
          }
        }
      }
		}

		ul {
			@include media-breakpoint-down(md) {
				flex-direction: column;
        margin: 0 auto;

        li {
          padding: 18px;
        }
			}

			a {
				color: $gray-300;
				padding: 0 25px;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        min-height: 54px;
        bottom: -5px;
        border-bottom: 5px solid transparent;
        position: relative;
        text-transform: none;
        @include media-breakpoint-down(md) {
          min-height: 0;
          bottom: 0;
        }
			}
		}
	}
}

.dogtopia-partner-promo-post-container {
  font-family: $brandon-text;

  .top-dogtopia {
    margin-bottom: 63px;
    align-items: center;
    flex-direction: column;
    @media all and ( min-width: $breakpoint-small ) {
      margin-bottom: 83px;      
    }

    @media all and ( min-width: $breakpoint-medium ) {
      flex-direction: row;
    }

    img.header-logo {
      margin-right: 0;
      margin-bottom: 20px;
      @media all and ( min-width: $breakpoint-medium ) {
        margin-right: 30px;
        margin-bottom: 0;
      }
    }

    span {
      font-family: $brandon-text;
      font-weight: bold;
      font-size: 15px;
      line-height: 24px;
      text-transform: uppercase;
      color: $gray-300;
      margin-right: 0;
      margin-bottom: 20px;
      @media all and ( min-width: $breakpoint-medium ) {
        font-size: 18px;
        line-height: 25px;
        margin-right: 20px;
        margin-bottom: 0;
      }
    }
  }

  .orange-text {
    color: $brand-orange;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    @media all and ( min-width: $breakpoint-small ) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  .offer-heading-container {
    line-height: 1.25;
    max-width: 835px;
    margin-bottom: 17px;
    @media all and ( min-width: $breakpoint-small ) {
      margin-bottom: 20px;
    }

    .orange-offer-text {
      color: $brand-orange;
      font-family: $sign-painter;
      font-size: 46px;
      line-height: 44px;
      @media all and ( min-width: $breakpoint-small ) {
        font-size: 67px;
        line-height: 65px;
      }
    }

    .offer-text {
      font-family: $brandon-text;
      font-size: 40px;
      line-height: 55px;
      font-weight: 300;
      @media all and ( min-width: $breakpoint-small ) {
        font-size: 50px;
        line-height: 65px;
      }
    }
  }

  .offer-content-container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 157px;
    max-width: 635px;
    font-size: 25px;
    line-height: 40px;
    @media all and ( min-width: $breakpoint-small ) {
      font-size: 30px;
      line-height: 42px;
    }

		.offer-content-button {
			display: inline-flex;
			color: $global-inverse-color;
			margin: 50px auto 0;
			justify-content: center;
			align-items: center;
			padding: 20px 40px;
			background: $brand-blue;
			border-radius: 8px;
			font-size: 1rem;
    	line-height: 1.45;
			font-weight: 500;

			&:active,
			&:focus,
			&:hover {
				text-decoration: none;
    		box-shadow: 0 7px 6px 0 rgba(0, 0, 0, .25);
			}
		}
  }

	.option-b-cta-container {
		margin-top: -84px;
		margin-bottom: -100px;
		@media all and ( min-width: $breakpoint-small ) {
			margin-bottom: -40px;
		}
		@media all and ( min-width: $breakpoint-medium ) {
			margin-bottom: 0;
		}

		.cta-container {
			border-radius: 20px;
			background: #F9F9F9;
			padding: 50px 0 60px;
			position: relative;
			display: block;
			font-size: 20px;
			font-weight: bold;
			line-height: normal;
			color: $gray-300;
			font-family: $brandon-text;
			@media all and ( min-width: $breakpoint-medium ) {
				font-size: 30px;

				&:hover {
					text-decoration: none;
					background: $gray-800;
				}

				&:focus,
				&:active {
					background: #F9F9F9;
				}
			}

			img {
				position: absolute;
				left: 0;
				bottom: 0;
				border-radius: 20px;
			}

			.label-container {
				padding-left: 135px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				padding-right: 30px;
				@media all and ( min-width: $breakpoint-medium ) {
					justify-content: space-between;
					padding-left: 150px;
				}

				@media all and ( min-width: $breakpoint-large ) {
					padding-left: 200px;
				}

				i {
					font-size: 18px;
					line-height: normal;
					position: absolute;
					right: 10px;
					top: 57px;
					@media all and ( min-width: 375px ) {
						right: 30px;
					}

					@media all and ( min-width: $breakpoint-medium ) {
						position: relative;
						font-size: 24px;
						right: auto;
						top: auto;
					}
				}

				.open-arrow-icon {
					display: block;
				}

				.open-label-content {
					display: none;
				}
			}
		}
	}

  .hype-container {
    max-width: 1920px;
    background-image: url( "../images/pp-orange-bg.png" );
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0;
    padding-bottom: 180px;
    @media all and( min-width: $breakpoint-small ) {
      padding-bottom: 80px;
      margin-bottom: 40px;
    }

    @media all and( min-width: $breakpoint-large ) {
      padding-left: 30px;
      padding-right: 30px;
      background-size: 100% 100%;
      margin-bottom: 84px;
    }

    .hype-items-container {
      max-width: 1400px;
      margin: -70px auto 55px;

      .hype-item {
        position: relative;
        max-width: 412px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        color: #455259;
        background-color: #fff;

        .hype-image-container {
          width: 100%;
          height: 100%;
          max-height: 210px;

          .hype-item-image {
            border-radius: 10px 10px 0 0;
            min-height: 210px;
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .badge {
          width: 100%;
          position: absolute;
          bottom: -30px;

          @media all and (min-width: $breakpoint-medium) {
            bottom: -40px;
          }

          img {
            max-width: 70px;

            @media all and (min-width: $breakpoint-medium) {
              max-width: 100%;
            }
          }
        }

        .text-container {
          border-radius: 0 0 10px 10px;
          min-height: 126px;

          @media all and (min-width: $breakpoint-medium) {
            min-height: 156px;
          }

          .text {
            padding: 45px 10px;
            color: inherit;
            line-height: 1.5;
            font-size: 17px;

            @media all and (min-width: $breakpoint-medium) {
              font-size: 20px;
            }
          }
        }

        .text-container {
          background-color: #fff;
        }
      }
    }

    .sub-heading {
      font-family: $brandon-text;
      font-weight: bold;
      font-size: 15px;
      line-height: 24px;
      color: #fff;
      margin: 0;
      @media all and ( min-width: $breakpoint-small ) {
        font-size: 18px;
        line-height: 25px;
      }
    }

    .heading {
      font-family: $brandon-text;
      font-weight: 400;
      font-size: 37px;
      line-height: 37px;
      color: #fff;
      margin: 0 0 20px;
      @media all and ( min-width: $breakpoint-small ) {
        font-size: 40px;
        line-height: 65px;
      }

      span {
        font-family: $sign-painter;
        font-size: 42px;
        line-height: 53px;
        @media all and ( min-width: $breakpoint-small ) {
          font-size: 52px;
          line-height: 65px;
        }
      }
    }

    .statistics-container {
      line-height: 1;
      max-width: 840px;
      margin: 0 auto 50px;
      @media all and( min-width: $breakpoint-medium ) {
        margin: 0 auto 102px;
      }
  
      .number {
        font-weight: 400;
        font-size: 60px;
        line-height: 83px;
        color: #fff;
      }
  
      .text {
        font-size: 20px;
        line-height: 33px;
        color: #fff;
        margin: 0;
      }
    }

		&.option-b-hype-container {
			padding-top: 180px;
			padding-bottom: 0;
			margin-bottom: 160px;
			@media all and ( min-width: $breakpoint-xsmall ) {
				padding-top: 160px;
			}
			@media all and ( min-width: $breakpoint-small ) {
				padding-top: 140px;
			}
			@media all and ( min-width: $breakpoint-medium ) {
				padding-top: 110px;
			}

			.hype-items-container {
				margin-top: 0;
				margin-bottom: -60px;
			}
		}
  }

  .location-section-container {
    max-width: 880px;
    margin: 0 auto 40px;
    @media all and ( min-width: $breakpoint-small ) {
      margin: 0 auto 70px;
    }

    @media all and ( min-width: $breakpoint-medium ) {
      margin: 0 auto 140px;
    }

    .location-content {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: $gray-400;
      margin-bottom: 118px;
      @media all and ( min-width: $breakpoint-small ) {
        font-size: 25px;
        line-height: 41px;
      }
    }

    .location-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      text-transform: uppercase;
      color: $gray-300;
      margin-bottom: 50px;
    }

    .location-form-container {
      max-width: 750px;
      margin: 0 auto;

      .location-field {
        margin-bottom: 40px;
        position: relative;

        &::before {
          font-family: $font-awesome-font-family;
          content: "\f002";
          position: absolute;
          left: 0;
          top: 10px;
          color: $gray-700;
          font-size: 20px;
          font-weight: bold;
        }

        label {
          position: absolute;
          top: 7px;
          left: 30px;
          font-family: $brandon-text;
          font-weight: 400;
          font-size: 25px;
          line-height: 35px;
          color: $gray-400;
          transition: all .3s;
        }

        input {
          position: relative;
          z-index: 2;
          background: transparent;
          padding-left: 30px;
        }

        &.has-focus-or-value {
          label {
            top: -20px;
            font-size: 14px;
          }
        }
      }



      h3.location-instruction {
        font-family: $brandon-text;
        font-weight: 400;
        font-size: 20px;
        text-align: left;
        text-transform: none;
        line-height: 28px;
        color: $gray-400;
        margin: 0 0 15px;
      }

      .pp-location-list-container {
        .loader-container {
          margin-bottom: 30px;

          i {
            animation: rotate-center 1s linear infinite both;
          }
        }

        @keyframes rotate-center {
          0% { transform: rotate(0); }
          100% { transform: rotate( 360deg ); }
        }

        .location-card {
          padding: 30px 12px 30px 30px;
          margin-bottom: 40px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 0px 4px rgba( 0, 0, 0, 0.25 );

          h4 {
            font-family: $brandon-text;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: $gray-300;
          }

          p {
            font-family: $brandon-text;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: $gray-400;
          }

          button.location-button {
            font-family: $brandon-text;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $brand-blue;
            border: none;

            &::after {
              content: "";
            }
          }
        }
      }

      .promo-submission {
        display: inline-flex;
        color: #fff;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        padding: 13px 40px;
        background: $brand-blue;
        border-radius: 8px;

        &::after {
          content: "";
        }

        &:disabled {
          opacity: .4;
        }
      }
    }
  }

	.option-b-booking-container {
		.begin-booking-container {
			max-width: 1100px;
			width: 100%;
			margin: 0 auto 45px;

			.location-title {
				font-size: 18px;
				line-height: normal;
				margin: 0 auto 40px;
			}

			.location-card-container {
				padding: 30px;
				text-align: left;
				border-radius: 10px;
				background-color: #f9f9f9;
				display: flex;
				flex-direction: column;

				h4 {
					font-size: 17px;
					font-weight: 500;
					line-height: normal;
					margin: 0 0 10px;
					color: #424A51;
				}

				span {
					font-size: 14px;
  				line-height: normal;
					display: block;
					margin: 0 0 18px;
					flex-grow: 2;
				}

				a {
					font-family: $brandon-text;
					font-size: 16px;
					font-weight: bold;
					padding: 9px 20px;
					border-radius: 94px;
					max-width: 108px;

					&::after {
						content: '';
					}
				}
			}
		}
	}

  .gallery-container {
    max-width: 1400px;
    margin: 0 auto;
    
    .tint-mobile-iframe-container {
      height: 270px;
      width: 100%;
      overflow: hidden;

      @media all and (min-width: 385px) {
        height: 320px;
      }

      @media all and ( min-width: $breakpoint-xsmall ) {
        height: 360px;
      }
    }
  }

  .pp-footer-container {
    width: 100%;
    background: $brand-orange;
    padding-top: 210px;
    margin-top: -200px;

    > div {
      max-width: 1400px;
    }

    .white-d-logo {
      margin-bottom: 40px;
    }

    .footer-link-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
      flex-direction: column-reverse;
      align-items: center;
      @media all and ( min-width: $breakpoint-medium ) {
        flex-direction: row;
      }

      .copyright-links-container {
        flex-direction: column-reverse;
        text-align: center;
        @media all and ( min-width: $breakpoint-medium-small ) {
          flex-direction: row;
          text-align: left;
        }
      }

      ul {
        li {
          padding: 0 20px 20px;
          @media all and ( min-width: $breakpoint-medium ) {
            padding: 0 35px 0 0;
          }
          
          span,
          a {
            font-family: $brandon-text;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #fff;
            min-height: auto;
            display: inline-block;
            padding: 0;
          }

          a.copyright-link:active,
          a.copyright-link:focus,
          a.copyright-link:hover {
            text-decoration: underline;
          }

          img {
            filter: brightness(200%);

            &:active,
            &:focus,
            &:hover {
              opacity: .7;
            }
          }
        }
      }
    }

		.footer-minor-wrap {
			background: transparent;

			.footer-copyright-secondary-nav {
				a,
				span {
					font-size: .8rem;
					font-weight: 500;
				}
			}
		
			.site-by-reshift {
				a {
					font-size: .8rem;
					font-weight: 500;
				}
			}

		}
  }
}

.pp-form-container {
	padding: 0;
	@media all and ( min-width: $breakpoint-medium ) {
		padding: 0 0 40px;
	}

	&.form-section-bkgd-container {
    .gform_heading {
      display: none;
    }

    .gform_fields {
      grid-row-gap: 20px;
    }

    p {
      font-family: $brandon-text;
      font-size: 18px;
      line-height: 24px;
			color: #5b6770;
      margin: 0 auto 20px;
			text-align: center;
			max-width: 670px;
      @media all and ( min-width: $breakpoint-medium ) {
        font-size: 25px;
        line-height: 41px;
        margin: 0 auto 40px;
      }

			&.legal-text {
				display: none;
			}
    }

    .gform_fields {
      grid-row-gap: 16px !important;
      @media all and ( min-width: $breakpoint-medium ) {
        grid-row-gap: 25px !important;
      }
    }

    .gfield {
      position: relative;
      width: 100%;
      max-width: 370px;
      margin: 0 auto;

      &.d-no-max-width {
        max-width: none;
      }

      &.has-focus-or-value {
        // label {
        //   display: none;
        // }

        .ginput_container_consent {
          label {
            display: inline-block;
          }
        }
      }

      label {
        position: absolute;
        left: 9px;
        top: 9px;
        font-family: $brandon-text;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #4D4D4F;
        cursor: text;
        @media all and ( min-width: $breakpoint-medium ) {
          font-size: 20px;
          line-height: 28px;
          left: 11px;
          top: 11px;
        }

        span.gfield_required {
          font-family: $brandon-text;
          color: #4D4D4F;
          font-size: 16px;
          line-height: 22px;
          @media all and ( min-width: $breakpoint-medium ) {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }

      input[ type=text ], 
      input[ type=email ], 
      input[ type=tel ], 
      input[ type=phone ] {
        appearance: none;
        font-family: $brandon-text;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        // color: #4D4D4F;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        padding: 9px;
        // background: #C9C9CA;
        @media all and ( min-width: $breakpoint-medium ) {
          height: 50px;
          font-size: 20px;
          line-height: 28px;
        }
      }

      .ginput_container_consent {
        display: flex;
        align-items: flex-start;
        text-align: left;
        margin-top: 15px;
        @media all and ( min-width: $breakpoint-medium ) {
          margin-top: 0;
        }
			}

			input[ type=checkbox ] {
				height: 15px;
				min-width: 15px;
				border-radius: 0;
				appearance: none;
				background: $global-inverse-color;
				border: none; 
				outline: transparent;
				cursor: pointer;

				&:active,
				&:focus,
				&:hover {
					border-radius: 0;
					appearance: none;
					// border: none; 
					outline: transparent;
				}

				&:checked {
					background-image: url( '../images/checked-checkbox.png' );
				}
			}

			label {
				font-family: $brandon-text;
				font-size: 14px;
				line-height: 19px;
				letter-spacing: -0.01em;
				color: $global-inverse-color;
				position: relative;
				top: 0;
				padding-right: 10px;
				cursor: pointer;
				@media all and ( min-width: $breakpoint-medium ) {
					font-size: 18px;
					line-height: 25px;
				}

				a {
					text-decoration: underline;
					color: $global-inverse-color;

					&:active,
					&:focus,
					&:hover {
						text-decoration: none;
						color: $global-inverse-color;
					}
				}
			}

			span.gfield_required {
				display: none;
			}      

      &.disclaimer-container {
        p {
          font-family: $brandon-text;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: $global-inverse-color;
          text-align: left;
          @media all and ( min-width: $breakpoint-medium ) {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 15px;
          }
        }
      }
    }

    button[ type=submit ] {
      appearance: none;
      border: none;
      margin: 0 auto;
      background: $brand-orange;
      border-radius: 50px;
      padding: 15px 40px;
      font-family: $brandon-text;
      font-weight: 500;
      font-size: 18px;
      transition: .2s ease;
      line-height: 25px !important; // override mobile setting
      color: $global-inverse-color;
      min-width: 290px;
			width: 100%;
			max-width: 370px;

			&::after {
				content: '';
			}

      &:active,
      &:focus,
      &:hover {
        box-shadow: 0 7px 6px 0 rgba( 0, 0, 0, .25 );
        text-decoration: none;
      }
    }

    .gform_footer {
      display: flex;
      flex-direction: column;
    
      .gform_ajax_spinner {
        width: 35px;
        margin: 10px auto 0;
      }
    }

    .gform_confirmation_message {
      font-family: $brandon-text;
      font-size: 18px;
      line-height: 24px;
      color: $global-inverse-color;

      br {
        display: none;

        &::before,
        &::after {
          content: "";
          display: none;
        }
      }

      img.success-icon {
        margin: 0 0 20px;
        width: 94px;
        height: 94px
      }

      h3 {
        font-weight: 500;
        font-size: 22px;
        margin: 0 0 10px;
        line-height: 30px;
        color: $brand-yellow;
        @media all and ( min-width: $breakpoint-medium ) {
          font-size: 35px;
          margin: 0 0 30px;
        }
      }

      p {
        font-size: 18px;
        margin: 0 0 30px;
        line-height: 24px;
        @media all and ( min-width: $breakpoint-medium ) {
          font-size: 25px;
          line-height: 35px;
        }    
      }

      a.back-home {
        font-size: 16px;
        line-height: 24px;
        display: inline-block;
        margin: 0 0 55px;
        color: $global-inverse-color;
        text-decoration-line: underline;
        @media all and ( min-width: $breakpoint-medium ) {
          font-size: 18px;
          line-height: 25px;
        }

        &:active,
        &:focus,
        &:hover {
          text-decoration: none;
        }
      }

      .social-container {
        margin-bottom: 36px;

        a {
          font-size: 27px;
          text-decoration: none;
          margin: 0 25px;

          &:active,
          &:focus,
          &:hover {
            opacity: .7;
            text-decoration: none;
          }

          img {
            filter: brightness(200%);
          }
        }
      }
    }

    .gform_validation_error {
      .gform_validation_errors {
        background: transparent;
				border: none;
				box-shadow: none;
				padding: 0;
				visibility: hidden;
				max-height: 1px;
      }

      .gfield_validation_message {
        font-family: $brandon-text;
        border: none;
        background: transparent;
        color: $brand-error;
        text-align: left;
        padding: 0;
      }

      .gfield_error {
        input[ type=text ], 
        input[ type=email ] {
          border-color: $brand-error;
        }
      }
    }
  }

	.implied_consent {
		color: $gray-400;
    font-family: $brandon-text;
    font-size: 16px;
    font-weight: 390;
    line-height: normal;
	}

	.gform_wrapper {
		form {
			.gform_fields {
				grid-row-gap: 6px;
				@media all and ( min-width: $breakpoint-medium ) {
					grid-row-gap: 20px;
				}
			
				label {
					font-family: $brandon-text;
					font-size: 16px;
					font-weight: 500;
					line-height: normal;
					color: $gray-300;
					margin: 0 0 10px;
					left: 0;

					.gfield_required {
						display: none;
					}
				}

				input {
					height: 50px;
					margin: 0 0 20px;
					border-radius: 5px;
					border: solid 1px #d3dce4;
					background-color: #fff;
				}

				fieldset {
					legend {
						display: none;
					}
				}
			}

			.ginput_container_consent {
				display: flex;
				align-items: flex-start;
				text-align: left;
				margin-top: 18px;

				input[type=checkbox] {
					height: 15px;
					min-width: 15px;
					border-radius: 0;
					appearance: none;
					background: #fff;
					border: none;
					outline: transparent;
					cursor: pointer;
					border: 2px solid $brand-orange;
					background-position: 50%;
					margin-right: 10px;

					&:active,
          &:focus,
          &:hover {
            border-radius: 0;
            appearance: none;
            outline: transparent;
          }

          &:checked {
            background-image: url( '../images/checked-checkbox.png' );
          }
				}

				label {
					font-family: $brandon-text;
					font-size: 14px;
					font-weight: normal;
					line-height: normal;
					letter-spacing: -0.14px;
					color: $gray-300;

					a {
            text-decoration: underline;
            color: $gray-300;

            &:active,
            &:focus,
            &:hover {
              text-decoration: none;
              color: $gray-300;
            }
          }
				}
			}

			input[ type=submit ] {
				appearance: none;
				border: none;
				margin: 0 auto;
				background: $brand-orange;
				border-radius: 50px;
				padding: 15px 40px;
				font-family: $brandon-text;
				font-weight: 500;
				font-size: 18px;
				transition: .2s ease;
				line-height: 25px !important; // override mobile setting
				color: #fff;
				max-width: 290px;
    		width: 100%;
	
				&:active,
				&:focus,
				&:hover {
					box-shadow: 0 7px 6px 0 rgba( 0, 0, 0, .25 );
					text-decoration: none;
				}
			}

			.gform_footer {
				flex-direction: column;
				padding-bottom: 21px;
				@media all and ( min-width: $breakpoint-medium ) {
					padding-bottom: 16px;
				}

				input {
					text-transform: lowercase;
				}
			}

			.gfield_error {
				input {
					border-color: $brand-error;
				}

				.gfield_description {
					font-family: $brandon-text;
					color: $brand-error;
					border: none;
					padding: 0;
					margin-top: -15px;
					margin-bottom: 10px;
					background: transparent;

					&.instruction {
						display: none;
					}
				}

				&.gfield--type-consent {
					.gfield_description {
						margin-top: 0;
					}
				}
			}
		}

		div.gform_validation_errors {
			background: transparent;
			border: none;
			padding: 0;
			box-shadow: none;
			margin: 0;

			// .validation_error,
			// .validation_list {
			// 	display: none;
			// }
		}
	}
}

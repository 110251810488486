
.content-wrap.content-blog-list {
	padding-bottom: 50px;

	.blog-list-masonry-grid {
		margin-bottom: 50px;
	}

	.no-more-results-msg {
		font-weight: $font-weight-medium;
		display: none;
	}
}

.content-blog-index-list {
	.image-copy-content-section {
		.copy-column {
			@extend .uk-width-1-2\@lg;
			@extend .uk-width-2-5\@xl;
		}

		.image-column.hero-blog-post-card-column {
			@extend .uk-width-1-2\@lg;

			position: relative;
			z-index: 2;
			padding-left: $global-small-gutter;
			max-width: ((($container-xlarge-max-width + $global-gutter) / $grid-columns) * 5);
		}
	}

	@include media-breakpoint-down(xs){
		.image-copy-content-section {
			.image-column.hero-blog-post-card-column {
				padding-left: 20px;
				padding-right: 20px - $global-small-gutter;
			}
		}
	}

	@media (min-width: 400px) and (max-width: $breakpoint-xsmall-max) {
		.blog-post-card:not(.hero-blog-post-card) {
			.uk-card-media-top {
				height: 145px;
			}

			.uk-card-body {
				padding-top: 25px;
				padding-bottom: 25px;
			}
		}
	}

	@include media-breakpoint-only(xs) {
		.blog-post-card:not(.hero-blog-post-card) {
			h4 {
				line-height: 1.5;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.image-copy-content-section {
			.image-column.hero-blog-post-card-column {
				padding-left: $global-gutter;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.image-copy-content-section .image-column.hero-blog-post-card-column {
			margin-top: 55px;
		}

		.blog-list-grid-wrap {
			margin-top: ($grid-list-top-offset * -1);

			.blog-list-item {
				&:nth-child(3),
				&:nth-child(4) {
					margin-top: $grid-list-top-offset + $grid-list-vertical-gutter;
				}
			}
		}
	}

	@include media-breakpoint-down(xxs) {
		.blog-post-card:not(.hero-blog-post-card) {
			.uk-card-body {
				padding-top: 30px;
				padding-bottom: 30px;
			}
		}
	}
}

.content-blog-post {
	.blog-promo-wrap {
		.section-title:last-child {
			margin-bottom: 0;
		}
	}

	.blog-posts-grid-row {
		margin-top: 25px;
	}

	@include media-breakpoint-down(xs) {
		.blog-posts-grid-row {
			padding-bottom: 0 !important;
		}

		.content-footer-services-promo {
			margin-top: 70px;
		}
	}

	@media (max-width: 399px) {
		.post-pagination-wrap {
			display: none;
		}
	}
}

.blog-list-masonry-grid {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: flex-start;
	position: relative;
	margin-right: -15px;
	margin-left: ($global-gutter * -1);
	padding-right: 15px;

	// Right side mask to hide any list items that fall out of the parent before it's resized
	&:after {
		content: "";
		background-color: $global-background;
		z-index: 2;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 100%;
		padding-left: ($global-gutter / 2);
		width: 100%;
	}

	.blog-list-item {
		@extend .uk-width-1-2\@sm;
		@extend .uk-width-1-3\@md;
		@extend .uk-width-1-4\@lg;

		margin-top: $grid-list-vertical-gutter;
		padding-left: $global-gutter;
		transition: opacity .4s ease;
		order: 9999;

		&.loading {
			opacity: 0;
		}
	}

	@include media-breakpoint-down(xs) {
		.blog-list-item {
			width: 100%;

			&:first-child {
				margin-right: auto;
				margin-left: auto;
				width: percentage(6 / $grid-columns);
			}
		}
	}

	@include media-breakpoint-down(xxs) {
		.blog-list-item:first-child {
			width: percentage(8 / $grid-columns);
		}
	}

	@media (max-width: 400px) {
		.blog-list-item:first-child {
			width: 100%;
		}
	}
}

.load-more-btn-column {
	text-align: center;
}
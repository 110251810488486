.insurance-aggregator-page-container {
  margin-top: -1px;
  margin-bottom: 60px;
  overflow: hidden;
  @media all and ( min-width: $breakpoint-medium ) {
    margin-bottom: 130px;
  }

  .hero-banner-container  {
    height: auto;
    @media all and ( min-width: $breakpoint-large ) {
      height: 545px;
    }

    .hero-image-container {
      height: 255px;
      position: relative;
      padding: 0;
      width: 100%;
      @media all and ( min-width: $breakpoint-large ) {
        max-height: 545px;
        position: absolute;
        height: 100%;
      }

      img {
        object-position: left;
        object-fit: cover;
        min-height: 255px;
        height: 100%;
        width: 100%;
        @media all and ( min-width: $breakpoint-large ) {
          object-position: center;
        }
      }
    }

    .hero-content-container {
      padding-top: 50px;
      position: relative;
      z-index: 2;
      text-align: center;
      @media all and ( min-width: $breakpoint-large ) {
        width: 57%;
        max-width: 652px;
        float: right;
        text-align: left;
      }

      &:before {
        display: block;
        position: absolute;
        content: "";
        width: 78px;
        background-image: url('/wp-content/themes/dogtopia-2018/assets/images/insurance04.png');
        background-size: contain;
        z-index: 2;
        height: 78px;
        top: -40px;
        right: 0;
        left: 0;
        margin: 0 auto;
        @media all and ( min-width: $breakpoint-large ) {
          display: none;
        }
      }

      .hero-header {
        font-weight: 400;
        font-size: 38px;
        line-height: 44px;
        text-transform: uppercase;
        color: #424A51;
        @media all and ( min-width: $breakpoint-medium ) {
          font-size: 50px;
          line-height: 50px;
        }

        span {
          font-family: "signpainter", Arial, sans-serif;
          display: block;
          font-size: 58px;
          line-height: 57px;
          color: #FE8200;
          text-transform: lowercase;
          @media all and ( min-width: $breakpoint-medium ) {
            font-size: 78px;
            line-height: 76px;
          }
        }
      }

      .insurance-banner-copy {
        font-weight: 500;
      }
    }
  }

  .insurance-info-container {
    position: relative;
    padding-top: 30px;
    @media all and ( min-width: $breakpoint-medium ) {
      padding-top: 100px;
    }

    &:before {
      display: none;
      position: absolute;
      width: 118px;
      background-image: url('/wp-content/themes/dogtopia-2018/assets/images/insurance04.png');
      z-index: 2;
      height: 118px;
      top: -40px;
      right: 30px;
      @media all and ( min-width: $breakpoint-large ) {
        display: block;
      }
    }

    .section-one-container {
      flex-direction: column-reverse;
      text-align: center;
      @media all and ( min-width: $breakpoint-medium ) {
        flex-direction: row;
        text-align: left;
      }

      .row-one-image-container {
        margin-bottom: -70px;
        margin-left: -20px;
        margin-right: -20px;
        @media all and ( min-width: $breakpoint-medium ) {
          margin: 0;
        }
      }

      .row-one-content-container {
        max-width: 430px;
        margin: 0 auto;
        float: none;
        padding-right: 0;
        box-sizing: content-box;
        @media all and ( min-width: $breakpoint-medium ) {
          margin: 0;
          float: right;
          padding-right: 40px;
        }
      }
    }

    .section-two-container {
      text-align: center;
      margin-bottom: 30px;
      @media all and ( min-width: $breakpoint-medium ) {
        text-align: left;
      }

      .row-two-image-container {
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: -70px;
        @media all and ( min-width: $breakpoint-medium ) {
          margin-left: -40px;
          margin-right: 0;
          margin-bottom: 0;
        }
      }

      .row-two-content-container {
        max-width: 430px;
        margin: 0 auto;
        @media all and ( min-width: $breakpoint-medium ) {
          margin: 0;
        }
      }
    }
  }
}


@mixin hook-form {
	border-color: $form-border-color;
	border-style: solid;
}

@mixin hook-form-single-line {
	border-bottom-width: $form-border-width;
}

@mixin hook-form-multi-line {
	border-width: $form-border-width;
	padding-right: 10px;
	padding-left: 10px;
}

@mixin hook-form-focus {
	border-color: $form-focus-border-color;
}

@mixin hook-form-danger {
	border-color: $brand-error;
}

@mixin hook-form-radio {
	border: 2px solid $brand-orange;
}

@mixin hook-form-radio-checked {
	border-color: $form-radio-checked-border;
}

@mixin hook-form-label {
	line-height: $form-height;
	position: absolute;
	display: block;
	transition: color 0.2s ease,
				font-size 0.2s ease,
				line-height 0.2s ease,
				top 0.2s ease;
}

.highcharts-container {
	svg {
		font-family: $global-font-family !important;

		.highcharts-title > tspan {
			font-size: $global-small-font-size !important;
		}

		.highcharts-title > tspan,
		.highcharts-legend-item > text {
			color: #666666 !important;
			fill: #666666 !important;
		}

		.highcharts-title > tspan,
		.highcharts-axis-labels > text {
			font-weight: $font-weight-bold !important;
		}

		.highcharts-axis-labels,
		.highcharts-legend-item,
		.highcharts-tooltip {
			& > text {
				font-size: 0.7rem !important;
			}
		}

		.highcharts-legend-item > text {
			font-weight: $font-weight-medium !important;
		}

		.highcharts-tooltip > text {
			& > tspan:first-child {
				font-size: $global-small-font-size !important;
			}
		}
	}
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	@extend .uk-button;
    @extend .uk-button-default;
}

.uk-button-default-inverse {
	border: $button-border-width solid $inverse-button-default-border-color;
	background-color: $inverse-button-default-background;
	color: $inverse-button-default-color;
	padding: ($button-padding-vertical - $button-border-width) ($button-padding-horizontal - $button-border-width);
	padding-right: ((($global-font-size / 1rem) * ($global-small-font-size / 1rem)) * 16px) + ($button-padding-horizontal - $button-border-width);

	&:after {
		right: ($button-padding-horizontal - $button-border-width);
	}

	&:hover {
		color: $inverse-button-default-hover-color;
	}
}

.uk-button-text.load-more-btn {
	position: relative;
	padding: 0 ($global-line-height * 1em) ($global-line-height * 1em) ($global-line-height * 1em);

	&:after {
		content: fa-content($fa-var-angle-down);
		transition: padding 0.2s ease;
		top: ($global-line-height * 1em);
		right: 0;
		left: 0;
		width: auto;
	}

	&:hover,
	&:focus {
		&:after {
			margin-bottom: 5px;
			padding-top: 5px;
		}
	}

	&.loading {
		&:after {
			content: "";
			background-image: url("../images/loader.gif");
			background-repeat: no-repeat;
			background-size: cover;
			top: ($global-line-height * 1em) + (($global-line-height / 2) * 1em);
			margin: -7px auto 0 auto;
			padding: 0;
			width: 15px;
			height: 15px;
		}
	}
}

.uk-button-link {
	text-transform: none;

	&:hover,
	&:focus {
		box-shadow: none;
	}
}

@mixin hook-base-h1 {
	margin-bottom: 20px;

	& > small {
		@include h1-heading-highlight;
	}
}

@mixin hook-base-h2 {
	margin-bottom: 18px;

	& > small {
		@include h2-heading-highlight;
	}
}
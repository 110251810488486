
@mixin hook-flex-misc {
	@include media-breakpoint-up(xs) {

		.uk-flex-left\@xs {
			justify-content: flex-start;
		}

		.uk-flex-center\@xs {
			justify-content: center;
		}

		.uk-flex-right\@xs {
			justify-content: flex-end;
		}

		.uk-flex-between\@xs {
			justify-content: space-between;
		}

		.uk-flex-around\@xs {
			justify-content: space-around;
		}


		.uk-flex-stretch\@xs {
			align-items: stretch;
		}

		.uk-flex-top\@xs {
			align-items: flex-start;
		}

		.uk-flex-middle\@xs {
			align-items: center;
		}

		.uk-flex-bottom\@xs {
			align-items: flex-end;
		}

	}

	@include media-breakpoint-up(sm) {

		.uk-flex-stretch\@s {
			align-items: stretch;
		}

		.uk-flex-top\@s {
			align-items: flex-start;
		}

		.uk-flex-middle\@s {
			align-items: center;
		}

		.uk-flex-bottom\@s {
			align-items: flex-end;
		}

	}

	@include media-breakpoint-up(ms) {

		.uk-flex-left\@ms {
			justify-content: flex-start;
		}

		.uk-flex-center\@ms {
			justify-content: center;
		}

		.uk-flex-right\@ms {
			justify-content: flex-end;
		}

		.uk-flex-between\@ms {
			justify-content: space-between;
		}

		.uk-flex-around\@ms {
			justify-content: space-around;
		}


		.uk-flex-stretch\@ms {
			align-items: stretch;
		}

		.uk-flex-top\@ms {
			align-items: flex-start;
		}

		.uk-flex-middle\@ms {
			align-items: center;
		}

		.uk-flex-bottom\@ms {
			align-items: flex-end;
		}

	}

	@include media-breakpoint-up(md) {

		.uk-flex-stretch\@m {
			align-items: stretch;
		}

		.uk-flex-top\@m {
			align-items: flex-start;
		}

		.uk-flex-middle\@m {
			align-items: center;
		}

		.uk-flex-bottom\@m {
			align-items: flex-end;
		}

	}

	@include media-breakpoint-up(lg) {

		.uk-flex-stretch\@l {
			align-items: stretch;
		}

		.uk-flex-top\@l {
			align-items: flex-start;
		}

		.uk-flex-middle\@l {
			align-items: center;
		}

		.uk-flex-bottom\@l {
			align-items: flex-end;
		}

	}

	@include media-breakpoint-up(xl) {

		.uk-flex-stretch\@xl {
			align-items: stretch;
		}

		.uk-flex-top\@xl {
			align-items: flex-start;
		}

		.uk-flex-middle\@xl {
			align-items: center;
		}

		.uk-flex-bottom\@xl {
			align-items: flex-end;
		}

	}
}
.preferred-partners-page-container {
  margin-top: -1px;
  margin-bottom: 30px;
  overflow: hidden;
  @media all and ( min-width: $breakpoint-medium ) {
    margin-bottom: 70px;
  }

  .hero-banner-container  {
    height: auto;
    @media all and ( min-width: $breakpoint-large ) {
      height: 545px;
    }

    .hero-image-container {
      height: 255px;
      position: relative;
      padding: 0;
      width: 100%;
      @media all and ( min-width: $breakpoint-large ) {
        max-height: 545px;
        position: absolute;
        height: 100%;
      }

      img {
        object-position: center;
        object-fit: cover;
        min-height: 255px;
        height: 100%;
        width: 100%;
      }
    }

    .hero-content-container {
      padding-top: 50px;
      position: relative;
      z-index: 2;
      text-align: center;
      @media all and ( min-width: $breakpoint-large ) {
        width: 57%;
        max-width: 652px;
        float: right;
        text-align: left;
      }

      &:before {
        display: block;
        position: absolute;
        content: "";
        width: 78px;
        background-image: url('/wp-content/themes/dogtopia-2018/dist/images/insurance04.png');
        background-size: contain;
        z-index: 2;
        height: 78px;
        top: -40px;
        right: 0;
        left: 0;
        margin: 0 auto;
        @media all and ( min-width: $breakpoint-large ) {
          display: none;
        }
      }
    }
  }

  .insurance-info-container {
    position: relative;
    max-width: 1115px;
    padding-top: 70px;
    @media all and ( min-width: $breakpoint-medium ) {
      padding-top: 100px;
    }

    &:before {
      position: absolute;
      width: 78px;
      background-image: url('/wp-content/themes/dogtopia-2018/dist/images/circle-icon-daycare.png');
      background-size: contain;
      z-index: 2;
      height: 78px;
      top: -40px;
      left: 0;
      right: 0;
      margin: 0 auto;
      @media all and ( min-width: $breakpoint-large ) {
        display: block;
        top: -60px;
        left: 30px;
        width: 118px;
        height: 118px;
        margin: 0;
      }
    }

    .intro-container {
      flex-direction: column-reverse;
      text-align: center;
      @media all and ( min-width: $breakpoint-medium ) {
        flex-direction: row;
        text-align: left;
      }

      p {
        font-size: 22px;
        line-height: 31px;
        padding-bottom: 50px;
        border-bottom: 1px solid #c3c3c3;
        text-align: left;
        @media all and ( min-width: $breakpoint-medium ) {
          font-size: 26px;
          line-height: 37px;
        }
      }
    }

    .info-container {
      margin-top: 50px;
      flex-direction: column-reverse;
      text-align: center;
      margin-bottom: 40px;
      @media all and ( min-width: $breakpoint-medium ) {
        flex-direction: row;
        text-align: left;
      }

      h2 {
        font-size: 21px;
        line-height: 30px;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #424A51;
      }

      p {
        text-align: left;
      }
    }

    .section-two-container {
      text-align: center;
      max-width: 1115px;
      margin-bottom: 30px;
      @media all and ( min-width: $breakpoint-medium ) {
        text-align: left;
      }

      .column-container {
        margin-bottom: 40px;

        .media-container {
          margin: 0 -15px 20px;
          // height: 245px;
          @media all and ( min-width: $breakpoint-medium ) {
            margin: 0 0 20px;
            // height: 350px;
          }

          img {
            height: 100%;
            object-fit: contain;
            // width: 100%;
            object-position: left;
          }

          a.uk-toggle {
            position: relative;

            &:active,
            &:focus,
            &:hover {
              opacity: .8;
            }

            .play-icon {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              font-size: 40px;
              text-align: center;
              color: white;
              text-shadow: 2px 2px 2px black;
              z-index: 2;
            }
          }
        }

        p {
          text-align: left;
        }
      }
    }

    .accordion-container {
      max-width: 1115px;
      margin-top: 20px;
      @media all and ( min-width: $breakpoint-medium ) {
        text-align: left;
        margin-top: 40px;
      }

      h2 {
        font-size: 21px;
        line-height: 30px;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #424A51;
        margin-bottom: 35px;
        text-align: center;
        @media all and ( min-width: $breakpoint-medium ) {
          text-align: left;
        }
      }

      li {
        margin-bottom: 50px;
      
        a.uk-accordion-title {
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
          color: #424A51;
          padding-top: 0;
          position: relative;
          display: flex;
          align-items: center;
          @media all and ( min-width: $breakpoint-medium ) {
            font-size: 26px;
            line-height: 32px;
          }

          .toggle-icon {
            content: url( '/wp-content/themes/dogtopia-2018/assets/images/open-plus-sm.svg' );
            top: 0;
            background: #FE8200;
            height: 38px;
            width: 38px;
            display: flex;
            margin-right: 16px;
            align-items: center;
            justify-content: center;
            border-radius: 40px;
            padding: 10px;
            @media all and ( min-width: $breakpoint-medium ) {
              content: url( '/wp-content/themes/dogtopia-2018/assets/images/open-plus.svg' );
              margin-right: 26px;
              height: 54px;
              width: 54px;
              border-radius: 60px;
            }
          }
        }

        &.uk-open {
          a.uk-accordion-title {
            .toggle-icon {
              content: url( '/wp-content/themes/dogtopia-2018/assets/images/closed-minus-sm.svg' );
              @media all and ( min-width: $breakpoint-medium ) {
                content: url( '/wp-content/themes/dogtopia-2018/assets/images/closed-minus.svg' );
              }
            }
          }
        }

        .uk-accordion-content {
          margin-left: 0;
          @media all and ( min-width: $breakpoint-medium ) {
            margin-left: 80px;
          }
        }
      }
    }
  }
}

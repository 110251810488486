*,
*:before,
*:after {
	box-sizing: border-box;
}

*:focus {
	outline: 0;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6,
.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6,
p,
ul, ol {
	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

h1, h2,
.uk-h1, .uk-h2 {
	font-weight: $font-weight-black;
}

h1, h3,
.uk-h1, .uk-h3 {
	color: $gray-300;
}

h1, .uk-h1 {
	@include media-breakpoint-down(md) {
		font-size: $global-xlarge-font-size;
		line-height: $base-h2-line-height;
	}
}

h2, .uk-h2 {
	@include media-breakpoint-down(md) {
		font-size: 1.5rem;
	}
}

* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
	margin-top: 0;
}

p,
ul,
ol {
	margin-bottom: ($global-line-height * 1rem);
}

ol {
	& > li + li {
		margin-top: ($global-line-height * 1rem);
	}
}

figure {
	margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

blockquote {
	p {
		color: $brand-orange;
		font-size: 1.5rem;
		font-weight: $font-weight-bold;
		text-align: center;
		line-height: 1.4;
	}
}

.content-page {
	.content-footer-services-promo {
		margin-top: 50px;

		@include media-breakpoint-up(lg) {
			margin-top: 70px;
		}
	}
}

.page-title {
	&,
	&.section-title {
		margin-bottom: 16px;
	}

	h2 {
		@include h1-heading-highlight;
	}
}

.body-copy-content-wrap {
	.body-copy-content-column {
		@extend .uk-width-4-5\@sm;
		@extend .uk-width-3-5\@lg;

		@include media-breakpoint-up(sm) {
			@include make-uk-width-offset(1);
		}

		@include media-breakpoint-up(lg) {
			@include make-uk-width-offset(2);
		}
	}
}

.service-cards-wrap {
	@extend .uk-clearfix;

	position: relative;
	margin-left: ($global-small-gutter * -1);

	@include media-breakpoint-up(md) {
		margin-left: ($global-gutter * -1);
	}

	.service-card {
		@include media-breakpoint-down(md) {
			padding-left: $global-gutter;

			& + .service-card {
				margin-top: 105px;
			}
		}

		@include media-breakpoint-down(ms) {
			padding-left: $global-small-gutter;
		}

		@media (max-width: ($service-card-breakpoint-small - 0.02px)){
			.uk-card-body {
				margin-right: auto;
				margin-left: auto;
			}
		}
	}

	.dogtopia-slogan,
	.boarding-service-card {
		@media (min-width: $service-card-breakpoint-small) {
			float: right;
		}

		@include media-breakpoint-up(lg) {
			float: left;
		}
	}

	.daycare-service-card,
	.spa-service-card {
		@media (min-width: $service-card-breakpoint-small) {
			float: left;
			margin-left: abs($card-badge-top);
		}

		@include media-breakpoint-up(sm) {
			margin-left: abs($card-badge-top-xl);
		}

		@include media-breakpoint-up(ms) {
			@include make-uk-width-offset(1);
		}

		@include media-breakpoint-up(lg) {
			float: right;
			margin-left: 0;
		}

		@include media-breakpoint-down(md) {
			.uk-card-media-top,
			.uk-card-media-right,
			.uk-card-media-bottom,
			.uk-card-media-left {
				margin-left: 15px;
			}
		}
	}

	.dogtopia-slogan {
		text-align: right;
		padding-left: $global-gutter;
		width: 30%;
	}

	.daycare-service-card {
		z-index: 1;

		@include media-breakpoint-up(lg) {
			margin-right: -25px;
		}

		@include media-breakpoint-up(xl) {
			@include make-uk-width-offset(1, "right");
		}
	}

	.boarding-service-card {
		@media (min-width: $service-card-breakpoint-small) {
			margin-right: abs($card-badge-top);
		}

		@include media-breakpoint-up(sm) {
			margin-left: abs($card-badge-top-xl);
		}

		@include media-breakpoint-up(ms) {
			@include make-uk-width-offset(1, "right");
		}

		@include media-breakpoint-up(lg) {
			margin-right: 0;
			margin-left: -40px;
		}

		@include media-breakpoint-up(xl) {
			margin-left: $global-gutter;
		}
	}

	.spa-service-card {
		@media (min-width: $service-card-breakpoint-small) and (max-width: $breakpoint-medium-max) {
			.uk-card-badge {
				right: auto;
				left: $card-badge-right;
			}
		}

		@include media-breakpoint-up(lg) {
			margin-right: -5px;
		}

		@include media-breakpoint-up(xl) {
			@include make-uk-width-offset(1, "right");
		}
	}
}

.content-footer-promo-block.find-daycare-promo-block {
	display: inline-block;
	margin-top: 70px;
}
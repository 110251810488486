
.content-team {
	.team-content-column {
		@extend .uk-width-4-5\@sm;

		.image-copy-content-section {
			.page-title,
			.copy-column {
				margin-left: 0;
			}

			.copy-column {
				width: 100%;
			}
		}
	}

	.team-members-grid {
		margin-top: 40px;
	}

	.team-member-group-block {
		@extend .uk-width-4-5\@sm;

		margin-top: 50px;
	}

	.uk-grid.content-footer-services-promo {
		margin-top: 70px;
	}

	@include media-breakpoint-up(sm) {
		.team-content-column,
		.team-member-group-block {
			@include make-uk-width-offset(1);
		}
	}

	@include media-breakpoint-between(ms, md) {
		.team-member-group-block {
			margin-left: 0;
			width: 100%;
		}
	}
}

.content-team-general {
	.uk-grid {
		& + .uk-grid-stack:not(.content-footer-services-promo) {
			margin-top: 0;
		}
	}

	@include media-breakpoint-between(ms, lg) {
		.team-members-grid {
			justify-content: space-around;
		}
	}
}

.content-board-members {
	@include media-breakpoint-only(ms) {
		.team-members-grid {
			margin-right: (($container-small-max-width + $global-small-gutter) / $grid-columns) * -1;
			margin-left: (($container-small-max-width + $global-small-gutter) / $grid-columns) * -1;
		}
	}

	@include media-breakpoint-only(md) {
		.team-members-grid {
			margin-right: -10%;
			margin-left: -10%;
		}
	}
}

.content-team-member-detail {
	$team-member-detail-border-offset: 	20px;

	.image-copy-content-section:first-child {

		.copy-column {
			@extend .uk-width-3-5\@l;
		}

		.page-title {
			margin-bottom: 3px;

			small {
				margin-top: -8px;
				margin-bottom: 0;
			}
		}

		.image-column {
			margin-top: 15px;
			padding-left: $global-small-gutter;
			max-width: ((((($container-xlarge-max-width + $global-gutter) / $grid-columns) * 3) - $global-gutter) + $global-small-gutter) - (40px - $team-member-detail-border-offset);

			.team-member-detail-image {
				position: relative;
				padding-right: $team-member-detail-border-offset;
				padding-bottom: $team-member-detail-border-offset;

				&:before {
					content: "";
					border: 4px solid $brand-yellow;
					position: absolute;
					top: $team-member-detail-border-offset;
					right: 0;
					bottom: 0;
					left: $team-member-detail-border-offset;
					z-index: -1;
				}
			}
		}
	}

	.team-members-promo-banner {
		position: relative;
		z-index: 0;
		margin-top: 70px;
	}

	.team-members-grid {
		@extend .uk-child-width-1-3\@sm;
		@extend .uk-child-width-1-4\@md;
		@extend .uk-child-width-1-5\@lg;

		position: relative;
		z-index: 1;
		margin-top: -85px;
	}

	@media (max-width: 599px) {
		.team-members-promo-banner,
		.team-members-grid {
			display: none;
		}
	}

	@include media-breakpoint-down(md) {
		.image-copy-content-section:first-child {
			.copy-column {
				order: 2;
				margin-top: 30px;
			}
		}

		.team-members-grid {
			& > :last-child {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(ms) {
		.team-members-grid {
			& > :nth-last-child(2) {
				display: none;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.image-copy-content-section:first-child {
			.image-column {
				padding-left: $global-gutter;
				max-width: ((($container-xlarge-max-width + $global-gutter) / $grid-columns) * 3) - (40px - $team-member-detail-border-offset);
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.image-copy-content-section:first-child {
			.image-column {
				padding-right: (40px - $team-member-detail-border-offset);
				max-width: ((($container-xlarge-max-width + $global-gutter) / $grid-columns) * 3);
				width: 40%;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.image-copy-content-section:first-child {
			.image-column {
				max-width: unset;
				width: 30%;
			}
		}
	}
}

.team-members-promo-banner {
	background-image: url("../images/team-member-promo-bg.jpg");
	background-repeat: no-repeat;
	background-position: top center;
	padding: 30px 0 115px 0;

	h2 {
		color: $global-inverse-color;
		text-align: center;
		margin-bottom: 30px;
	}
}

.team-member-group-block {
	h2 {
		margin-bottom: 30px;
	}
}

.team-member-list {
	& > .team-member-media-block + .team-member-media-block {
		margin-top: 70px;

		@include media-breakpoint-up(ms) {
			margin-top: 35px;
		}
	}
}
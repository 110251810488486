
.body-copy-content-wrap {
	.body-copy-content-column {
		@extend .uk-width-4-5\@sm;

		@include media-breakpoint-up(sm) {
			@include make-uk-width-offset(1);
		}
	}

	& > .image-slideshow-column,
	.body-copy-content-column > .image-slideshow-column {
		width: 100%;
	}

	.image-slideshow-column.small-image-slideshow-column {
		@include make-uk-width-offset(2);

		@extend .uk-width-3-5;
	}

	.captioned-slideshow-wrap {
		margin-top: 40px;
		margin-bottom: 20px;
	}

	.did-you-know-banner-column {
		margin-top: 60px;
		margin-bottom: 30px;
	}

	@include media-breakpoint-down(sm) {
		.did-you-know-banner-column {
			margin-right: ($global-small-gutter * -1);
			margin-left: ($global-small-gutter * -1);
		}
	}

	@include media-breakpoint-up(ms) {
		.did-you-know-banner-column {
			width: 100%;
		}
	}
}

.body-copy-content-column {
	& > ul {
		@include colored-bullet-list;
	}
}

.captioned-slideshow-wrap {
	$captioned-slideshow-border-width: 3px;
	$captioned-slideshow-border-v-offset: 20px;
	$captioned-slideshow-border-h-offset: 20px;
	$captioned-slideshow-border-v-offset-sm: 30px;
	$captioned-slideshow-border-h-offset-sm: 30px;

	@extend .uk-flex-middle\@l;

	position: relative;

	.image-slideshow-column {
		@extend .uk-width-1-1;
		@extend .uk-width-3-4\@lg;
	}

	.caption-column {
		position: absolute;
		z-index: 1;
		right: 0;
		bottom: 50px;
		left: 0;
	}

	@include media-breakpoint-down(md) {
		margin-left: 0;

		.image-slideshow-column,
		.caption-column {
			padding-left: 0;
		}

		.image-slideshow {
			.uk-slideshow-items > * {
				background-color: $global-background;
			}

			&:before {
				content: "";
				border: $captioned-slideshow-border-width solid $brand-yellow;
				position: absolute;
				top: $captioned-slideshow-border-v-offset-sm;
				right: $captioned-slideshow-border-h-offset-sm;
				bottom: ($captioned-slideshow-border-v-offset-sm * -1) + 50px;
				left: ($captioned-slideshow-border-h-offset-sm * -1);
				z-index: -1;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.image-slideshow {
			&:before {
				top: $captioned-slideshow-border-v-offset;
				right: $captioned-slideshow-border-h-offset;
				bottom: ($captioned-slideshow-border-v-offset * -1) + 50px;
				left: ($captioned-slideshow-border-h-offset * -1);
			}
		}
	}

	@include media-breakpoint-down(xs) {
		margin-right: auto;
		margin-left: auto;
		width: 90%;
	}

	@include media-breakpoint-up(lg) {
		.caption-column {
			position: relative;
			bottom: 0;
			margin-left: ((map_get($container-max-widths, xl) + $global-gutter) / $grid-columns) * -1;
			margin-bottom: 50px;
			width: percentage(3 / 8);
		}
	}
}

.slideshow-caption-holder {
	@extend .uk-flex;
	@extend .uk-flex-bottom;
	@extend .uk-flex-middle\@l;

	transition: height 0.6s ease;
	position: relative;

	.slideshow-caption-slide {
		background-color: rgba($global-background, 0.9);
		transition: opacity 0.6s;
		margin: 3px;
		padding: 12px;
		width: 100%;

		&.next,
		&.prev {
			opacity: 0
		}

		&.prev {
			margin-right: -100%;
		}

		& + .slideshow-caption-slide.next {
			margin-left: -100%;
		}
	}

	p {
		font-size: 0.8rem;
		line-height: normal;
	}

	@include media-breakpoint-up(md) {
		p {
			font-size: 1rem;
		}
	}

	@include media-breakpoint-up(lg) {
		$slideshow-caption-border-offset: 20px;

		background-color: $global-background;
		box-shadow: $global-small-box-shadow;

		&:before {
			content: "";
			border: 4px solid $brand-yellow;
			display: block;
			position: absolute;
			top: ($slideshow-caption-border-offset * -1);
			right: ($slideshow-caption-border-offset * -1);
			bottom: $slideshow-caption-border-offset;
			left: $slideshow-caption-border-offset;
			z-index: -1;
		}

		.slideshow-caption-slide {
			background-color: transparent;
			padding: 60px 50px;
		}

		p {
			font-size: 1.25rem;
		}
	}
}

.form-column {
	.gform_wrapper {
		margin-bottom: 20px;

		form {
			@extend .uk-flex;
			@extend .uk-flex-wrap;
		}

		.validation_error,
		.gform_body {
			width: 100%;
		}

		.gform_footer {
			position: relative;
			margin-right: auto;
			margin-left: auto;

			.gform_ajax_spinner {
				position: absolute;
				top: 50%;
				left: 100%;
				margin-top: -11px;
				margin-left: 10px;
			}
		}
	}

	.legal-text {
		font-size: 0.7rem;
		line-height: normal;
	}
}

.instagram-photo {
	overflow: hidden;
	position: relative;
	padding-top: 100%;

	& > a {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		& > img {
			max-width: none;

			&.orientation-landscape {
				width: auto;
				height: 100%;
			}

			&.orientation-portrait {
				width: 100%;
				height: auto;
			}
		}
	}

	img {
		box-shadow: 3px 7px 7px 0 rgba(0, 0, 0, 0.25);
		width: auto;
		max-width: none;
		height: 100%;
	}

	.icon-overlay {
		@extend .uk-position-cover;

		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
		color: $global-inverse-color;
		text-align: right;

		.icon {
			font-size: 1.15rem;
			position: absolute;
			right: 10px;
			bottom: 10px;
		}
	}
}

.content-footer-promo-block {
	$content-footer-promo-block-cta-overlap: 75px;

	border: $promo-block-border-width solid $brand-yellow;
	border-top: none;
	border-left: none;
	position: relative;
	padding-right: 20px;

	.promo-body {
		p {
			margin-bottom: 15px;
		}

		.uk-button {
			@extend .uk-hidden\@ms;
		}
	}

	.promo-heading {
		@extend .uk-h2;

		display: block;
		margin: 0;
	}

	@include media-breakpoint-up(ms) {
		display: flex;
		flex-wrap: nowrap;
		padding: 30px (45px + $content-footer-promo-block-cta-overlap) 30px 0;

		.promo-body {
			p:last-of-type {
				margin-bottom: 0;
			}
		}

		.promo-cta {
			position: absolute;
			left: 100%;
			top: 50%;
			transform: translateY(-50%);
			margin-left: ($content-footer-promo-block-cta-overlap * -1);
		}
	}

	&.find-daycare-promo-block {
		@include media-breakpoint-down(sm) {
			margin-bottom: (($button-height + $promo-block-border-width) / 2);

			.promo-cta {
				margin-top: 15px;
				margin-bottom: (($button-height + $promo-block-border-width) / 2) * -1;
			}
		}

		@media (min-width: $breakpoint-medium-small) and (max-width: 709px) {
			.promo-heading {
				font-size: $global-large-font-size;
			}
		}
	}

	&.franchise-promo-block {
		$franchise-promo-block-cta-overlap: 205px;

		.promo-body {
			p {
				line-height: normal;
			}
		}

		.promo-heading {
			margin-bottom: 7px;

			small {
				color: $brand-blue;
				font-size: 1rem;
				margin-bottom: -5px;
			}
		}

		.image-button-cta {
			& > a,
			& > button {
				margin-top: 0;
				margin-left: 0;
			}

			.uk-button {
				@extend .uk-visible\@ms;
			}
		}

		@include media-breakpoint-up(ms) {
			margin-right: 70px;
			padding: 0 (20px + ($franchise-promo-block-cta-overlap - $promo-block-border-width)) 40px 0;

			.promo-cta {
				top: 0;
				transform: none;
				margin-left: ($franchise-promo-block-cta-overlap - $promo-block-border-width) * -1;
			}

			.image-button-cta {
				& > a,
				& > button {
					margin-top: -35px;
					margin-left: 25px;
				}
			}
		}

		@include media-breakpoint-up(md) {
			margin-right: 35px;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 50px;

			&:before {
				content: "";
				background-color: $global-background;
				z-index: 1;
				position: absolute;
				top: 0;
				right: ($promo-block-border-width * -1);
				left: 0;
				height: 100px;
			}

			.promo-body {
				position: relative;
				z-index: 2;
			}

			.promo-cta {
				margin-top: 15px;
				margin-bottom: (50px + $promo-block-border-width) * -1;
				width: 200px;
			}
		}
	}
}

@mixin hook-alert {
	font-weight: $font-weight-medium;
	line-height: $base-h3-line-height;
	padding-left: 50px;

	&:before {
		@include make-fa-icon;
		@include fa-solid;

		line-height: inherit;
		position: absolute;
		top: $alert-padding;
		left: $alert-padding;
	}
}

@mixin hook-alert-danger {
	&:before {
		content: fa-content($fa-var-exclamation);
	}
}
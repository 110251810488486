
@mixin hook-navbar-transparent {
	background-color: transparent;
}

@mixin hook-navbar-nav-item {
	border-bottom: $navbar-nav-item-active-border-width solid transparent;
	font-weight: $font-weight-medium;
	text-transform: lowercase;
}

@mixin hook-navbar-nav-item-hover {
	color: $gray-200;
}

@mixin hook-navbar-nav-item-active {
	border-bottom-color: $global-inverse-color;

	&:hover {
		@include hook-navbar-nav-item-hover;
	}
}

@mixin hook-navbar-toggle {
	display: block;
	transition: all 0.2s ease;
	position: relative;
	padding: $navbar-toggle-padding-vertical $navbar-toggle-padding-horizontal;
	width: $navbar-toggle-width;
	height: $navbar-toggle-height;

	@at-root button#{&} {
		border: none;
		background-color: transparent;
	}

	&:before,
	&:after {
		content: " ";
		background-color: $navbar-toggle-color;
		display: block;
		transition: opacity 0.2s ease,
					top 0.1s ease 0.1s,
					bottom 0.1s ease 0.1s,
					transform 0.1s cubic-bezier(.215,.61,.355,1);
		position: absolute;
		width: $navbar-toggle-icon-bar-width;
		height: $navbar-toggle-icon-bar-height;
	}

	&:before {
		top: $navbar-toggle-padding-vertical + ($navbar-toggle-icon-bar-height / 2);
		margin-top: ($navbar-toggle-icon-bar-height / 2) * -1;
	}

	&:after {
		right: auto;
		bottom: $navbar-toggle-padding-vertical + ($navbar-toggle-icon-bar-height / 2);
		margin-bottom: ($navbar-toggle-icon-bar-height / 2) * -1;
	}

	&:hover,
	&:focus {
		box-shadow: none;
	}

	&:hover {
		opacity: 0.75;
	}

	&.uk-open,
	&:focus {
		background-color: rgba(255, 255, 255, 0.15);
	}

	&.uk-open {
		&:before,
		&:after {
			transition: top 0.1s ease 0s,
			bottom 0.1s ease 0s,
			transform 0.1s cubic-bezier(.215,.61,.355,1) 0.1s;
		}

		&:before {
			top: 50%;
			transform: rotate(-45deg);
		}

		&:after {
			bottom: 50%;
			transform: rotate(45deg);
		}
	}
}

@mixin hook-navbar-dropdown {
	$navbar-dropdown-outer-padding: 			15px;
	$navbar-dropdown-inner-padding-horizontal:	60px;
	$navbar-dropdown-column-width:				((($container-max-width + $global-gutter) / $grid-columns) * 2);

	background: transparent;
	padding: 0;
	width: auto;

	&.navbar-dropdown-has-featured-item {
		&.navbar-dropdown-2-column-width {
			width: (($navbar-dropdown-column-width * 2) - $global-gutter) + $navbar-dropdown-inner-padding-horizontal;
		}

		&.navbar-dropdown-3-column-width {
			width: (($navbar-dropdown-column-width * 3) - $global-gutter) + $navbar-dropdown-inner-padding-horizontal;
		}

		&.navbar-dropdown-height-large {
			.navbar-dropdown-inner-wrap {
				height: 390px;
			}
		}

		.navbar-dropdown-inner-wrap {
			height: 340px;
		}

		.uk-navbar-dropdown-nav {
			padding-right: 0;
		}
	}

	.navbar-dropdown-inner-wrap {
		background: $navbar-dropdown-background;
		box-shadow: 0 0 9px 6px rgba(0, 0, 0, 0.2);
	}
}

@mixin hook-navbar-dropdown-nav {
	@extend .uk-flex;
	@extend .uk-flex-column;
	@extend .uk-flex-wrap;

	padding: (28px - ($navbar-dropdown-nav-item-gutter-vertical / 2)) 60px;
	height: 100%;

	& > li {
		margin: 3px 4px;
	}
}

@mixin hook-navbar-dropdown-nav-item {
	font-size: 0.9rem;
	font-weight: $font-weight-medium;
	text-transform: lowercase;
	line-height: 1.56;
	white-space: nowrap;
	padding: 4px 0;
}
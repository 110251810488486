
.dogtopia-foundation-bar-wrap,
footer {
	font-size: $global-small-font-size;
	font-weight: $font-weight-medium;
}

footer {
	background-color: $global-background;
}

.dogtopia-foundation-block-wrap {
	background-color: $brand-blue;
}

.dogtopia-foundation-block {
	color: $global-inverse-color;

	.foundation-description-column {
		line-height: $base-h5-line-height;
	}
}

.footer-main-wrap {

}

.app-promo-column {
	background-color: $brand-orange;
	background-image: url("../images/footer-app-promo-bg.jpg");
	background-repeat: no-repeat;
	background-position: center top;
	overflow: hidden;
	position: relative;

	&.uk-grid-margin {
		margin-top: 0;
	}

	.app-promo-copy {
		@extend .uk-width-3-5\@sm;
		@extend .uk-width-1-2\@ms;

		.app-download-btn {
			margin-top: 115px;
		}

		@include media-breakpoint-up(sm) {
			.app-download-btn {
				margin-top: 10px;
			}
		}

		@media (min-width: 570px) {
			margin-left: 3%;
		}

		@include media-breakpoint-up(ms) {
			@include make-uk-width-offset(1);
		}

		@include media-breakpoint-down(xs) {
			margin-right: auto;
			margin-left: auto;
			max-width: 360px;
			width: 100%;

			.app-download-btn {
				margin-left: 10px;
			}
		}

		@media (max-width: 369px) {
			.app-download-btn:last-child {
				margin-left: 0;
			}
		}

		@media (max-width: 359px) {
			.app-download-btn:first-child {
				margin-left: 0;
			}
		}

		@media (max-width: 349px) {
			.app-download-btn:first-child {
				margin-left: -10px;
			}
		}

		@media (max-width: 330px) {
			p {
				text-align: center;
			}

			.app-download-btn:first-child {
				margin-left: 0;
			}

			.app-download-btn:last-child {
				margin-top: 0
			}
		}
	}

	.app-promo-image {
		& > img {
			width: 190px;
		}

		@include media-breakpoint-up(sm) {
			margin-top: 15px;
			margin-bottom: -140px;
		}

		@include media-breakpoint-up(ms) {
			& > img {
				margin-left: 20px;
			}
		}

		@include media-breakpoint-down(xs) {
			text-align: center;
			position: absolute;
			top: auto;
			right: 0;
			left: 0;
			bottom: -100px;
			padding-left: 0;
		}
	}

	@include media-breakpoint-up(lg) {
		background-position: right top;
	}

	@include media-breakpoint-down(md) {
		padding: 0;
		width: 100%;

		.uk-container {
			padding: 0 $global-gutter;
		}
	}

	@include media-breakpoint-down(ms) {
		.uk-container {
			padding: 0 $global-small-gutter;
		}
	}
}

.app-promo-copy {
	color: $global-inverse-color;
	padding-top: 30px;
	padding-bottom: 20px;

	@include media-breakpoint-up(sm) {
		padding-top: 50px;
		padding-bottom: 40px;
	}

	// h2,
	h3 {
		color: inherit;
		margin-bottom: 7px;
		font-weight: 900;
		font-size: 2rem;
		@include media-breakpoint-between(sm, md) {
			font-size: $base-h2-font-size;
		}

		span {
			@include media-breakpoint-down(md) {
				font-size: 1.3rem;
			}

			@include h2-heading-highlight;			
			margin-bottom: -6px;
		}
	}

	// h3 {
	// 	@include h2-heading-highlight;

	// 	margin-bottom: -6px;
	// }

	.app-download-btn {
		vertical-align: top;
		display: inline-block;

		@include media-breakpoint-up(sm) {
			&:first-child {
				margin-left: -10px;
			}
		}
	}
}

.footer-minor-wrap {
	.uk-grid {
		@include make-uk-child-width-ready;
	}

	@include media-breakpoint-down(md) {
		.footer-copyright-secondary-nav {
			order: 2;
		}

		.site-by-reshift {
			order: 3;
		}

		.social-account-icons {
			order: 1;
		}
	}

	a {
		color: inherit;
	}
}

.footer-copyright-secondary-nav {
	@extend .uk-flex;
	@extend .uk-flex-middle;

	line-height: normal;

	@include media-breakpoint-down(md) {
		text-align: center;
		display: block;

		& > span {
			display: block;
		}
	}

	&.uk-grid-margin {
		margin-top: 12px;
	}

	.uk-nav {
		font-size: 0;
		text-transform: lowercase;

		@include media-breakpoint-only(sm) {
			margin-left: -20px;
		}

		@include media-breakpoint-between(ms, md) {
			margin-left: -35px;
		}

		@include media-breakpoint-down(md) {
			margin-top: 15px;
		}

		& > li {
			font-size: $global-small-font-size;

			@include media-breakpoint-up(sm) {
				display: inline-block;
				margin-left: 20px;
			}

			@include media-breakpoint-up(ms) {
				margin-left: 35px;
			}

			@include media-breakpoint-down(xs) {
				& + li {
					margin-top: 14px;
				}
			}

			& > a {
				padding: 0;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.site-by-reshift {
	@include media-breakpoint-down(md) {
		text-align: center;

		&.uk-grid-margin {
			margin-top: 15px;
		}
	}
}
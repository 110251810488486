
.post-media-block {
	@extend .uk-flex;

	margin-left: -20px;

	.media-block-img,
	.media-block-body {
		margin-left: 20px;
	}

	.media-block-body {
		h4 {
			line-height: normal;
		}
	}

	img {
		@extend .uk-preserve-width;
	}
}

.team-member-media-block {
	@extend .uk-flex-top\@ms;

	margin-left: -40px;

	img {
		@extend .uk-preserve-width;
	}

	.media-block-img,
	.media-block-body {
		margin-left: 40px;
	}

	.team-member-photo {
		display: inline-block;
		padding-right: 0;
		padding-bottom: 0;

		&:before {
			width: 100%;
			height: 100%;
		}
	}

	.media-block-body {
		padding-top: $team-member-card-border-offset + 10;

		h3 {
			margin-bottom: 10px;
		}
	}

	.job-title {
		color: $brand-orange;
		text-transform: none;
	}

	@include media-breakpoint-up(ms) {
		display: flex;

		.media-block-body {
			padding-top: $team-member-card-border-offset;
		}
	}
}

.header-wrap {
	&.has-top-banner-media {
		@include media-breakpoint-only(lg) {
			.header-top-banner-elements {
				position: static;
			}
		}

		@include media-breakpoint-up(xl) {
			box-shadow: none;

			.header-top-banner-wrap {
				background-color: $global-background;
				text-align: center;
			}

			.header-navbar-banner-wrap {
				box-shadow: $global-medium-box-shadow;
			}
		}
	}

	.social-account-icons {
		font-size: 1.35rem;
	}
}

.top-banner-video-holder {
	.top-banner-media-effect-mask {
		background-image: linear-gradient(to bottom, rgba(0,0,0,0.25), rgba(0,0,0,0));
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1;
		height: 225px;
	}
}

.header-top-banner-elements {
	z-index: 2;

	.uk-grid {
		@extend .uk-child-width-auto;
		@extend .uk-child-width-expand\@l;

		@include media-breakpoint-up(xl) {
			.header-logo {
				@include make-uk-width-ready;
			}
		}
	}

	.social-account-icons {
		ul {
			margin-left: ($header-social-account-icons-margin-horizontal + $iconnav-padding-horizontal) * -1;

			& > li {
				padding-left: $header-social-account-icons-margin-horizontal;
			}
		}

		@include media-breakpoint-up(xl) {
			ul {
				margin-left: ($header-social-account-icons-margin-horizontal-xl + $iconnav-padding-horizontal) * -1;

				& > li {
					padding-left: $header-social-account-icons-margin-horizontal-xl;
				}
			}
		}
	}

	.header-logo {
		@extend .uk-width-auto\@xl;
	}

	.secondary-header-nav {
		position: relative;
		z-index: ($global-z-index + 1);

		@include media-breakpoint-down(md) {
			margin-left: auto;
		}
	}
}

.header-navbar-banner-wrap {
	@extend .uk-visible\@l;

	@include media-breakpoint-up(xl) {
		&.uk-sticky.uk-active {
			box-shadow: $global-medium-box-shadow;
		}
	}

	.uk-navbar-container {
		display: block;
	}

	.header-navbar {
		margin: 0 ($navbar-nav-item-padding-horizontal * -1);
	}
}

.header-logo {
	text-align: center;
}

.header-navbar {
	@extend .uk-navbar-center;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"] {
	@extend .uk-input;
}

input[type="radio"] {
	@extend .uk-radio;
}

input[type="checkbox"] {
	@extend .uk-checkbox;
}

input[type="range"] {
	@extend .uk-range;
}

select {
	@extend .uk-select;
}

textarea {
	@extend .uk-textarea;
}

fieldset {
	@extend .uk-fieldset;
}

legend {
	@extend .uk-legend;
}

.gform_wrapper {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	
	.validation_error {
		@extend .uk-alert;
		@extend .uk-alert-danger;
		padding: 25px 0 0 !important;

		&::before {
			top: 0 !important;
			display: none !important;
		}

		div.validation_error,
		h2 {
			margin: 0 !important;
			border: none !important;
			color: white !important;
			padding: 0 50px !important;
			text-align: center;
			text-transform: none;
			font-size: 24px;
			padding-bottom: 25px !important;

			&::before {
				top: 0 !important;
				display: inline-block !important;
			}
		}
	}

	ol.validation_list {
		display: none;
	}

	.inv-recaptcha-holder {
		display: none;
	}

	.gf_progressbar {
		background-color: #D8D8D8;
		position: relative;
		margin-top: 40px;
		margin-bottom: 30px;
		height: 11px;

		.gf_progressbar_percentage {
			background-color: $brand-success;
			height: 100%;

			span {
				position: absolute;
				top: -30px;
			}
		}
	}

	.gform_body {
		margin-bottom: 15px;

		.gform_hidden,
		.gfield.hidden,
		.gfield_creditcard_warning_message {
			display: none;
		}
	}

	.gform_footer {
		text-align: center;
	}

	.gfield {
		position: relative;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		&.has-focus-or-value {
			& > label {
				color: $gray-700;
				font-size: $global-small-font-size;
				line-height: ((($global-font-size / 1rem) * ($global-small-font-size / 1rem)) * 16px);
				top: -7px;
			}
		}

		&.gfield_error {
			& > label {
				color: $brand-error;
			}

			.ginput_container {
				& > select,
				& > textarea,
				& > input:not([type="checkbox"]):not([type="file"]) {
					@extend .uk-form-danger;
				}
			}

			.gfield_description.validation_message {
				@extend .uk-text-danger;
			}
		}

		&.gfield_textarea,
		&.gfield_checkbox,
		&.gfield_radio {
			& > label,
			& > .gfieldset > legend.gfield_label {
				line-height: normal;
				position: static;
			}
		}

		&.gfield_checkbox {
			&.checkbox-options-inline {
				margin-bottom: 12px;

				.gfield_checkbox {
					@extend .uk-flex;
					@extend .uk-flex-wrap;

					margin-left: -20px;

					& > li {
						margin-left: 20px;
					}
				}
			}

			& + .gfield_checkbox {
				margin-top: -10px;
			}

			legend.gfield_label {
				font-size: inherit;
			}

			&:not(.checkbox-options-inline) {
				.gfieldset {
					.ginput_container.ginput_container_checkbox {
						margin-top: 0;
					}
				}
			}
		}

		&.gfield_textarea {
			& > label {
				margin-bottom: 10px;
			}
		}

		&.gfield_creditcard {
			& > label {
				@extend .uk-h3;

				display: block;
				margin-bottom: 8px;

				.gfield_required {
					display: none;
				}
			}

			.ginput_full,
			.ginput_cardinfo_right {
				position: relative;

				& > label {
					@extend .uk-form-label;
				}
			}

			.ginput_full,
			.ginput_cardinfo_right,
			.ginput_card_expiration_container {
				& > label:after {
					content: " *";
					color: $brand-error;
				}
			}

			.ginput_full {
				display: block;
				margin-bottom: 20px;

				&.has-focus-or-value {
					&,
					.ginput_cardinfo_right {
						& > label {
							color: $gray-700;
							font-size: $global-small-font-size;
							line-height: ((($global-font-size / 1rem) * ($global-small-font-size / 1rem)) * 16px);
							top: -7px;
						}
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			.gform_card_icon_container {
				@extend .uk-flex;

				margin-bottom: 18px;
				margin-left: -7px;

				.gform_card_icon {
					background-image: url("../images/credit-card-icons.png");
					background-repeat: no-repeat;
					background-position: top left;
					background-size: cover;
					text-indent: -9000px;
					margin-left: 7px;
					width: 34px;
					height: 23px;

					&.gform_card_icon_discover {
						background-position: top left -41px;
					}

					&.gform_card_icon_mastercard {
						background-position: top left -82px;
					}

					&.gform_card_icon_visa {
						background-position: top left -123px;
					}
				}
			}

			.ginput_cardextras {
				@extend .uk-flex-bottom\@xs;

				display: block;
				margin-left: ($global-gutter * -1);

				.ginput_cardinfo_left,
				.ginput_cardinfo_right {
					display: block;

					padding-left: $global-gutter;
				}

				.ginput_cardinfo_left {
					@extend .uk-width-2-3\@m;
				}

				.ginput_cardinfo_right {
					@extend .uk-width-1-3\@m;
				}
			}

			.ginput_cardinfo_right {
				position: relative;

				.ginput_card_security_code_icon {
					background-image: url("../images/cvc-icon.png");
					background-repeat: no-repeat;
					background-size: cover;
					position: absolute;
					right: -40px;
					bottom: 10px;
					width: 33px;
					height: 20px;
				}
			}

			.ginput_card_expiration_container {
				@extend .uk-flex;
				@extend .uk-flex-wrap;

				label {
					width: 100%;
				}

				.ginput_card_expiration {
					width: 44.4444444%;
				}
			}
		}

		&[class*="uk-width-"] {
			@media (max-width: 399px){
				width: 100%;
			}

			@media (min-width: 400px) {
				display: inline-block;

				& + [class*="uk-width-"] {
					padding-left: $global-gutter;
				}
			}
		}

		& > label:not(.gfield_label_before_complex) {
			@extend .uk-form-label;
		}

		.gfield_required {
			color: $brand-error;
			margin-left: 5px;
		}
	}

	.ginput_container {
		&.ginput_container_checkbox,
		&.ginput_container_radio {
			li {
				label {
					font-size: $global-small-font-size;
					line-height: normal;
					cursor: pointer;
					padding: 4px 0 5px 6px;
				}
			}
		}

		&.ginput_container_checkbox {
			font-size: 0;
			margin-top: 17px;

			li {
				@extend .uk-flex;
				@extend .uk-flex-top;

				input[type=checkbox] {
					flex-shrink: 0;
					margin-top: floor((((($global-font-size / 1rem) * 16px) * $global-line-height) - $form-radio-size) / 2);
				}
			}
		}

		&.ginput_container_select {
			position: relative;

			select {
				background-color: transparent;
				padding-top: 1px;
			}
		}

		textarea {
			line-height: normal;
			max-height: 220px;
		}
	}

	.gf_invisible {
		visibility: hidden;
		position: absolute;
		left: -9999px;
	}

	.gfield_description {
		font-size: $global-small-font-size;
	}

	.gform_button,
	.gform_next_button {
		@extend .uk-button;
		@extend .uk-button-primary;
	}

	.gform_ajax_spinner {
		margin-left: 20px;
		width: 22px;
	}

	@include media-breakpoint-down(xxs) {
		.gfield.gfield_creditcard {
			.ginput_full.has-focus-or-value {
				& > label:not(:first-child) {
					top: 34px;
				}
			}

			.ginput_card_expiration_container {
				justify-content: space-between;
			}

			.ginput_cardinfo_left {
				margin-bottom: 20px;
			}

			.ginput_cardinfo_right {
				width: 50%;
				min-width: 150px;
			}
		}
	}

	@include media-breakpoint-only(xs) {
		.gfield.gfield_creditcard {
			.ginput_card_expiration_container {
				.ginput_card_expiration {
					width: 43.4444444%;
				}
			}

			.ginput_cardinfo_right {
				padding-right: 25px;
			}
		}
	}

	@include media-breakpoint-up(xs) {
		.gfield.gfield_creditcard {
			.gform_card_icon_container {
				position: absolute;
				top: ((40px - 5px) * -1);
				right: 0;
				margin-bottom: 0;
			}

			.ginput_cardextras {
				display: flex;
			}

			.ginput_card_expiration_container {
				.ginput_card_expiration_year {
					margin-left: $global-gutter - 5;
				}
			}

			.ginput_cardinfo_right {
				.ginput_card_security_code_icon {
					right: -15px;
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.gfield.gfield_creditcard {
			.gform_card_icon_container {
				right: 65px;
			}
		}
	}

	@include media-breakpoint-up(ms) {
		.gfield.gfield_creditcard {
			.ginput_cardinfo_right {
				.ginput_card_security_code_icon {
					right: -10px;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.gfield.gfield_creditcard {
			.ginput_cardinfo_right {
				.ginput_card_security_code_icon {
					right: -43px;
				}
			}
		}
	}

	@include media-breakpoint-only(lg) {
		.gfield.gfield_creditcard {
			.gform_card_icon_container {
				right: 35px;
			}

			.ginput_cardextras {
				.ginput_cardinfo_right {
					padding-left: $global-gutter - 10;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.gfield.gfield_creditcard {
			.ginput_card_expiration_container {
				.ginput_card_expiration_year {
					margin-left: $global-gutter;
				}
			}
		}
	}
}

.gform_confirmation_wrapper {
	background-image: url("../images/scruffy-illustrated.svg");
	background-repeat: no-repeat;
	background-position: bottom left 40px;
	margin: 40px 0 50px 0;
	padding-bottom: 120px;

	&:before {
		content: url("../images/success-circle-check-icon.svg");
		font-size: 0;
		text-align: center;
		display: block;
		margin-bottom: $global-line-height * 1rem;
	}

	.gform_confirmation_message {
		text-align: center;

		h3 {
			margin-bottom: 20px;
		}
	}
}

.training-long-form_wrapper {
	.gfield {
		width: 50%;

		&.gsection,
		&.gfield_html,
		&.gfield_address,
		&.gfield_email_confirm,
		&.total-right,
		&.terms-conditions,
		&.gfield_full {
			width: 100%;
		}

		&.gfield_address,
		&.gfield_email_confirm {
			margin-left: ($global-gutter * -1);

			.ginput_complex {
				display: flex;
				flex-wrap: wrap;
			}

			.gfield_label_before_complex,
			.ginput_full,
			.ginput_left,
			.ginput_right,
			.validation_message {
				padding-left: $global-gutter;
			}

			.ginput_full,
			.ginput_left,
			.ginput_right {
				position: relative;
				margin-bottom: 20px;

				&.has-focus-or-value {
					& > label {
						color: $gray-700;
						font-size: $global-small-font-size;
						line-height: ((($global-font-size / 1rem) * ($global-small-font-size / 1rem)) * 16px);
						top: -7px;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}

				& > label {
					@extend .uk-form-label;
				}
			}

			.gfield_label_before_complex {
				font-weight: bold;
			}

			.ginput_full {
				width: 100%;
			}

			.ginput_left,
			.ginput_right {
				width: 50%;
			}
		}

		&.gfield_email_confirm {
			& > label,
			.ginput_container_email {
				padding-left: $global-gutter;
			}

			.ginput_container_email {
				width: 50%;
			}
		}

		&.gfield_price,
		&.gfield_total,
		&.total-right,
		&.terms-conditions {
			& > label {
				line-height: normal;
				position: static;
			}
		}

		&.total-right {
			text-align: right;

			& > label {
				font-weight: $font-weight-bold;
			}
		}

		&.gfield_multi_time {
			.clear-multi {
				display: flex;
				margin-left: -10px;
			}

			.ginput_container_time {
				padding-left: 10px;
				width: 33.333333333%;
			}

			.gfield_time_hour {
				i {
					font-style: normal;
					float: right;
				}
			}
		}

		&.gfield_full {
			.ginput_container {
				width: 50%;
			}
		}

		&.thankyoumessage {
			@extend .uk-h3;

			color: $brand-primary;
			text-align: center;
		}
	}

	.ginput_container {
		&.ginput_container_checkbox {
			margin-top: 5px;
		}
	}

	.ginput_product_price {
		color: $brand-primary;
	}

	.ginput_total {
		color: $brand-success;
	}

	.review-table {
		& > table > tbody > tr > td > table > tbody > tr:not(.lastrow) {
			display: none;
		}

		.gpecf-order-summary > tbody > tr > td:first-child > div {
			color: $gray-400 !important;
		}
	}

	.gform_page_footer {
		margin-top: 45px;
	}
}

.content-single {
	// Adding spacing to padding and negative margin to accommodate top box shadow while using overflow: hidden on parent
	margin-top: (($single-post-top-overlap + $box-shadow-spacing) * -1);
	padding-top: $box-shadow-spacing;

	.single-post-content-column {
		position: relative;

		.back-link {
			font-weight: $font-weight-medium;
			margin-bottom: 15px;

			a {
				color: inherit;

				span {
					margin-right: 8px;
				}
			}
		}
	}

	.post {
		background-color: $global-background;
		box-shadow: $global-small-box-shadow;
		position: relative;
		z-index: 2;
		padding: 15px $article-card-padding 40px $article-card-padding;

		& + .post-sharing-icons {
			@extend .uk-visible\@l;

			background-color: $global-background;
			box-shadow: $global-small-box-shadow;
			padding: 8px 0;

			.uk-iconnav {
				@extend .uk-iconnav-vertical;

				margin-top: 0;

				& > li {
					padding-top: 0;
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.single-post-content-column {
			margin: 0 8px;
		}

		.post {
			padding: 15px $article-card-padding-md 60px $article-card-padding-md;
		}
	}

	@include media-breakpoint-up(lg) {
		.single-post-content-column {
			margin: 0 auto;
			width: (((($container-large-max-width + $global-gutter) / $grid-columns) * 8) - $global-gutter) + ($article-card-padding-lg * 2);
		}

		.post {
			padding: 20px $article-card-padding-lg 60px $article-card-padding-lg;
		}

		.post-sharing-icons {
			position: absolute;
			top: $single-post-top-overlap;
			left: 0;
			margin-bottom: $single-post-top-overlap;
			margin-left: -60px;

			&.has-position-fixed {
				margin-left: 0;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.single-post-content-column {
			width: (((($container-xlarge-max-width + $global-gutter) / $grid-columns) * 8) - $global-gutter) + ($article-card-padding-lg * 2);
		}
	}
}

.post {
	header {
		margin-bottom: 25px;

		.post-sharing-icons {
			@extend .uk-hidden\@l;

			margin-bottom: -10px;

			.uk-iconnav {
				@extend .uk-flex-right;

				& > * {
					padding-left: 0;
				}

				& > li > a {
					padding-right: 10px;
					padding-left: 10px;
				}

				.iconnav-label {
					margin-right: 10px;
				}
			}
		}

		.post-category {
			@extend .uk-h3;

			margin-bottom: -2px;
		}

		@include media-breakpoint-down(md) {
			.post-category {
				font-size: 1rem;
				line-height: $global-line-height;
				margin-bottom: 4px;
			}
		}

		@media (max-width: 399px) {
			.post-sharing-icons {
				margin-bottom: 5px;

				.uk-iconnav {
					justify-content: flex-start;
					margin-left: 0;
				}
			}
		}
	}

	.entry-content {
		@extend .uk-clearfix;

		min-height: 300px + $global-large-gutter;

		& > h3,
		& > h4,
		& > h5 {
			color: $brand-orange;
		}

		& > h3 {
			margin-bottom: $base-h3-line-height * 1em;
		}

		& > h4 {
			line-height: $global-line-height;
			margin-bottom: 1.6em;
		}

		& > ul {
			@include colored-bullet-list;
		}

		.post-featured-image {
			text-align: center;
		}

		.image-slideshow-column.large-image-slideshow-column,
		.did-you-know-banner-column {
			margin-right: ($article-card-padding * -1);
			margin-left: ($article-card-padding * -1);
		}

		.image-slideshow-column {
			&.small-image-slideshow-column {
				margin-right: auto;
				margin-left: auto;
				max-width: ((($container-xlarge-max-width + $global-gutter) / $grid-columns) * 6) - $global-gutter;
			}
		}

		.did-you-know-banner-column {
			margin-top: 30px;
			margin-bottom: 40px;
		}

		.did-you-know-banner {
			padding-right: $article-card-padding;
			padding-left: $article-card-padding;

			.did-you-know-banner-copy {
				width: auto;
			}
		}

		.mid-content-service-promo {
			@extend .uk-width-4-5;
			@extend .uk-width-3-5\@ms;
			@extend .uk-width-2-5\@m;
			@extend .uk-width-1-3\@xl;
		}

		@include media-breakpoint-down(md) {
			.post-featured-image {
				margin-right: (15px + $article-card-padding-md) * -1;
				margin-left: (15px + $article-card-padding-md) * -1;
			}
		}

		@include media-breakpoint-down(ms) {
			.mid-content-service-promo {
				margin-right: auto;
				margin-left: auto;
			}
		}

		@include media-breakpoint-down(xs) {
			.post-featured-image {
				margin-right: ($article-card-padding + $global-small-gutter) * -1;
				margin-left: ($article-card-padding + $global-small-gutter) * -1;
			}
		}

		@include media-breakpoint-down(xxs) {
			.did-you-know-banner {
				.did-you-know-banner-copy {
					padding: 0 10px;
				}
			}

			.mid-content-service-promo {
				width: auto;
			}
		}

		@include media-breakpoint-between(sm, ms) {
			.did-you-know-banner {
				.did-you-know-banner-copy {
					padding: 0;
				}
			}
		}

		@include media-breakpoint-up(sm) {
			.image-slideshow-column.large-image-slideshow-column,
			.did-you-know-banner-column {
				margin-right: ($article-card-padding-md * -1);
				margin-left: ($article-card-padding-md * -1);
			}

			.did-you-know-banner {
				padding-right: $article-card-padding-md;
				padding-left: $article-card-padding-md;
			}
		}

		@include media-breakpoint-only(ms) {
			.did-you-know-banner {
				padding-top: 35px;
			}
		}

		@include media-breakpoint-up(ms) {
			.did-you-know-banner {
				.did-you-know-banner-copy {
					margin-left: ((($container-max-width + $global-gutter) / $grid-columns) * 2);
				}
			}
		}

		@include media-breakpoint-up(md) {
			.mid-content-service-promo {
				float: right;
			}
		}

		@include media-breakpoint-up(lg) {
			.post-featured-image {
				@include make-x-cols-wide(5, map_get($container-max-widths, lg));

				float: right;
				margin: 0 ((($container-large-max-width + $global-gutter) / $grid-columns) * -1) $global-large-gutter $global-gutter;
			}

			.image-slideshow-column.large-image-slideshow-column,
			.did-you-know-banner-column {
				margin-right: ($article-card-padding-lg * -1);
				margin-left: ($article-card-padding-lg * -1);
			}

			.did-you-know-banner {
				padding-right: $article-card-padding-lg;
				padding-left: $article-card-padding-lg;

				.did-you-know-banner-copy {
					margin-left: ((($container-xlarge-max-width + $global-gutter) / $grid-columns) * 2);
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.post-featured-image {
				@include make-x-cols-wide(5, map_get($container-max-widths, xl));

				margin-right: (($container-xlarge-max-width + $global-gutter) / $grid-columns) * -1;
			}

			.mid-content-service-promo {
				margin-right: ($global-large-gutter - $article-card-padding-lg);
			}
		}
	}
}
// styles + classes borrowed from _contact.scss
.real-estate-page-container {
	padding-bottom: 45px;
	@media all and ( min-width: $breakpoint-medium ) {
		padding-bottom: 75px;
	}

	.real-estate-slider-container {
		max-width: 990px;
		width: 100%;
		margin: 0 auto;
		box-sizing: content-box;

		.home-page-promo-slideshow {
			margin: 0;
		}

		h1 {
			max-width: 930px;
			margin: 0 auto 27px;
		}

		.image-copy-content-section {
			width: 100%;
			margin: 0;
			padding: 0;
			display: block;
			max-width: none;
			@media all and ( min-width: $breakpoint-medium ) {
				margin: 0 0 25px;
			}

			.real-estate-page-promo-slideshow-column {
				width: 100%;
				margin: 0;
				padding: 0;

				ul.uk-slideshow-items {
					box-shadow: none;

					&::before {
						content: "";
						display: none;
					}

					img {
						height: 100%;
						object-fit: cover;
					}
				}

				.slideshow-status-control-bar {
					margin-top: 15px;
					@media all and ( min-width: $breakpoint-medium ) {
						margin-top: 26px;
					}

					.slideshow-current-slide-status {
						display: none;
						@media all and ( min-width: $breakpoint-xsmall ) {
							display: block;
						}
					}

					.slider-link-container {
						position: absolute;
						bottom: 0;
						left: 0;
						@media all and ( min-width: $breakpoint-medium ) {
							bottom: -10px;
						}

						a {
							font-size: 20px;
							font-weight: 500;
							text-transform: none;
							@media all and ( min-width: 370px ) {
								min-width: 206px;

								&::after {
									right: 35px;
								}
							}
							
							@media all and ( min-width: $breakpoint-medium ) {
								&::after {
									right: 30px;
								}
							}
						}
					}

					.slide-controls-wrap {
						a {
							display: flex;
							align-items: center;

							&.previous-btn {
								padding: 11px 20px;
								@media all and ( min-width: $breakpoint-medium ) {
									padding: 0 11px 0 15px;
								}
							}

							&.next-btn {
								padding: 11px 20px;
								@media all and ( min-width: $breakpoint-medium ) {
									padding: 0 15px 0 11px;
								}
							}

							&:active,
							&:focus,
							&:hover {
								text-decoration: none;
							}
						}
					}
				}
			}
		}
	}

	.contact-copy-column,
	.contact-form-column {
		@extend .uk-width-4-5\@sm;
		@extend .uk-width-1-2\@lg;
		@extend .uk-width-2-5\@xl;
	}

	.contact-form-column {
		@extend .uk-width-3-5\@ms;
		max-width: 990px;
    margin: 40px auto 113px;
		width: 100%;
		box-sizing: border-box;
		padding: 29px 15px 22px;
		border: solid 4px $brand-yellow;
		@media all and ( min-width: $breakpoint-small ) {
			padding: 29px 28px 22px 29px;
		}

		form {
			position: relative;

			legend.gfieldset-legend {
				display: none;
			}

			.gfieldset {
				ul.gform_fields {
					column-count: 1;
					position: relative;
					right: 0;
					bottom: 0;
					max-width: none;
					@media all and ( min-width: $breakpoint-medium ) {
						column-count: 2;
					}

					input {
						max-width: 450px;
					}
					
					label {
						font-size: 18px;
						@media all and ( min-width: $breakpoint-xsmall ) {
							font-size: 20px;
						}
					}

					.gfield.has-focus-or-value>label {
						font-size: .8em;
					}

					.gfield_radio {
						display: flex;

						.gchoice {
							margin-right: 20px;
						}
					}
				}
			}

			ul.gform_fields {
				position: relative;
				max-width: 450px;
				right: none;
				bottom: none;
				z-index: 2;
				@media all and ( min-width: $breakpoint-medium ) {
					position: absolute;
					right: 200px;
					bottom: 10px;
				}

				small {
					font-size: 14px;
					font-style: italic;
					line-height: 1.57;
					margin-top: 20px;
					display: block;
				}
			}

			div.gform_footer {
				margin: 0;
				width: 100%;
				text-align: right;

				button {
					max-width: 162px;
					width: 100%;

					&::after {
						right: 45px;
					}
				}
			}
		}
	}

	.real-estate-page-btns-container {
		max-width: 730px;
		margin: 0 auto 15px;

		.other-contact-page-btns {
			justify-content: center;
		}

		.image-button-cta {
			position: relative;
			margin-top: 60px;
			@media all and ( min-width: $breakpoint-medium ) {
				margin-top: 0;
				padding-left: 80px;
			}

			img {
				max-height: 219px;
				max-width: 329px;
				object-fit: cover;
			}

			a {
				position: absolute;
				right: 5px;
				bottom: -20px;
				margin: 0;
				padding-left: 26px;
				@media all and ( min-width: $breakpoint-small ) {
					right: -20px;
				}
			}

			&:last-of-type a {
				right: 5px;
			}
		}
	}

	.gform_confirmation_wrapper {
		background-position: bottom center;
		margin-top: 10px;
		padding-bottom: 210px;

		h3 {
			margin-bottom: 7px;
		}
	}

	.real-estate-container {
		max-width: 1030px;
		margin: 0 auto;
	}

	.real-estate-intro-content-container {
		margin-bottom: 40px;
		@media all and ( min-width: $breakpoint-medium ) {
			margin-bottom: 35px;
		}

		p {
			font-size: 25px;
			font-weight: bold;
			line-height: normal;
			color: $gray-400;
			@media all and ( min-width: $breakpoint-medium ) {
				font-size: 30px;
			}

			b, strong {
				color: $brand-blue;
			}
		}
	}

	.real-estate-stats-outer-container {
		padding: 0 15px 40px;
		@media all and ( min-width: $breakpoint-medium ) {
			padding: 0 15px;
		}

		.real-estate-stats-container {
			max-width: 1070px;
			margin: 0 auto;

			.real-estate-stat-card-container {
				text-align: center;
				margin-bottom: 5px;
				@media all and ( min-width: $breakpoint-medium ) {
					margin-bottom: 70px;
				}

				img {
					width: 100px;
					height: 100px;
					margin: 0 0 3px;
					@media all and ( min-width: $breakpoint-medium ) {
						width: 120px;
						height: 120px;
						margin: 0 0 10px;
					}
				}

				p {
					font-size: 16px;
					line-height: normal;
					color: $gray-400;
					max-width: 300px;
					margin: 0 auto;
					@media all and ( min-width: $breakpoint-medium ) {
						font-size: 23px;
						line-height: 1.35;				
					}

					span {
						font-size: 30px;
						display: block;
						font-weight: 900;
						line-height: 1.87;
						color: $gray-300;
						margin: 0;
						@media all and ( min-width: $breakpoint-medium ) {
							font-size: 50px;					
							line-height: 1.12;
							margin: 0 0 5px;
						}
					}
				}
			}
		}
	}

	.real-estate-orange-advantage-container {
		background: $brand-orange;
		padding: 40px 0;
		margin: 0 0 40px;
		@media all and ( min-width: $breakpoint-medium ) {
			padding: 68px 0 58px;
			margin: 0 0 70px;
		}

		h2 {			
			font-size: 25px;
			font-weight: bold;
			color: $global-background;
			margin: 0 0 20px;
			line-height: normal;
			text-transform: none;
			max-width: 950px;
			@media all and ( min-width: $breakpoint-medium ) {
				font-size: 30px;
				margin: 0 0 9px;
			}
		}

		ul {
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				font-size: 16px;
				line-height: normal;
				color: $global-background;
				margin: 0;
				position: relative;
				margin-left: 20px;
				@media all and ( min-width: $breakpoint-medium ) {
					font-size: 20px;
					line-height: 2.05;
				}

				&::before {
					content: "";
					min-width: 9px;
					min-height: 9px;
					background-color: $brand-yellow;
					position: absolute;
					display: block;
					left: -19px;
					top: 7px;
					@media all and ( min-width: $breakpoint-medium ) {
						top: 16px;
					}
				}
			}
		}
	}

	.real-estate-criteria-container {
		h2 {
			font-size: 32px;
			font-weight: 900;
			line-height: 1.53;
			color: $gray-400;
			margin: 0;
			@media all and ( min-width: $breakpoint-medium ) {
				font-size: 40px;
				line-height: 1.23;
				margin: 0 0 20px;
			}
		}

		h3 {
			font-size: 20px;
			font-weight: bold;
			line-height: 1.6;
			color: $gray-400;
			margin: 0 0 7px;
			text-transform: none;
		}

		ul {
			padding: 0;
			margin: 0 0 19px;
			list-style: none;

			li {
				font-size: 20px;
				line-height: 1.6;
				color: $gray-400;
				margin: 0;
				position: relative;
				margin-left: 20px;

				&::before {
					content: "";
					min-width: 9px;
					min-height: 9px;
					background-color: $brand-yellow;
					position: absolute;
					display: block;
					left: -19px;
					top: 10px;
				}
			}
		}

		.criteria-container {
			margin-bottom: 30px;
			@media all and ( min-width: $breakpoint-medium ) {
				margin-bottom: 16px;
			}

			.right-column {
				margin-top: -9px;
			}
		}

		.trade-area-header {
			margin: 0 0 20px;
			max-width: 400px;
			@media all and ( min-width: $breakpoint-large ) {
				max-width: none;
			}
		}

		.trade-area-container {
			margin-bottom: 40px;
			@media all and ( min-width: $breakpoint-medium ) {
				margin-bottom: 65px;
			}

			.image-button-cta {
				position: relative;
				margin-top: 15px;
				@media all and ( min-width: $breakpoint-medium ) {
					margin-top: -40px;
				}

				&::before {
					content: "";
					width: 196px;
					height: 168px;
					border-top: 5px solid $brand-yellow;
					border-left: 5px solid $brand-yellow;
					display: block;
					position: absolute;
					@media all and ( min-width: $breakpoint-medium ) {
						width: 283px;
						height: 243px;
					}
				}

				img {				
					padding: 16px 29px 0 16px;
					max-width: 453px;
					width: 100%;
					@media all and ( min-width: $breakpoint-medium ) {
						padding: 24px 0 0 24px;
					}
				}

				a {
					float: right;
					margin-right: 0px;
					padding: 12px 40px 12px 31px;
					margin-top: -20px;

					&::after {
						right: 24px;
					}
				}
			}
		}
	}

	.real-estate-searchbox-outer-container {
		padding: 0 15px;

		.real-estate-searchbox-container {
			max-width: 1030px; 
			margin: 0 auto 10px;
			padding: 20px 20px 40px;
			box-shadow: 0 0 7px 0 rgba( 0, 0, 0, 0.25 );
			@media all and ( min-width: $breakpoint-medium ) {
				margin: 0 auto 30px;
				padding: 44px 27px 42px;
			}

			.content-container {
				max-width: 440px;
				margin-bottom: 15px;
				@media all and ( min-width: $breakpoint-medium ) {
					margin-bottom: 10px;
				}

				img {
					margin-bottom: 7px;
					width: 30px;
					@media all and ( min-width: $breakpoint-medium ) {
						width: 50px;
						margin-bottom: 15px;
					}
				}

				p {
					font-size: 25px;
					font-weight: bold;
					line-height: normal;
					text-align: left;
					color: $gray-400;
					margin: 0 0 20px;
					@media all and ( min-width: $breakpoint-medium ) {
						font-size: 30px;
						margin: 0 0 27px;
					}

					b, strong {
						color: $brand-blue;
					}
				}

				.cta-container {
					display: flex;
					
					a.uk-button {
						width: 50%;
						margin-right: 16px;

						&::after {
							@media all and ( min-width: $breakpoint-small ) {
								right: 45px;
							}

							@media all and ( min-width: $breakpoint-medium ) {
								right: inherit;
							}
							
							@media all and ( min-width: $breakpoint-large ) {
								right: 45px;
							}							
						}

						&.uk-button-default::after {
							@media all and ( min-width: $breakpoint-small ) {
								right: 50px;
							}

							@media all and ( min-width: $breakpoint-medium ) {
								right: inherit;
							}
							
							@media all and ( min-width: $breakpoint-large ) {
								right: 50px;
							}	
						}
					}
				}
			}

			.list-container {
				columns: 2;
				margin: 0;
				padding: 0;
				list-style: none;
				@media all and ( min-width: $breakpoint-medium-small ) {
					columns: 3;
				}
				@media all and ( min-width: $breakpoint-medium ) {
					columns: 2;
				}

				li {
					font-size: 16px;
					line-height: 2;
					color: $gray-400;
					@media all and ( min-width: $breakpoint-medium ) {
						font-size: 20px;
						line-height: 1.6;
					}
				}
			}
		}
	}

	.real-estate-accordion-container {
		.accordion-title {
			margin: 30px 0 15px;
			font-size: 32px;
			line-height: 1.53;
			@media all and ( min-width: $breakpoint-medium ) {
				margin: 40px 0 30px;
				font-size: 40px;
			}
		}

		ul {
			list-style: none;

			li { 
				box-shadow: 0 0 7px 0 rgba( 0, 0, 0, 0.25 );
				margin: 0 0 15px;
				position: relative;
				@media all and ( min-width: $breakpoint-medium ) {
					margin: 0 0 30px;
				}

				&.uk-open {
					.faq-question {
						&::after {
							transform: rotate( 180deg );
						}
					}
				}
		
				h3.faq-question {
					font-size: 24px;
					line-height: 1.33;
					font-family: $global-font-family;
					font-weight: bold;			
					color: $gray-400;
					padding: 25px 50px 25px 12px;
					text-transform: none;
					@media all and ( min-width: $breakpoint-medium ) {
						line-height: 1.23;
						padding: 30px 85px 26px 30px;
						font-size: 26px;
					}

					&:active,
					&:focus,
					&:hover {
						color: $gray-300;
					}

					&::after {
						content: "\f078";
						font-family: $font-awesome-font-family;
						position: absolute;
						right: 12px;
						top: 25px;
						font-size: 24px;
						line-height: 1.33;
						color: $gray-400;
						transition: all .3s;
						@media all and ( min-width: $breakpoint-medium ) {
							right: 30px;
							top: 30px;
							font-size: 26px;
  						line-height: 1.23;
						}
					}
				}

				.faq-answer {
					font-family: $global-font-family;
					font-size: 18px;
					line-height: 26px;
					color: $gray-400;
					padding: 0 12px 25px;
					margin-top: 0;
					@media all and ( min-width: $breakpoint-medium ) {
						font-size: 23px;
						line-height: 1.26;
						padding: 0 30px 26px;
					}
				}
			}
		}
	}

	.real-estate-resources-outer-container {
		background: $brand-orange;
		margin: 30px 0 -20px;
		@media all and ( min-width: $breakpoint-medium ) {
			margin: 40px 0 102px;
		}

		.real-estate-resources-container {
			padding: 40px 0;
			@media all and ( min-width: $breakpoint-medium ) {
				padding: 90px 0 80px;
			}

			.resource-content {
				font-size: 25px;
				font-weight: bold;
				line-height: normal;
				color: $global-background;
				margin: 0 0 40px;
				@media all and ( min-width: $breakpoint-medium ) {
					font-size: 30px;
					margin: 0 0 43px;
				}
			}

			.cta-container {
				display: flex;
				
				a {
					width: 50%;
					margin-right: 16px;
					@media all and ( min-width: $breakpoint-medium ) {
						max-width: 206px;
						width: 100%;
					}

					&.uk-button-primary {
						background: $global-background;
						border-color: $global-background;
						color: $brand-orange;
					}

					&.uk-button-default {
						background: $brand-orange;
						border-color: $global-background;
						color: $global-background;
						margin-right: 0;
					}
				}
			}

			.resource-list-container {
				margin: 40px 0 0;
				@media all and ( min-width: $breakpoint-medium ) {
					margin: 0;
				}

				h3 {
					font-size: 25px;
					font-weight: bold;
					line-height: normal;
					color: #ffcf01;
					margin: 0 0 1px;
					@media all and ( min-width: $breakpoint-medium ) {
						font-size: 30px;
						margin: 0 0 10px;
					}
				}

				ul {
					padding: 0;
					margin: 0;
					list-style: none;

					li,
					li a {
						font-size: 18px;
						font-weight: bold;
						line-height: 1.78;
						color: $global-background;
						@media all and ( min-width: $breakpoint-medium ) {
							font-size: 20px;
							line-height: 1.6;
						}
					}

					a {
						display: inline-block;
						text-decoration: underline;

						&:active,
						&:focus,
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}

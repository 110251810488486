$width-xxlarge-width: 1400px;

@mixin hook-width-misc {
	.uk-child-width-1-1 > * {
		width: 100%;
	}

	.uk-width-1-1 {
		width: 100%;
	}

	@include media-breakpoint-up(xs) {

		.uk-child-width-1-1\@xs > * { width: 100%; }
		.uk-child-width-1-2\@xs > * { width: 50%; }
		.uk-child-width-1-3\@xs > * { width: percentage(1 / 3); }
		.uk-child-width-1-4\@xs > * { width: 25%; }
		.uk-child-width-1-5\@xs > * { width: 20%; }
		.uk-child-width-1-6\@xs > * { width: percentage(1 / 6); }

		.uk-child-width-auto\@xs > * { width: auto; }
		.uk-child-width-expand\@xs > :not([class*='uk-width']) {
			flex: 1;
			min-width: 1px;
		}


		/* Whole */
		.uk-width-1-1\@xs { width: 100%; }

		/* Halves */
		.uk-width-1-2\@xs { width: 50%; }

		/* Thirds */
		.uk-width-1-3\@xs { width: percentage(1 / 3); }
		.uk-width-2-3\@xs { width: percentage(2 / 3); }

		/* Quarters */
		.uk-width-1-4\@xs { width: 25%; }
		.uk-width-3-4\@xs { width: 75%; }

		/* Fifths */
		.uk-width-1-5\@xs { width: 20%; }
		.uk-width-2-5\@xs { width: 40%; }
		.uk-width-3-5\@xs { width: 60%; }
		.uk-width-4-5\@xs { width: 80%; }

		/* Sixths */
		.uk-width-1-6\@xs { width: percentage(1 / 6); }
		.uk-width-5-6\@xs { width: percentage(5 / 6); }

		/* Pixel */
		.uk-width-small\@xs { width: $width-small-width; }
		.uk-width-medium\@xs { width: $width-medium-width; }
		.uk-width-large\@xs { width: $width-large-width; }
		.uk-width-xlarge\@xs { width: $width-xlarge-width; }
		.uk-width-xxlarge\@xs { width: $width-xxlarge-width; }

		/* Auto */
		.uk-width-auto\@xs { width: auto; }

		/* Expand */
		.uk-width-expand\@xs {
			width: 1px;
			flex: 1;
			min-width: 0;
			flex-basis: 1px;
		}
	}

	@include media-breakpoint-up(sm) {

		.uk-child-width-1-1\@sm > * { width: 100%; }
		.uk-child-width-1-2\@sm > * { width: 50%; }
		.uk-child-width-1-3\@sm > * { width: percentage(1 / 3); }
		.uk-child-width-1-4\@sm > * { width: 25%; }
		.uk-child-width-1-5\@sm > * { width: 20%; }
		.uk-child-width-1-6\@sm > * { width: percentage(1 / 6); }

		.uk-child-width-auto\@sm > * { width: auto; }
		.uk-child-width-expand\@sm > :not([class*='uk-width']) {
			flex: 1;
			min-width: 1px;
		}


		/* Whole */
		.uk-width-1-1\@sm { width: 100%; }

		/* Halves */
		.uk-width-1-2\@sm { width: 50%; }

		/* Thirds */
		.uk-width-1-3\@sm { width: unquote('calc(100% * 1 / 3.001)'); }
		.uk-width-2-3\@sm { width: unquote('calc(100% * 2 / 3.001)'); }

		/* Quarters */
		.uk-width-1-4\@sm { width: 25%; }
		.uk-width-3-4\@sm { width: 75%; }

		/* Fifths */
		.uk-width-1-5\@sm { width: 20%; }
		.uk-width-2-5\@sm { width: 40%; }
		.uk-width-3-5\@sm { width: 60%; }
		.uk-width-4-5\@sm { width: 80%; }

		/* Sixths */
		.uk-width-1-6\@sm { width: unquote('calc(100% * 1 / 6.001)'); }
		.uk-width-5-6\@sm { width: unquote('calc(100% * 5 / 6.001)'); }

		/* Pixel */
		.uk-width-small\@sm { width: $width-small-width; }
		.uk-width-medium\@sm { width: $width-medium-width; }
		.uk-width-large\@sm { width: $width-large-width; }
		.uk-width-xlarge\@sm { width: $width-xlarge-width; }
		.uk-width-xxlarge\@sm { width: $width-xxlarge-width; }

		/* Auto */
		.uk-width-auto\@sm { width: auto; }

		/* Expand */
		.uk-width-expand\@sm {
			width: 1px;
			flex: 1;
			min-width: 0;
			flex-basis: 1px;
		}

	}

	@include media-breakpoint-up(ms) {

		.uk-child-width-1-1\@ms > * { width: 100%; }
		.uk-child-width-1-2\@ms > * { width: 50%; }
		.uk-child-width-1-3\@ms > * { width: percentage(1 / 3); }
		.uk-child-width-1-4\@ms > * { width: 25%; }
		.uk-child-width-1-5\@ms > * { width: 20%; }
		.uk-child-width-1-6\@ms > * { width: percentage(1 / 6); }

		.uk-child-width-auto\@ms > * { width: auto; }
		.uk-child-width-expand\@ms > :not([class*='uk-width']) {
			flex: 1;
			min-width: 1px;
		}


		/* Whole */
		.uk-width-1-1\@ms { width: 100%; }

		/* Halves */
		.uk-width-1-2\@ms { width: 50%; }

		/* Thirds */
		.uk-width-1-3\@ms { width: percentage(1 / 3); }
		.uk-width-2-3\@ms { width: percentage(2 / 3); }

		/* Quarters */
		.uk-width-1-4\@ms { width: 25%; }
		.uk-width-3-4\@ms { width: 75%; }

		/* Fifths */
		.uk-width-1-5\@ms { width: 20%; }
		.uk-width-2-5\@ms { width: 40%; }
		.uk-width-3-5\@ms { width: 60%; }
		.uk-width-4-5\@ms { width: 80%; }

		/* Sixths */
		.uk-width-1-6\@ms { width: percentage(1 / 6); }
		.uk-width-5-6\@ms { width: percentage(5 / 6); }

		/* Pixel */
		.uk-width-small\@ms { width: $width-small-width; }
		.uk-width-medium\@ms { width: $width-medium-width; }
		.uk-width-large\@ms { width: $width-large-width; }
		.uk-width-xlarge\@ms { width: $width-xlarge-width; }
		.uk-width-xxlarge\@ms { width: $width-xxlarge-width; }

		/* Auto */
		.uk-width-auto\@ms { width: auto; }

		/* Expand */
		.uk-width-expand\@ms {
			width: 1px;
			flex: 1;
			min-width: 0;
			flex-basis: 1px;
		}
	}

	@include media-breakpoint-up(md) {

		.uk-child-width-1-1\@md > * { width: 100%; }
		.uk-child-width-1-2\@md > * { width: 50%; }
		.uk-child-width-1-3\@md > * { width: percentage(1 / 3); }
		.uk-child-width-1-4\@md > * { width: 25%; }
		.uk-child-width-1-5\@md > * { width: 20%; }
		.uk-child-width-1-6\@md > * { width: percentage(1 / 6); }

		.uk-child-width-auto\@md > * { width: auto; }
		.uk-child-width-expand\@md > :not([class*='uk-width']) {
			flex: 1;
			min-width: 1px;
		}


		/* Whole */
		.uk-width-1-1\@md { width: 100%; }

		/* Halves */
		.uk-width-1-2\@md { width: 50%; }

		/* Thirds */
		.uk-width-1-3\@md { width: unquote('calc(100% * 1 / 3.001)'); }
		.uk-width-2-3\@md { width: unquote('calc(100% * 2 / 3.001)'); }

		/* Quarters */
		.uk-width-1-4\@md { width: 25%; }
		.uk-width-3-4\@md { width: 75%; }

		/* Fifths */
		.uk-width-1-5\@md { width: 20%; }
		.uk-width-2-5\@md { width: 40%; }
		.uk-width-3-5\@md { width: 60%; }
		.uk-width-4-5\@md { width: 80%; }

		/* Sixths */
		.uk-width-1-6\@md { width: unquote('calc(100% * 1 / 6.001)'); }
		.uk-width-5-6\@md { width: unquote('calc(100% * 5 / 6.001)'); }

		/* Pixel */
		.uk-width-small\@md { width: $width-small-width; }
		.uk-width-medium\@md { width: $width-medium-width; }
		.uk-width-large\@md { width: $width-large-width; }
		.uk-width-xlarge\@md { width: $width-xlarge-width; }
		.uk-width-xxlarge\@md { width: $width-xxlarge-width; }

		/* Auto */
		.uk-width-auto\@md { width: auto; }

		/* Expand */
		.uk-width-expand\@md {
			width: 1px;
			flex: 1;
			min-width: 0;
			flex-basis: 1px;
		}

	}

	@include media-breakpoint-up(lg) {

		.uk-child-width-1-1\@lg > * { width: 100%; }
		.uk-child-width-1-2\@lg > * { width: 50%; }
		.uk-child-width-1-3\@lg > * { width: percentage(1 / 3); }
		.uk-child-width-1-4\@lg > * { width: 25%; }
		.uk-child-width-1-5\@lg > * { width: 20%; }
		.uk-child-width-1-6\@lg > * { width: percentage(1 / 6); }

		.uk-child-width-auto\@lg > * { width: auto; }
		.uk-child-width-expand\@lg > :not([class*='uk-width']) {
			flex: 1;
			min-width: 1px;
		}


		/* Whole */
		.uk-width-1-1\@lg { width: 100%; }

		/* Halves */
		.uk-width-1-2\@lg { width: 50%; }

		/* Thirds */
		.uk-width-1-3\@lg { width: unquote('calc(100% * 1 / 3.001)'); }
		.uk-width-2-3\@lg { width: unquote('calc(100% * 2 / 3.001)'); }

		/* Quarters */
		.uk-width-1-4\@lg { width: 25%; }
		.uk-width-3-4\@lg { width: 75%; }

		/* Fifths */
		.uk-width-1-5\@lg { width: 20%; }
		.uk-width-2-5\@lg { width: 40%; }
		.uk-width-3-5\@lg { width: 60%; }
		.uk-width-4-5\@lg { width: 80%; }

		/* Sixths */
		.uk-width-1-6\@lg { width: unquote('calc(100% * 1 / 6.001)'); }
		.uk-width-5-6\@lg { width: unquote('calc(100% * 5 / 6.001)'); }

		/* Pixel */
		.uk-width-small\@lg { width: $width-small-width; }
		.uk-width-medium\@lg { width: $width-medium-width; }
		.uk-width-large\@lg { width: $width-large-width; }
		.uk-width-xlarge\@lg { width: $width-xlarge-width; }
		.uk-width-xxlarge\@lg { width: $width-xxlarge-width; }

		/* Auto */
		.uk-width-auto\@lg { width: auto; }

		/* Expand */
		.uk-width-expand\@lg {
			width: 1px;
			flex: 1;
			min-width: 0;
			flex-basis: 1px;
		}

	}

	@include media-breakpoint-up(xl) {

		.uk-child-width-1-1\@xl > * { width: 100%; }
		.uk-child-width-1-2\@xl > * { width: 50%; }
		.uk-child-width-1-3\@xl > * { width: percentage(1 / 3); }
		.uk-child-width-1-4\@xl > * { width: 25%; }
		.uk-child-width-1-5\@xl > * { width: 20%; }
		.uk-child-width-1-6\@xl > * { width: percentage(1 / 6); }

		.uk-child-width-auto\@xl > * { width: auto; }
		.uk-child-width-expand\@xl > :not([class*='uk-width']) {
			flex: 1;
			min-width: 1px;
		}


		/* Whole */
		.uk-width-1-1\@xl { width: 100%; }

		/* Halves */
		.uk-width-1-2\@xl { width: 50%; }

		/* Thirds */
		.uk-width-1-3\@xl { width: unquote('calc(100% * 1 / 3.001)'); }
		.uk-width-2-3\@xl { width: unquote('calc(100% * 2 / 3.001)'); }

		/* Quarters */
		.uk-width-1-4\@xl { width: 25%; }
		.uk-width-3-4\@xl { width: 75%; }

		/* Fifths */
		.uk-width-1-5\@xl { width: 20%; }
		.uk-width-2-5\@xl { width: 40%; }
		.uk-width-3-5\@xl { width: 60%; }
		.uk-width-4-5\@xl { width: 80%; }

		/* Sixths */
		.uk-width-1-6\@xl { width: unquote('calc(100% * 1 / 6.001)'); }
		.uk-width-5-6\@xl { width: unquote('calc(100% * 5 / 6.001)'); }

		/* Pixel */
		.uk-width-small\@xl { width: $width-small-width; }
		.uk-width-medium\@xl { width: $width-medium-width; }
		.uk-width-large\@xl { width: $width-large-width; }
		.uk-width-xlarge\@xl { width: $width-xlarge-width; }
		.uk-width-xxlarge\@xl { width: $width-xxlarge-width; }

		/* Auto */
		.uk-width-auto\@xl { width: auto; }

		/* Expand */
		.uk-width-expand\@xl {
			width: 1px;
			flex: 1;
			min-width: 0;
			flex-basis: 1px;
		}

	}
}

.content-contact {
	.contact-copy-column,
	.contact-form-column {
		@extend .uk-width-4-5\@sm;
		@extend .uk-width-1-2\@lg;
		@extend .uk-width-2-5\@xl;
	}

	.contact-form-column {
		@extend .uk-width-3-5\@ms;

		margin-top: 60px;
	}

	.other-contact-page-btns {
		@extend .uk-child-width-1-2\@l;

		margin-top: 40px;
	}

	.gform_wrapper {
		.gfield.gfield_checkbox .gfield_required {
			display: none;
		}
	}

	.gform_confirmation_wrapper {
		background-position: bottom center;
		margin-top: 10px;
		padding-bottom: 210px;

		h3 {
			margin-bottom: 7px;
		}
	}

	@include media-breakpoint-up(sm) {
		.contact-copy-column,
		.contact-form-column {
			@include make-uk-width-offset(1);
		}
	}

	@include media-breakpoint-between(ms, md) {
		.contact-form-column {
			@include make-uk-width-offset(2);
		}
	}

	@include media-breakpoint-up(lg) {
		.contact-copy-column,
		.contact-form-column {
			margin-left: 0;
		}
	}

	@include media-breakpoint-up(xl) {
		.contact-copy-column {
			@include make-uk-width-offset(1);
		}
	}
}

.content-wrap {
	overflow: hidden;
	padding-top: 40px;
	padding-bottom: 90px;
}

.social-account-icons {
	line-height: 1.2;

	ul {
		margin-left: ($iconnav-margin-horizontal + $iconnav-padding-horizontal) * -1;

		& > li > a {
			transition: all 0.2s ease,
						opacity 0.1s ease;
			padding: 0 $iconnav-padding-horizontal;

			&:hover {
				/*margin-top: $icon-hover-offset;
				padding-bottom: ($icon-hover-offset * -1);
				text-shadow: 0 7px 4px rgba(0, 0, 0, 0.5);*/
				opacity: 0.6;
			}
		}
	}
}

.post-sharing-icons {
	text-align: center;

	.uk-iconnav {
		font-size: 1.1rem;
		line-height: 2;

		& > li {
			& > a {
				padding: 0 20px;

				&:hover {
					color: $global-inverse-color;
				}
			}
		}
	}

	.iconnav-label {
		font-size: $global-small-font-size;
		font-weight: $font-weight-medium;
		line-height: 2.4;
		margin-bottom: 3px;
	}

	.social-icon-facebook {
		color: $brand-social-facebook;

		&:hover {
			background-color: $brand-social-facebook;
		}
	}

	.social-icon-twitter {
		color: $brand-social-twitter;

		&:hover {
			background-color: $brand-social-twitter;
		}
	}

	.social-icon-pinterest {
		color: $brand-social-pinterest;

		&:hover {
			background-color: $brand-social-pinterest;
		}
	}

	.social-icon-linkedin {
		color: $brand-social-linkedin;

		&:hover {
			background-color: $brand-social-linkedin;
		}
	}

	.social-icon-email {
		color: $gray-700;

		&:hover {
			background-color: $gray-700;
		}
	}
}

.image-copy-content-section {
	.uk-grid-margin {
		margin-top: 0;
	}

	.section-title,
	.copy-column {
		@include media-breakpoint-up(sm) {
			@include make-uk-width-offset(1);
		}

		@include media-breakpoint-only(lg) {
			margin-left: 0;
		}
	}

	.section-title {
		margin-bottom: 18px;

		h3 {
			@include h2-heading-highlight;
		}

		@include media-breakpoint-up(sm) {
			width: 90%;
		}

		@include media-breakpoint-up(lg) {
			& ~ .image-column {
				margin-top: 0;
			}
		}
	}

	.copy-column,
	.image-column {
		@extend .uk-width-4-5\@sm;
	}

	.copy-column {
		@extend .uk-width-1-2\@lg;
		@extend .uk-width-1-2\@xl;

		span,
		small,
		.h2-heading-highlight,
		& > h3:first-child {
			@include h2-heading-highlight;
		}

		& + .image-column:not(.slideshow-column) {
			& > img {
				float: none;
			}

			@include media-breakpoint-down(md) {
				margin-top: 30px;
			}

			@include media-breakpoint-up(lg) {
				padding-right: 0;
				padding-left: $global-gutter + 30px;
			}

			@include media-breakpoint-only(lg) {
				width: percentage(1 / 2);
			}
		}

		p + .uk-button {
			@include media-breakpoint-up(lg) {
				margin-top: 8px;
			}
		}

		ul {
			@include colored-bullet-list;
		}

		@include media-breakpoint-down(xs){
			padding-left: 20px;
			padding-right: 20px - $global-small-gutter;
		}
	}

	.image-column {
		@extend .uk-width-2-5\@lg;

		position: relative;

		& > img {
			& + img {
				margin-top: 30px;
			}
		}

		@include media-breakpoint-down(md) {
			text-align: center;
			margin-right: auto;
			margin-left: auto;

			& + .copy-column {
				margin-top: 30px;
			}
		}

		@include media-breakpoint-up(md) {
			& > img {
				max-width: none;
			}
		}

		@include media-breakpoint-up(lg) {
			margin-top: 45px;

			&:not(.slideshow-column) {
				padding-right: 30px;
			}

			& > img {
				float: right;

				& + img {
					margin-top: 50px;
				}
			}

			& + .copy-column {
				margin-left: 0;
			}
		}

		@include media-breakpoint-only(lg) {
			&:first-child {
				@include make-uk-width-offset(1);
			}
		}
	}
}

.home-page-promo-slideshow-content-wrap {
	@include make-uk-child-width-ready;

	@extend .uk-child-width-expand\@xl;
	@extend .uk-flex-middle;

	position: relative;

	.home-page-promo-slideshow-column {
		@extend .uk-width-4-5\@md;
		@extend .uk-width-2-3\@lg;
		@extend .uk-width-1-2\@xl;

		background-color: $global-background;
		z-index: 2;

		@include media-breakpoint-up(md) {
			@include make-uk-width-offset(1);
		}

		@include media-breakpoint-up(xl) {
			@include make-uk-width-ready;

			margin-left: $grid-gutter-horizontal;
			padding-left: 0;
		}

		.home-page-promo-slideshow {
			@include media-breakpoint-down(lg) {
				margin-left: $home-page-promo-slideshow-border-h-offset;
			}

			@include media-breakpoint-down(ms) {
				margin-right: 55px;
				margin-left: $home-page-promo-slideshow-border-h-offset + 55px;
			}

			@include media-breakpoint-down(sm) {
				margin-right: 25px;
				margin-left: $home-page-promo-slideshow-border-h-offset + 25px;
			}

			@media (max-width: 549px) {
				margin-right: 0;
				margin-left: $home-page-promo-slideshow-border-h-offset;
			}
		}
	}

	.home-page-promo-copy-column {
		z-index: 1;

		@include media-breakpoint-down(lg) {
			margin-top: 15px;
		}

		@include media-breakpoint-down(md) {
			margin-top: 10px;
		}

		@include media-breakpoint-only(lg) {
			@include make-uk-width-offset(1);

			width: percentage(4 / 5);
		}

		@include media-breakpoint-up(xl) {
			margin-left: 0;
			padding-left: 90px;
		}

		.slideshow-copy-holder {
			@extend .uk-flex;
			@extend .uk-flex-top;
			@extend .uk-flex-middle\@xl;

			position: relative;
			height: 100%;
		}

		.slideshow-copy-slide {
			transition: margin 0.6s, opacity 0.6s;
			width: 100%;

			&.next,
			&.prev {
				opacity: 0
			}

			&.next.right,
			&.prev.left {
				margin-right: -100%;
			}

			&.prev.right,
			&.next.left {
				margin-left: -100%;
			}

			& > h3:first-child {
				@include h2-heading-highlight;
			}

			h2 > small,
			& > h3:first-child {
				color: $brand-orange;
			}

			span,
			small,
			.h2-heading-highlight {
				@include h2-heading-highlight;
				color: $brand-orange;
			}
		}
	}
}

.image-button-cta {
	font-size: 0;

	& > img {
		display: block;
	}

	& > a,
	& > button {
		display: inline-block;
		margin-top: -15px;
		margin-left: 15px;
	}
}

.team-members-grid {
	@extend .uk-child-width-1-2\@xs;
	@extend .uk-child-width-1-3\@ms;
	@extend .uk-child-width-1-4\@xl;

	& > * {
		max-width: 170px + $team-member-card-border-offset + (40px - $team-member-card-border-offset) + $global-small-gutter;
	}

	@media (max-width: 499px) {
		justify-content: center;
	}

	@media (min-width: $breakpoint-xsmall) and (max-width: 499px) {
		& > * {
			width: auto;
		}
	}

	@include media-breakpoint-between(xs, sm) {
		justify-content: space-around;
	}

	@include media-breakpoint-up(md) {
		& > * {
			max-width: 170px + $team-member-card-border-offset + (40px - $team-member-card-border-offset) + $global-gutter;
		}
	}
}

.team-member-photo {
	position: relative;
	padding-right: $team-member-card-border-offset;
	padding-bottom: $team-member-card-border-offset;

	&:before {
		content: "";
		border: 4px solid $brand-yellow;
		position: absolute;
		top: $team-member-card-border-offset;
		right: 0;
		bottom: 0;
		left: $team-member-card-border-offset;
		z-index: -1;
	}
}

.entry-content-asset {
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: -20px;
	}
}

.embed-responsive-16by9 {
	position: relative;
	padding-top: percentage(9 / 16);
	width: 100%;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.did-you-know-banner {
	background-color: #4D555D;
	background-image: url("../images/did-you-know-bg.jpg");
	background-repeat: no-repeat;
	background-position: top left;
	padding: 35px 0;

	.did-you-know-banner-copy {
		@extend .uk-width-3-5\@lg;

		color: $global-inverse-color;
		font-weight: $font-weight-medium;
		line-height: normal;

		a {
			color: $brand-yellow;
			display: inline-block;
			position: relative;
			padding-right: $global-small-font-size;

			&:after {
				@include make-fa-icon;

				content: fa-content($fa-var-angle-right);
				text-align: center;
				line-height: 1.42;
				position: absolute;
				right: 0;
				width: $global-small-font-size;
			}
		}
	}

	@include media-breakpoint-down(ms) {
		padding-top: 170px;

		.did-you-know-banner-copy {
			padding: 0 $global-gutter;
		}
	}

	@include media-breakpoint-only(md) {
		.did-you-know-banner-copy {
			width: 55%;
		}
	}

	@include media-breakpoint-up(md) {
		.did-you-know-banner-copy {
			margin-left: 300px;
		}
	}

	@include media-breakpoint-up(lg) {
		.did-you-know-banner-copy {
			margin-left: ((($container-xlarge-max-width + $global-gutter) / $grid-columns) * 3) - $global-gutter;
		}
	}
}

.mid-content-service-promo {
	$promo-image-overhang:	100px;

	border: 4px solid $brand-yellow;
	border-bottom: none;
	border-left: none;
	position: relative;
	margin: 20px 0 ($promo-image-overhang + $global-gutter) 40px;
	padding: 45px 25px 0 5px;

	& > h2,
	& > h3,
	& > h4,
	& > h5 {
		&:first-child {
			@include h2-heading-highlight;

			color: $brand-blue;
			margin-bottom: 1px;
		}

		& + h2,
		& + h3,
		& + h4,
		& + h5 {
			@extend .uk-h1;

			color: $gray-400;
			line-height: 1;
			margin-bottom: 16px;
		}
	}

	img {
		float: none;
		margin: 3px 0 ($promo-image-overhang * -1) 0;
	}

	a {
		@extend .uk-button;
		@extend .uk-button-primary;

		position: absolute;
		top: 100%;
		left: 55px;
		margin-top: 15px;

		@include media-breakpoint-down(xxs) {
			left: 35px;
		}

		@media (max-width: 369px) {
			left: -10px;
		}

		@include media-breakpoint-up(lg) {
			left: 120px;
		}

		@include media-breakpoint-up(xl) {
			left: 190px;
			margin-top: -65px;
		}
	}
}

.blog-posts-grid-row {
	@include make-uk-child-width-ready();

	@extend .uk-flex-center;
	@extend .uk-flex-top;
	@extend .uk-flex-stretch\@xl;
	@extend .uk-child-width-1-2\@s;
	@extend .uk-child-width-1-4\@xl;

	@include media-breakpoint-between(sm, ms) {
		.blog-post-card {
			margin-right: 8px;
			margin-left: 8px;
		}
	}

	@include media-breakpoint-only(md) {
		.blog-post-card {
			margin-right: 35px;
			margin-left: 35px;
		}
	}

	@include media-breakpoint-only(lg) {
		& > * {
			width: percentage(1 / 3) + 1;
		}
	}

	@include media-breakpoint-down(xs) {
		& > .blog-post-column {
			padding-right: 5px;
			padding-left: $global-small-gutter + 5px;

			& + .blog-post-column {
				margin-top: 20px;
			}
		}
	}
}

.content-footer-services-promo {
	.section-title {
		margin-left: 0;
		margin-bottom: 0;

		h2 > small,
		& > h3:first-child {
			color: $brand-orange;
			margin-bottom: -4px;
		}

		h2 {
			margin-bottom: 8px;
		}
	}

	.copy-column {
		margin-left: 0;

		p + .uk-button {
			margin-top: -4px;
		}
	}

	.image-column.slideshow-column {
		@extend .uk-width-1-2\@lg;

		max-width: ((($container-max-width + $global-gutter) / $grid-columns) * 4) + $global-gutter;
	}

	@media (max-width: 389px) {
		.slideshow-column {
			display: none;
		}
	}

	@media (min-width: 540px) and (max-width: $breakpoint-medium-max) {
		margin-right: auto;
		margin-left: auto;
	}

	@include media-breakpoint-down(md) {
		max-width: (((($container-small-max-width + $global-small-gutter) / $grid-columns) * 8) - $global-small-gutter) + $global-gutter;

		.section-title,
		.slideshow-column,
		.copy-column {
			width: 100%;
		}

		.slideshow-column {
			text-align: left;
			margin-left: 0;

			& + .copy-column {
				margin-top: 18px;
			}
		}
	}

	@include media-breakpoint-down(ms) {
		max-width: ((($container-small-max-width + $global-small-gutter) / $grid-columns) * 8);

		.image-column.slideshow-column {
			max-width: ((($container-max-width + $global-gutter) / $grid-columns) * 4) + $global-small-gutter;
		}
	}

	@include media-breakpoint-up(lg) {
		.section-title {
			padding-right: 10%;
			width: 60%;

			~ .image-column {
				margin-top: -50px;
			}
		}

		.slideshow-column {
			order: 2;
		}
	}

	@include media-breakpoint-up(xl) {
		margin-right: $global-large-gutter;
		margin-left: ($global-gutter * -1) + $global-large-gutter;
	}
}
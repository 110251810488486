.store-finder-v2-page-container {
  padding: 0;

  .store-locator-app-wrap {
    position: relative;
    z-index: 1;
    
    iframe {
      // height: 860px;
      height: 875px;
      @media all and ( min-width: 640px ) {
        height: 1155px;
      }

      @media all and ( min-width: 1100px ) {
        height: 680px;
      }
    }
  }
}

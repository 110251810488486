.single-dtn_faq {
	.header-wrap {
		z-index: 3;
	}
}

.content-faq {
	.uk-grid {
		& + .uk-grid {
			margin-top: 0;
		}

		& + .content-footer-services-promo {
			margin-top: 70px;
		}
	}

	.image-copy-content-section:first-child {
		.copy-column {
			@extend .uk-width-3-5\@lg;
		}

		.faq-cta-column {
			@extend .uk-width-4-5\@xs;
			@extend .uk-width-3-5\@ms;

			text-align: left;
			margin-top: 35px;

			&.image-column {
				// Declaring this here to increase specificity and overwrite more general styles
				@extend .uk-width-2-5\@lg;

				padding-left: $global-small-gutter;
			}
		}
	}

	.faq-category-jump-tos {
		margin-top: 20px;

		ul {
			@extend .uk-list;

			& > li {
				padding-left: 0;

				&:nth-child(n + 2) {
					margin-top: 18px;
				}

				&:before {
					display: none;
				}
			}
		}
	}

	.faq-category-item-list-block {
		@extend .uk-width-4-5\@xs;

		margin-top: 50px;
	}

	@include media-breakpoint-up(xs) {
		.faq-category-item-list-block {
			@include make-uk-width-offset(1);
		}
	}

	@include media-breakpoint-up(md) {
		.image-copy-content-section:first-child {
			.faq-cta-column.image-column {
				padding-left: $global-gutter;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.image-copy-content-section:first-child {
			.image-column.faq-cta-column {
				width: percentage(3 / $grid-columns);
			}
		}
	}
}

.content-single-faq {
	.post-sharing-icons {
		top: 40px;
		margin-bottom: 40px;
	}
}

.faq-category-item-list-block {
	$faq-category-item-list-vertical-gutter: 30px;

	.faq-category-item-list-block-heading {
		@extend .uk-flex-between;
		@extend .uk-flex-bottom;

		margin-bottom: $faq-category-item-list-vertical-gutter;

		.uk-button-text {
			font-weight: normal;
		}

		@include media-breakpoint-down(xs) {
			& > div {
				margin-top: 0;
				width: 100%;
			}

			.heading-text {
				order: 2;
			}

			.back-to-top-btn-wrap {
				text-align: right;
			}
		}

		@include media-breakpoint-down(xxs) {
			.heading-text {
				padding-left: ($global-small-gutter * 2);
			}

			.back-to-top-btn-wrap {
				margin-right: $global-small-gutter;
			}
		}

		@include media-breakpoint-up(sm) {
			flex-wrap: nowrap;
		}
	}

	.faq-category-item-list-wrap {
		@include media-breakpoint-up(xs) {
			margin: 0 ($global-gutter * -1);
		}

		& > ul {
			&:not(.uk-accordion) {
				@extend .uk-list;

				& > li {
					font-size: 0;
					transition: box-shadow 0.2s ease;

					&:nth-child(n + 2) {
						margin-top: $faq-category-item-list-vertical-gutter;
					}

					&:hover {
						box-shadow: $global-large-box-shadow;

						h3:after {
							right: -10px;
						}
					}

					h3 {
						padding-right: 25px;

						&:after {
							content: fa-content($fa-var-angle-right);
							position: absolute;
							top: 5px;
							right: 0;
							transition: right 0.2s ease-out;
						}
					}

					a {
						display: inline-block;

						&:hover {
							color: inherit;
							text-decoration: none;
						}
					}
				}
			}

			& > li {
				padding: $global-gutter $global-small-gutter;
				box-shadow: $global-small-box-shadow;
				position: relative;

				h3 {
					color: $gray-400;
					font-weight: $font-weight-medium;
					text-transform: none;
					position: relative;
					padding-right: 30px;

					&:after {
						@include make-fa-icon;
						@include fa-light;
					}
				}

				& > a:after {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}

				.uk-accordion-content {
					a {
						position: relative;
						z-index: 1;
					}
				}

				@include media-breakpoint-up(xs) {
					padding: $global-gutter;
				}
			}
		}

		.uk-accordion {
			margin: 0;

			& > li {
				&:nth-child(n + 2) {
					margin-top: $faq-category-item-list-vertical-gutter;
				}

				&.uk-open {
					h3:after {
						transform: rotate(180deg);
					}
				}

				h3:after {
					content: fa-content($fa-var-angle-down);
					font-size: 1.8rem;
					line-height: ($global-line-height / 1.8);
					transition: transform 0.2s ease;
					position: absolute;
					top: 0;
					right: 0;
					width: auto;
				}
			}
		}
	}
}
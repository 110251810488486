
.content-services {
	.service-cards-wrap {
		.dogtopia-slogan {
			margin-top: 160px;
		}

		.daycare-service-card {
			margin-top: -75px;
			margin-bottom: 25px;
		}

		.boarding-service-card {
			margin-top: 70px;
		}

		.spa-service-card {
			margin-top: 50px;
		}
	}
}

.content-service-detail {
	@include media-breakpoint-up(lg) {
		padding-bottom: 0;
	}

	.image-copy-content-section {
		&:not(:first-child) {
			@extend .uk-flex-middle\@l;
		}

		& + .image-copy-content-section {
			margin-top: 50px;
		}

		.copy-column:only-child,
		.section-title + .copy-column:last-child {
			@extend .uk-width-4-5\@lg;
		}

		@include media-breakpoint-down(md) {
			&:first-child {
				.copy-column {
					order: 2;
					margin-top: 30px;

					+ .image-column {
						margin-top: 10px;
					}
				}

				.image-column {
					width: auto;
				}
			}
		}

		@include media-breakpoint-down(ms) {
			&:first-child {
				.copy-column + .image-column {
					margin-top: 30px;
					margin-right: ($service-circle-icon-diameter / 2);
					margin-left: ($service-circle-icon-diameter / 2);
				}
			}
		}

		@include media-breakpoint-up(lg) {
			&:first-child {
				.section-title {
					width: 80%;
				}
			}

			& + .image-copy-content-section {
				margin-top: 70px;
			}
		}
	}

	.section-title,
	.copy-column:first-child {
		& > h2 > small,
		& > h3:first-child {
			color: $brand-orange;
		}
	}

	.image-column {
		.current-service-type-icon-overlay {
			position: absolute;
			top: ($service-circle-icon-diameter / 2) * -1;
			right: ($service-circle-icon-diameter / 2) * -1;;
			width: $service-circle-icon-diameter;

			@include media-breakpoint-up(lg) {
				right: 0;
			}

			@include media-breakpoint-up(xl) {
				top: ($service-circle-icon-diameter-xl / 2) * -1;
				width: $service-circle-icon-diameter-xl;
			}
		}

		.endless-love-slogan-overlay {
			margin-top: -15px;
			margin-left: 10px;
		}

		@include media-breakpoint-up(xs) {
			.endless-love-slogan-overlay {
				width: 410px;
			}
		}

		@include media-breakpoint-up(lg) {
			margin-top: 0;
		}
	}

	.other-services-cards-wrap {
		@extend .uk-child-width-1-2\@l;
		@extend .uk-flex-center;

		margin-top: 80px;

		@include media-breakpoint-up(lg) {
			justify-content: normal;
			margin-top: 120px;
		}

		.service-card {
			@extend .uk-flex;
			@extend .uk-flex-middle;
			@extend .uk-flex-center;

			min-height: unset;

			&:first-child {
				.uk-card-media {
					margin-right: -45px;
				}
			}

			&:last-child {
				.uk-card-media {
					order: 2;
					margin-left: -45px;
				}
			}

			.uk-card-media {
				@extend .uk-visible\@s;

				width: 220px;
			}

			.uk-card-body {
				align-self: stretch;
				margin: 0;
				padding: 0;

				.service-card-body-inner-wrap {
					padding: $card-body-padding-vertical 40px (($card-body-padding-vertical - 25) + $button-height) 40px;
				}

				.card-cta-wrap {
					position: absolute;
					right: 0;
					bottom: ($card-body-padding-vertical - 25);
					left: 0;
				}
			}

			@include media-breakpoint-down(md){
				& + .service-card {
					margin-top: 70px;
				}
			}

			@include media-breakpoint-down(xs) {
				.uk-card-body {
					width: auto;
					max-width: 400px;

					p {
						margin-bottom: 10px;
					}
				}
			}

			@media (min-width: $service-card-breakpoint-small) and (max-width: $breakpoint-xsmall-max) {
				.uk-card-badge {
					left: calc(50% - (#{$service-circle-icon-diameter} / 2));
				}
			}

			@media (min-width: $service-card-breakpoint-small) and (max-width: 600px) {
				.uk-card-media {
					width: 180px;
				}
			}

			@include media-breakpoint-up(sm) {
				&:first-child {
					.uk-card-badge {
						right: auto;
						left: $card-badge-right;
					}
				}

				.uk-card-body {
					.service-card-body-inner-wrap {
						padding-right: $card-body-padding-horizontal;
						padding-left: $card-body-padding-horizontal;
					}
				}
			}

			@include media-breakpoint-up(ms) {
				.uk-card-body {
					@include make-x-cols-wide(4, map_get($container-max-widths, "xl"));
				}
			}

			@include media-breakpoint-up(lg) {
				.uk-card-media {
					width: 165px;
				}

				.uk-card-body {
					$hover-movement-amount: 25px;

					transition: margin-top 0.2s ease,
								padding-bottom 0.2s ease;

					&:hover {
						margin-top: ($hover-movement-amount * -1);
						padding-bottom: $hover-movement-amount;
					}
				}
			}
		}
	}
}
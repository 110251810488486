.preferred-partners-v2-page-container {
  margin-top: -1px;
  overflow: hidden;

  .hero-banner-container  {
    height: auto;
    @media all and ( min-width: $breakpoint-large ) {
      height: 500px;
    }

    .hero-image-container {
      height: 200px;
      position: relative;
      padding: 0;
      width: 100%;
      @media all and ( min-width: $breakpoint-large ) {
        max-height: 500px;
        position: absolute;
        height: 100%;
      }

      img {
        object-position: center;
        object-fit: cover;
        min-height: 200px;
        height: 100%;
        width: 100%;
      }
    }
  }

  .partner-page-info-container {
    position: relative;
    max-width: 1115px;
    padding-top: 130px;
    @media all and ( min-width: $breakpoint-medium ) {
      padding-top: 166px;
    }

    &:before {
      position: absolute;
      width: 280px;
      background-image: url( '/wp-content/themes/dogtopia-2018/dist/images/preferred-partners-tag.png' );
      background-size: contain;
      z-index: 2;
      height: 140px;
      top: -55px;
      left: 0;
      right: 0;
      margin: 0 auto;
      @media all and ( min-width: $breakpoint-large ) {
        display: block;
        top: -93px;
        width: 370px;
        height: 186px;
      }
    }

    .intro-container {
      max-width: 970px;

      p {
        font-family: $brandon-text;
        font-weight: 300;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        color: #4D4D4F;
        padding-bottom: 50px;
        @media all and ( min-width: $breakpoint-medium ) {          
          font-size: 35px;
          line-height: 54px;
          padding-bottom: 150px;

        }
      }
    }
  }  

  .offer-cards-container {
    background-image: url(
      '/wp-content/themes/dogtopia-2018/dist/images/partners-page-vector-bkgd.png'
    );
    background-size: cover;
    padding: 40px 0;
    @media all and ( min-width: $breakpoint-medium ) {
      padding: 100px 0 80px;
    }

    .partner-section-heading {
      font-family: $brandon-text;
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 80px;
      text-transform: none;
      @media all and ( min-width: $breakpoint-medium ) {
        font-size: 40px;
        line-height: 56px;
        margin-bottom: 100px;
      }
    }

    .partner-cards-container {
      text-align: center;
      max-width: 970px;
      margin: 0 auto 10px;
      @media all and ( min-width: $breakpoint-medium ) {
        margin: 0 auto 110px;
        align-items: flex-end;
      }

      .column-container {
        margin-bottom: 70px;
        @media all and ( min-width: $breakpoint-medium ) {
          margin-bottom: 40px;
        }

        .media-container {
          margin: 0 0 30px;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .card-content-contianer {
          p {
            font-family: $brandon-text;
            font-weight: 400;
            font-size: 21px;
            line-height: 29px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 40px;
          }
        }

        .card-button {
          background: #FFFFFF;
          color: #424A51;
        }
      }
    }
  }
}


.partner-page-model-container.uk-open {
  display: flex !important;
  justify-content: center;
  align-items: center;
}


.partners-promo-pop-up-modal-container {
	padding: 0;
	max-width: 480px !important;
	width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 900px !important;
  }

	.close-button {
		&::after {
			font-weight: 300;
		}
	}

	.content-container {
		display: flex;
		flex-direction: column-reverse;
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
	}
	
	.image-container {
		max-width: none;
    position: relative;
    width: 100%;
    height: 100%;
    display: none;
		@include media-breakpoint-up(md) {
      display: block;
			max-width: 480px
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.border {
			clip-path: polygon( 98% 0, 100% 0, 100% 100%, 90% 100% );
			position: absolute;
			top: 0;
			right: -2px;
			width: 100%;
			height: 100%;
			display: none;
			@include media-breakpoint-up(md) {
				display: block;
			}
		}
	}
		
	.details-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
    // padding: 35px 15px 40px;
    padding: 60px 0 40px 15px;
    @include media-breakpoint-up(md) {
		  padding: 28px 31px 42px 0;
    }

		img.partner-logo {
			margin-bottom: 20px;
		}
		
    .main-content-container {
      margin-bottom: 30px;
      @include media-breakpoint-up(md) {
        margin-bottom: 10px;
      }

      h2, h3 {
        font-family: $brandon-text;
        font-weight: 400;
        font-size: 28px;
        line-height: 39px;
        text-transform: uppercase;
        margin: 0;
      }

      p {
        font-family: $brandon-text;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin: 0;
      }
    }

    .column-content-container {
      max-width: 400px;
      margin-bottom: 30px;
      @include media-breakpoint-up(md) {
        margin-bottom: 20px;
      }

      h2, h3 {
        font-family: $brandon-text;
        font-weight: 500;
        font-size: 45px;
        line-height: 63px;
        text-transform: uppercase;
        margin: 0;
      }

      p {
        font-family: $brandon-text;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
      }
    }

    .disclaimer-container {
      margin-bottom: 40px;
      @include media-breakpoint-up(md) {
        margin-bottom: 25px;
      }

      p {
        font-family: $brandon-text;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
    }
		
    a.uk-button {
      background: $brand-primary;
    }
	}	
}

// Global variables
// ========================================================================

//
// Grayscale
//
$gray-200: #2e3539;
$gray-300: #424a51;
$gray-400: #5b6770;
$gray-500: #9bb1c2;
$gray-600: #d3dce4;
$gray-700: #c3c3c3;
$gray-800: #e5e5e5;

//
// Brand Colors
//

$brand-orange: #ff8200;
$brand-blue: #00acc6;
$brand-yellow: #ffcc42;

$brand-primary: $brand-orange;
$brand-success: #00c6bc;
$brand-error: #ff0149;

//
// Social Brand Colors
//

$brand-social-facebook: #3b5998;
$brand-social-instagram: #e00072;
$brand-social-twitter: #1da1f2;
$brand-social-pinterest: #bd081c;
$brand-social-linkedin: #0077b5;
$brand-social-youtube: #ff0000;

//
// Global Colors
//

$global-color: $gray-400;
$global-muted-color: $gray-600;

$global-link-color: $brand-blue;
$global-link-hover-color: $global-link-color;

$global-inverse-color: #ffffff;

//
// Backgrounds
//

$global-background: #ffffff;

$global-muted-background: $gray-800;
$global-primary-background: $global-background;
$global-secondary-background: $brand-blue;

$global-success-background: $brand-success;
$global-warning-background: $brand-yellow;
$global-danger-background: $brand-error;

//
// Typography
//

$font-family-brandon-grotesque: "brandon-grotesque", "Helvetica Neue", Arial,
  sans-serif;
$sign-painter: "SignPainter", sans-serif;
$brandon-text: "brandon-text", "Helvetica Neue", Arial, sans-serif;

$global-font-family: $font-family-brandon-grotesque;
$global-font-size: 1.25rem;
$global-line-height: 1.6;

$global-xxlarge-font-size: 2.5rem; // 50px
$global-xlarge-font-size: 2rem; // 40px
$global-large-font-size: 1.3rem; // 26px
$global-medium-font-size: 1rem; // 20px
$global-small-font-size: 0.8rem; // 16px

//
// Box-Shadows
//

$global-small-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.25);
$global-medium-box-shadow: 0 0 3px 5px rgba(0, 0, 0, 0.2);
$global-large-box-shadow: 0 7px 6px 0 rgba(0, 0, 0, 0.25);

//
// Spacings
//

// Used in grid, column, container, align, card, padding
$global-gutter: 30px;
$global-small-gutter: 15px;
$global-medium-gutter: $global-gutter;
$global-large-gutter: 90px;

//
// Controls
//

$global-control-height: 50px;

//
// Base Variables
//

$base-em-color: $global-color;

$base-heading-color: $global-color;
$base-heading-font-weight: bold;
$base-heading-text-transform: uppercase;
$base-heading-margin-top: 0;

$base-h1-font-size: $global-xxlarge-font-size;
$base-h2-font-size: $global-xlarge-font-size;
$base-h3-font-size: $global-large-font-size;
$base-h4-font-size: $global-medium-font-size;
$base-h5-font-size: $global-small-font-size;

$base-h1-line-height: 1.3;
$base-h2-line-height: 1.25;
$base-h3-line-height: 1.25;
$base-h4-line-height: 1.45;
$base-h5-line-height: 1.625;

$base-strong-font-weight: bold;

$base-list-padding-left: 24px;

$base-selection-background: $brand-yellow;
$base-selection-color: $gray-400;

$base-blockquote-line-height: $global-line-height;
$base-blockquote-font-style: inherit;
$base-blockquote-margin-vertical: ($global-line-height * 1rem);

//
// Text
//

$text-muted-color: $gray-500;

//
// Buttons
//

$button-line-height: $base-h4-line-height;

$button-font-size: 1rem;

$button-padding-horizontal: 20px;

$button-border-width: 3px;

$button-default-background: $global-background;
$button-default-color: $brand-blue;
$button-default-hover-background: $global-background;
$button-default-hover-color: $brand-blue;
$button-default-active-background: $global-background;
$button-default-active-color: $brand-blue;
$button-default-border-color: $brand-blue;

$button-primary-background: $brand-blue;
$button-primary-color: $global-inverse-color;
$button-primary-hover-background: $brand-blue;
$button-primary-hover-color: $global-inverse-color;
$button-primary-active-background: $brand-blue;
$button-primary-active-color: $global-inverse-color;

$button-secondary-background: $gray-400;
$button-secondary-color: $global-inverse-color;
$button-secondary-hover-background: $gray-400;
$button-secondary-hover-color: $global-inverse-color;
$button-secondary-active-background: $gray-400;
$button-secondary-active-color: $global-inverse-color;

$button-text-color: $gray-400;
$button-text-hover-color: $gray-300;

$inverse-button-default-background: transparent;
$inverse-button-default-color: $global-inverse-color;
$inverse-button-default-hover-background: transparent;
$inverse-button-default-hover-color: $global-inverse-color;
$inverse-button-default-active-background: transparent;
$inverse-button-default-active-color: $global-inverse-color;
$inverse-button-default-border-color: $global-inverse-color;

//
// Close
//

$close-color: $global-inverse-color;
$close-hover-color: $close-color;

//
// Alert
//

$alert-padding: 20px;
$alert-padding-right: $alert-padding;

$alert-success-background: $brand-success;
$alert-success-color: $global-inverse-color;

$alert-warning-background: $brand-yellow;
$alert-warning-color: $global-inverse-color;

$alert-danger-background: $brand-error;
$alert-danger-color: $global-inverse-color;

//
// Label
//

$label-padding-vertical: 3px;
$label-padding-horizontal: 8px;
$label-background: $brand-orange;
$label-line-height: 1.55;
$label-font-size: 0.75rem;

//
// Breakpoints
//

$breakpoint-xsmall: 450px;
$breakpoint-small: 550px;
$breakpoint-medium-small: 660px;
$breakpoint-medium: 830px;
$breakpoint-large: 992px;
$breakpoint-xlarge: 1230px;

$breakpoint-xxsmall-max: ($breakpoint-xsmall - 0.02);
$breakpoint-xsmall-max: ($breakpoint-small - 0.02);
$breakpoint-small-max: ($breakpoint-medium-small - 0.02);
$breakpoint-medium-small-max: ($breakpoint-medium - 0.02);
$breakpoint-medium-max: ($breakpoint-large - 0.02);
$breakpoint-large-max: ($breakpoint-xlarge - 0.02);

$breakpoints: (
  xxs: 0,
  xs: $breakpoint-xsmall,
  sm: $breakpoint-small,
  ms: $breakpoint-medium-small,
  md: $breakpoint-medium,
  lg: $breakpoint-large,
  xl: $breakpoint-xlarge,
);

//
// Container Max Widths
//

$container-max-width: 1200px - $global-gutter;
$container-small-max-width: 640px - $global-small-gutter;
$container-medium-max-width: 800px - $global-gutter;
$container-large-max-width: 960px - $global-gutter;
$container-xlarge-max-width: $container-max-width;

$container-max-widths: (
  ms: $container-small-max-width,
  md: $container-medium-max-width,
  lg: $container-large-max-width,
  xl: $container-xlarge-max-width,
);

$container-padding-horizontal-s: ($global-gutter / 2);

//
// Forms
//

$form-padding-horizontal: 0;

$form-background: $global-background;
$form-focus-background: $form-background;
$form-disabled-background: $gray-800;

$form-placeholder-color: $gray-600;

$form-radio-size: 15px;
$form-radio-margin-top: -2px;
$form-radio-background: transparent;

$form-radio-checked-border: transparent;
$form-radio-checked-background: $brand-orange;
$form-radio-checked-focus-background: $form-radio-checked-background;

//
// Grid Gutter
//

$grid-large-gutter-horizontal: $global-large-gutter;

//
// Navbar
//

$navbar-nav-item-color: $global-inverse-color;
$navbar-nav-item-font-size: 1.1rem !default;
$navbar-nav-item-hover-color: $navbar-nav-item-color;
$navbar-nav-item-padding-horizontal: 17px !default;
$navbar-nav-item-active-color: $navbar-nav-item-color;

$navbar-toggle-color: $global-inverse-color;
$navbar-toggle-hover-color: $global-muted-color;

$navbar-dropdown-nav-item-color: $global-color;
$navbar-dropdown-nav-item-hover-color: $text-muted-color;
$navbar-dropdown-nav-item-active-color: $brand-orange;
$navbar-dropdown-nav-item-gutter-vertical: 12px;

$navbar-dropdown-background: $global-background;

//
// Card
//

$card-body-padding-horizontal: 50px;
$card-body-padding-vertical: 60px;

$card-body-padding-horizontal-l: $card-body-padding-horizontal;
$card-body-padding-vertical-l: $card-body-padding-vertical;

$card-title-line-height: $base-h3-line-height;

$card-default-background: $global-background;
$card-default-title-color: $gray-300;

//
// Iconnav
//

$iconnav-item-color: inherit;
$iconnav-item-hover-color: $iconnav-item-color;

//
// Modal
//

$modal-background: rgba(10, 11, 11, 0.9);

$modal-body-padding-horizontal: 45px;
$modal-body-padding-vertical: $modal-body-padding-horizontal;

$modal-header-padding-horizontal: $modal-body-padding-horizontal;
$modal-header-padding-vertical: 35px;
$modal-header-background: $brand-orange;

$modal-title-font-size: $base-h2-font-size;
$modal-title-line-height: $base-h2-line-height;

$modal-close-position: 20px;

//
// Pagination
//

$pagination-margin-horizontal: $global-gutter;

$pagination-item-color: $gray-400;
$pagination-item-hover-color: $brand-orange;
$pagination-item-active-color: $brand-orange;

//
// Font Awesome Variables
//

$font-awesome-font-family: "Font Awesome 6 Pro";
$font-awesome-brands-font-family: "Font Awesome 6 Brands";
$font-awesome-solid-weight: 900;
$font-awesome-regular-weight: 400;
$font-awesome-light-weight: 300;

//
// Custom Non-UiKit Variables
//

$minimal-header-background-color: #515961;

$font-weight-black: 900;
$font-weight-bold: bold;
$font-weight-medium: 500;

$grid-columns: 10;

$single-column-plus-gutter-width-sm: (
  ($container-small-max-width + $global-small-gutter) / $grid-columns
);

$box-shadow-spacing: 10px;

$button-padding-vertical: 13px;

$button-height: (
    ((($global-font-size / 1rem) * ($button-font-size / 1rem)) * 16px) *
      $button-line-height
  ) + ($button-padding-vertical * 2);

$form-border-width: 2px;
$form-border-color: $gray-700;

$form-focus-border-color: $brand-blue;

$navbar-background: transparent;

$navbar-nav-item-margin-horizontal: (46px / 2) -
  $navbar-nav-item-padding-horizontal !default;
$navbar-nav-item-margin-horizontal-xl: (50px / 2) -
  $navbar-nav-item-padding-horizontal !default;

$navbar-nav-item-active-border-width: 3px;

$navbar-toggle-icon-bar-width: 35px;
$navbar-toggle-icon-bar-height: 4px;
$navbar-toggle-icon-bar-vertical-gutter: 10px;

$navbar-toggle-padding-horizontal: 35px;
$navbar-toggle-padding-vertical: 40px;
$navbar-toggle-width: $navbar-toggle-icon-bar-width +
  ($navbar-toggle-padding-horizontal * 2);
$navbar-toggle-height: ($navbar-toggle-padding-vertical * 2) +
  ($navbar-toggle-icon-bar-height * 2) + $navbar-toggle-icon-bar-vertical-gutter;

$header-logo-height: 61px;
$header-logo-height-lg: 75px;

$icon-hover-offset: -10px;

$service-circle-icon-diameter: 80px;
$service-circle-icon-diameter-xl: 100px;

$iconnav-padding-horizontal: 5px;

$header-top-banner-element-gutter: 30px;
$header-top-banner-element-gutter-xl: 35px;

$header-social-account-icons-margin-horizontal: $header-top-banner-element-gutter -
  ($iconnav-padding-horizontal * 2);
$header-social-account-icons-margin-horizontal-xl: $header-top-banner-element-gutter-xl -
  ($iconnav-padding-horizontal * 2);

$colored-bullet-width-height: 9px;

$service-card-breakpoint-small: 500px;

$grid-list-top-offset: 180px !default;
$grid-list-vertical-gutter: $global-gutter;

$single-post-top-overlap: 0 !default;

$article-card-padding: 15px;
$article-card-padding-md: 55px;
$article-card-padding-lg: 45px;

$team-member-card-border-offset: 14px;

$promo-block-border-width: 4px;

$footer-anchored-pagination-card-horizontal-breakout: 60px;

$app-promo-column-right-margin-md: 40px;

//
// UiKit variables that use custom, non-uikit variables specified above
//

$form-height: $global-control-height + $form-border-width;

$card-badge-top: ($service-circle-icon-diameter / 2) * -1;
$card-badge-top-xl: ($service-circle-icon-diameter-xl / 2) * -1;

$iconnav-margin-horizontal: 25px - ($iconnav-padding-horizontal * 2);


@mixin make-container-max-widths($maxWidths: $container-max-widths, $breakpointsList: $breakpoints) {

	@each $breakpointName, $containerWidth in $maxWidths {
		@include media-breakpoint-up($breakpointName, $breakpointsList) {
			max-width: $containerWidth;
		}
	}

}

@mixin hook-container-misc {
	.uk-container {
		@include make-container-max-widths;
	}
}
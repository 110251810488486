
.list-item-card {
	@extend .uk-card-default;
	@extend .uk-flex;
	@extend .uk-flex-middle;

	position: relative;
	transition: box-shadow 0.2s ease;

	&:hover {
		box-shadow: $global-large-box-shadow;

		.uk-card-body:after {
			right: 10px;
		}
	}

	[class^="uk-card-media"] {
		@extend .uk-visible\@ms;

		flex-shrink: 0;
	}

	.uk-card-body {
		padding: 20px 45px 20px 25px;

		&:after {
			@include make-fa-icon;
			@include fa-light;

			content: fa-content($fa-var-angle-right);
			color: $gray-500;
			font-size: 40px;
			position: absolute;
			top: 50%;
			right: 15px;
			margin-top: -20px;
			transition: right 0.2s ease-out;
		}

		h3 {
			color: $gray-400;
			margin-bottom: 0;

			a {
				color: inherit;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}

		.list-item-meta {
			color: $gray-500;
			font-weight: bold;
			line-height: normal;
			margin-top: 1px;
			margin-bottom: 2px;
		}
	}

	@include media-breakpoint-down(ms) {
		.uk-card-body {
			.list-item-meta .event-date {
				display: block;
			}
		}
	}

	@include media-breakpoint-up(xs) {
		.uk-card-body:after {
			right: 20px;
		}
	}
}

.service-card {
	$card-body-element-width: (($container-max-width + $global-gutter) / 3) - $global-gutter;

	@extend .uk-card-default;

	background-color: transparent;
	box-shadow: none;

	@media (min-width: $service-card-breakpoint-small) {
		min-height: 370px;
	}

	& > * {
		width: $card-body-element-width;

		@include media-breakpoint-down(xxs) {
			width: 100%;
			max-width: $card-body-element-width;
		}
	}

	.uk-card-body {
		background-color: $card-default-background;
		text-align: center;
		box-shadow: $global-small-box-shadow;
		position: relative;

		@include media-breakpoint-down(md) {
			z-index: 2;
			margin-top: 15px;
		}

		@media (max-width: ($service-card-breakpoint-small - 0.02px)){
			margin-top: 0;
		}

		@media (max-width: 379px) {
			padding-right: 30px;
			padding-left: 30px;
		}

		h3,
		.uk-card-title {
			margin-bottom: 10px;
		}

		p {
			font-style: italic;
			line-height: normal;
		}

		.uk-button {
			z-index: 1;
		}

		.card-click-area {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}

	.uk-card-media-top,
	.uk-card-media-right,
	.uk-card-media-bottom,
	.uk-card-media-left {
		@include media-breakpoint-down(md) {
			position: absolute;
			top: 0;
		}

		@media (max-width: ($service-card-breakpoint-small - 0.02px)){
			display: none;
		}
	}

	.uk-card-badge {
		width: $service-circle-icon-diameter;

		@media (max-width: ($service-card-breakpoint-small - 0.02px)){
			left: calc(50% - (#{$service-circle-icon-diameter} / 2));
		}

		@include media-breakpoint-up(xl) {
			width: $service-circle-icon-diameter-xl;
		}
	}

	&.daycare-service-card,
	&.spa-service-card {
		.uk-card-media-top,
		.uk-card-media-right,
		.uk-card-media-bottom,
		.uk-card-media-left {
			@include media-breakpoint-down(md) {
				left: 320px;
			}

			@include media-breakpoint-down(ms) {
				left: 220px;
			}
		}
	}

	&.daycare-service-card {
		@include media-breakpoint-up(lg) {
			display: flex;
			align-items: flex-start;
		}

		.uk-card-body {
			@include media-breakpoint-up(lg) {
				margin-top: 70px;
				margin-right: -130px;
			}

			& > a.uk-button {
				margin: 0 ($card-body-padding-horizontal * -1);
			}
		}

		.uk-card-badge {
			@media (min-width: $service-card-breakpoint-small) {
				right: auto;
				left: $card-badge-right;
			}
		}
	}

	&.boarding-service-card {
		.uk-card-body {
			@include media-breakpoint-up(lg) {
				margin-top: -85px;
				margin-left: 120px;
			}
		}

		.uk-card-media-top,
		.uk-card-media-right,
		.uk-card-media-bottom,
		.uk-card-media-left {
			@include media-breakpoint-down(md) {
				right: 315px;
			}

			@include media-breakpoint-down(ms) {
				right: 245px;
			}

			@include media-breakpoint-down(sm) {
				right: 180px;
			}
		}
	}

	&.spa-service-card {
		.uk-card-media-bottom {
			@include media-breakpoint-up(lg) {
				margin-top: -80px;
				margin-left: 80px;
			}

			@include media-breakpoint-down(sm) {
				left: 150px;
			}
		}
	}
}

.stat-card {
	text-align: center;
	line-height: 1;

	.stat-icon,
	.stat-info,
	.stat-description {
		display: block;
	}

	.stat-icon {
		margin: 0 auto 4px auto;
		width: 65px;
		height: 65px;

		@include media-breakpoint-up(sm) {
			margin-bottom: 14px;
			width: 80px;
			height: 80px;
		}

		@include media-breakpoint-up(md) {
			width: 90px;
			height: 90px;
		}

		@media (max-width: 399px) {
			display: none;
		}
	}

	.stat-info {
		color: $brand-yellow;
		font-size: 1.5rem;
		font-weight: $font-weight-bold;
		text-transform: uppercase;

		@include media-breakpoint-up(md) {
			font-size: $global-xxlarge-font-size;
		}
	}

	.stat-description {
		font-weight: $font-weight-bold;

		@include media-breakpoint-down(sm) {
			line-height: normal;
			margin-top: -1px;
		}

		@include media-breakpoint-up(md) {
			font-size: 1.5rem;
		}
	}
}

.blog-post-card {
	@extend .uk-card-default;

	transition: box-shadow 0.2s ease;

	&.loading {
		visibility: hidden;
	}

	&:hover {
		box-shadow: $global-large-box-shadow;
	}

	h4 {
		text-transform: none;
		line-height: $global-line-height;
	}

	a {
		color: inherit;
	}

	.uk-card-body {
		position: relative;
		padding: 20px;
	}

	.uk-card-badge {
		transform: translateY(-50%);
		top: 0;
		right: auto;
		left: 20px;
	}

	.uk-label {
		text-transform: lowercase;
	}

	&.hero-blog-post-card {
		.uk-card-body {
			text-align: left;
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}

	@media (min-width: 400px) and (max-width: $breakpoint-xxsmall-max) {
		h4 {
			font-size: 0.9rem;
		}
	}

	@media (min-width: 400px) and (max-width: $breakpoint-xsmall-max) {
		&:not(.hero-blog-post-card) {
			.uk-card-body {
				padding: 10px 20px;
			}
		}
	}

	@include media-breakpoint-down(xs) {
		&:not(.hero-blog-post-card) {
			display: flex;
			align-items: center;

			.uk-card-media-top {
				overflow: hidden;
				position: relative;
				width: 125px;
				height: 125px;

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					max-width: none;
					transform: translate(-50%, -50%);
				}
			}

			.uk-card-body {
				flex: 1;
				position: static;
			}

			.uk-card-badge {
				transform: none;
				right: 0;
				left: auto;
			}
		}
	}

	@include media-breakpoint-down(xxs) {
		&:not(.hero-blog-post-card) {
			.uk-card-media-top {
				width: 100px;
			}
		}
	}

	@media (max-width: 399px) {
		&:not(.hero-blog-post-card) {
			min-height: 100px;

			.uk-card-media-top {
				display: none;
			}

			.uk-card-body {
				padding-top: 20px;
				padding-bottom: 20px;
			}
		}
	}
}

.blog-categories-card {
	@extend .uk-card-default;

	.uk-card-body {
		padding: 30px;

		ul {
			@extend .uk-list;

			font-weight: 500;
			text-transform: lowercase;

			& > li {
				margin-left: 3px;

				& > a {
					color: inherit;
					padding: 0 6px 0 3px;

					&:hover {
						text-decoration: none;
					}
				}

				& > a:hover,
				&.current-cat > a {
					border-bottom: 3px solid $brand-orange;
				}
			}
		}
	}
}

.pagination-card {
	@extend .uk-card-default;

	&:hover {
		box-shadow: $global-small-box-shadow;
		margin-top: 0;
	}

	a:hover {
		&,
		.uk-card-title {
			text-decoration: none;
		}
	}

	.uk-card-title {
		color: $gray-500;
		font-size: 0.7rem;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		line-height: ($global-line-height * 1rem);
		margin-bottom: 10px;
	}

	.uk-card-body {
		padding: 10px 30px 30px 30px;
	}
}

.team-member-card {
	padding-right: 40px - $team-member-card-border-offset;

	.uk-card-body {
		font-weight: $font-weight-medium;
		text-align: center;
		line-height: normal;
		padding: 15px 0 0 0;

		h4 {
			color: $gray-300;
			font-weight: inherit;
			white-space: nowrap;
			margin: 0 -20px;
		}

		p {
			color: $gray-500;
		}
	}
}

.dog-expert-card {
	$dog-expert-card-border-width: 4px;
	$dog-expert-card-padding: 30px;
	$dog-expert-card-padding-right: 20px;

	border: $dog-expert-card-border-width solid $brand-yellow;

	.uk-card-body {
		position: relative;
		padding: $dog-expert-card-padding ($dog-expert-card-padding-right - $dog-expert-card-border-width) $dog-expert-card-padding ($dog-expert-card-padding - $dog-expert-card-border-width);

		h3 {
			color: inherit;
			margin-bottom: -3px;
		}

		h4 {
			font-weight: normal;
			font-style: italic;
			text-transform: none;
		}

		p {
			font-size: $global-small-font-size;
			line-height: normal;
		}

		.profile-photo-cta-btn-block {
			border: 10px solid $global-background;
			background-color: $global-background;
			display: inline-block;
			position: relative;
			margin-top: (110px + $dog-expert-card-padding + $dog-expert-card-border-width) * -1;
			margin-bottom: 15px;

			.circle-icon {
				position: absolute;
				top: 6px;
				right: 6px;
				width: 50px;
			}

			.uk-button {
				display: block;
				margin: 10px auto 0 auto;
			}
		}

		@include media-breakpoint-down(xs) {
			text-align: center;
		}

		@include media-breakpoint-up(sm) {
			.profile-photo-cta-btn-block {
				float: right;
				margin-top: (90px + $dog-expert-card-padding + $dog-expert-card-border-width) * -1;
				margin-right: (46px + $dog-expert-card-padding-right + $dog-expert-card-border-width) * -1;
			}
		}
	}
}

.faq-cta-card {
	background-color: $gray-800;
	background-image: url("../images/faq-cta-card-bg.png");
	background-repeat: no-repeat;
	background-position: bottom -225px right -37px;

	.uk-card-body {
		padding: 20px;

		h3 {
			margin-bottom: 17px;
		}

		p {
			font-size: 0.9rem;
			font-weight: $font-weight-bold;
			line-height: normal;
			margin-bottom: 20px;
		}
	}
}


.job-post-list-item-card {

	.uk-card-body {
		@include media-breakpoint-up(xs) {
			padding: 35px 55px 35px 35px;
		}
	}
}
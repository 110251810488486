
.comment-list ol {
  list-style: none;
}

.comment-content a {
	word-wrap: break-word;
}
.bypostauthor {
	display: block;
}
